import { createBrowserRouter } from "react-router-dom"
import { AppWrapper } from "./app-wrapper"
import { ProtectedAdmin } from "./components/protected-admin"
import { ProtectedRoute } from "./components/protected-route"
import { FourOhOne } from "./pages/401"
import { FourOhFour } from "./pages/404"
import { Admin } from "./pages/admin"
import { AdminComments } from "./pages/admin/comments"
import { Conditions } from "./pages/admin/conditions/index"
import { AdminEvents } from "./pages/admin/events"
import { IndicationsAdmin } from "./pages/admin/indications"
import { Indication } from "./pages/admin/indications/show"
import { Medications } from "./pages/admin/medications/index"
import { PatientFinder } from "./pages/admin/patient-finder"
import { Practices } from "./pages/admin/practices/index"
import { Practice } from "./pages/admin/practices/show"
import { PracticeUsersAdmin } from "./pages/admin/practices/users"
import { Procedures } from "./pages/admin/procedures/index"
import { AdminQualityAssurance } from "./pages/admin/quality-assurance"
import { SponsorsAdmin } from "./pages/admin/sponsors/index"
import { Sponsor } from "./pages/admin/sponsors/show"
import { StudiesAdmin } from "./pages/admin/studies"
import { StudyBuilder } from "./pages/admin/studies/builder"
import { Study } from "./pages/admin/studies/show"
import { VisitAdmin } from "./pages/admin/studies/visit"
import { Visits } from "./pages/admin/studies/visits/index"
import { SyncConfiguration } from "./pages/admin/sync-configuration"
import { Users } from "./pages/admin/users/index"
import { ShowUser } from "./pages/admin/users/show"
import { Xrefs } from "./pages/admin/xrefs"
import { Calendar } from "./pages/appointments/calendar"
import { Auth } from "./pages/auth"
import { Week } from "./pages/calendar-screening/calendar-screening"
import { ChartReviewBatch } from "./pages/chart-review-batch"
import { ChartReviewDirectory } from "./pages/chart-review-directory"
import { Dashboard } from "./pages/dashboard"
import { Home } from "./pages/home"
import { Kanban } from "./pages/kanban/kanban"
import Layout from "./pages/layout"
import { Logout } from "./pages/logout"
import { Messaging } from "./pages/messaging"
import { Patient } from "./pages/patient"
import { PatientAppointments } from "./pages/patient-appointments"
import { PatientCards } from "./pages/patient-cards"
import { PatientConditions } from "./pages/patient-conditions"
import { PatientInfo } from "./pages/patient-info"
import { PatientMedications } from "./pages/patient-medications"
import { PatientScreen } from "./pages/patient-screen"
import { PatientScreens } from "./pages/patient-screens"
import { PatientTimeline } from "./pages/patient-timeline"
import { PatientVisits } from "./pages/patient-visits"
import { Patients } from "./pages/patients"
import { PracticeSponsors } from "./pages/practice-sponsors"
import { PracticeStudies } from "./pages/practice-studies"
import { QualityAssurance } from "./pages/quality-assurance"
import { Referrals } from "./pages/referrals"
import { Tasks } from "./pages/tasks"
import { Uploads } from "./pages/uploads/index"

const multiTenantEnabled = process.env.MULTI_TENANT_ENABLED === "true"
export const appRouter = createBrowserRouter([
  {
    path: "/",
    element: <AppWrapper />,
    children: [
      { index: true, element: <Auth /> },
      { path: "auth", element: <Auth /> },
      { path: "logout", element: <Logout /> },
      { path: "404", element: <FourOhFour /> },
      { path: "401", element: <FourOhOne /> },
      {
        element: <ProtectedRoute component={Layout} />,
        children: [
          { path: "home", element: <Home /> },
          { path: "quality-assurance", element: <QualityAssurance /> },
          { path: "dashboard", element: <Dashboard /> },
          { path: "kanban", element: <Kanban /> },
          {
            path: "patients",
            children: [
              { index: true, element: <Patients /> },
              {
                path: ":patientId",
                element: <Patient />,
                children: [
                  { index: true, element: <PatientInfo /> },
                  { path: "info", element: <PatientInfo /> },
                  { path: "timeline", element: <PatientTimeline /> },
                  { path: "screens", element: <PatientScreens /> },
                  { path: "screens/:screenId", element: <PatientScreen /> },
                  { path: "appointments", element: <PatientAppointments /> },
                  { path: "visits", element: <PatientVisits /> },
                  { path: "medications", element: <PatientMedications /> },
                  { path: "conditions", element: <PatientConditions /> },
                ],
              },
              { path: "patient-cards", element: <PatientCards /> },
            ],
          },
          {
            path: "chart-review-directory",
            children: [
              { index: true, element: <ChartReviewDirectory /> },
              {
                path: ":patientId",
                element: <Patient />,
                children: [
                  { index: true, element: <PatientInfo /> },
                  { path: "info", element: <PatientInfo /> },
                  { path: "timeline", element: <PatientTimeline /> },
                  { path: "screens", element: <PatientScreens /> },
                  { path: "screens/:screenId", element: <PatientScreen /> },
                  { path: "appointments", element: <PatientAppointments /> },
                  { path: "visits", element: <PatientVisits /> },
                  { path: "medications", element: <PatientMedications /> },
                  { path: "conditions", element: <PatientConditions /> },
                ],
              },
              { path: "patient-cards", element: <PatientCards /> },
            ],
          },
          {
            path: "chart-review-batch",
            element: <ChartReviewBatch />,
          },
          { path: "referrals", element: <Referrals /> },
          { path: "week", element: <Week /> },
          { path: "messaging", element: <Messaging /> },
          { path: "calendar", element: <Calendar /> },
          { path: "uploads", element: <Uploads /> },
          { path: "studies", element: <PracticeStudies /> },
          { path: "sponsors", element: <PracticeSponsors /> },
          { path: "tasks", element: <Tasks /> },
          {
            path: "admin",
            element: <ProtectedAdmin />,
            children: [
              { index: true, element: <Admin /> },
              { path: "comments", element: <AdminComments /> },
              { path: "patient-finder", element: <PatientFinder /> },
              { path: "events", element: <AdminEvents /> },
              { path: "sync-configuration", element: <SyncConfiguration /> },
              multiTenantEnabled && {
                path: "practices",
                children: [
                  { index: true, element: <Practices /> },
                  { path: ":practiceId", element: <Practice /> },
                  {
                    path: ":practiceId/users",
                    element: <PracticeUsersAdmin />,
                  },
                ],
              },
              { path: "quality-assurance", element: <AdminQualityAssurance /> },
              multiTenantEnabled && {
                path: "sponsors",
                children: [
                  { index: true, element: <SponsorsAdmin /> },
                  { path: ":sponsorId", element: <Sponsor /> },
                ],
              },
              multiTenantEnabled && {
                path: "studies",
                children: [
                  { index: true, element: <StudiesAdmin /> },
                  { path: ":studyId", element: <Study /> },
                  { path: ":studyId/builder", element: <StudyBuilder /> },
                  { path: "visits", element: <Visits /> },
                  { path: "visits/:visitId", element: <VisitAdmin /> },
                ],
              },
              multiTenantEnabled && { path: "team_xrefs", element: <Xrefs /> },
              multiTenantEnabled && {
                path: "procedures",
                element: <Procedures />,
              },
              multiTenantEnabled && {
                path: "indications",
                children: [
                  { index: true, element: <IndicationsAdmin /> },
                  { path: ":indicationId", element: <Indication /> },
                ],
              },
              multiTenantEnabled && {
                path: "users",
                children: [
                  { index: true, element: <Users /> },
                  { path: ":userId", element: <ShowUser /> },
                ],
              },
              multiTenantEnabled && {
                path: "conditions",
                element: <Conditions />,
              },
              multiTenantEnabled && {
                path: "medications",
                element: <Medications />,
              },
            ],
          },
        ],
      },
    ],
  },
])
