import { useAuth0 } from '@auth0/auth0-react'
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IdleTimerProvider, useIdleTimer } from 'react-idle-timer'
import { Link, Outlet, useMatch, useNavigate, useOutletContext, useParams, useResolvedPath } from 'react-router-dom'
import { KeyedMutator, mutate } from 'swr'
import { HeroIcon } from '../components/hero-icon'
import { IneligibleModal } from '../components/ineligible-modal'
import { NewAppointmentModal } from '../components/new-appointment-modal'
import { NewConditionModal } from '../components/new-condition-modal'
import { NewMedicationModal } from '../components/new-medication-modal'
import { PatientInfoHeader } from '../components/patients/patient-info-header'
import { TimeoutModal } from '../components/timeout-modal'
import { classNames } from '../shared/class-names'
import { event } from '../shared/event'
import { indexUrl, useIndex, useShow, useUpdate } from '../shared/use-rest'
import { PatientInfoSidebar } from './patient-info-sidebar'
import { ContactModal } from './patients/contact-modal'

import { NewVisitModal } from '../components/new-visit-modal'
import { ScheduleInESourceModal } from '../components/schedule-in-esource-modal'
import { BookmarkModal } from './patients/bookmark-modal'
import { EditVisitModal } from '../components/edit-visit-modal'
import { ChatDrawer } from './messaging/chat-drawer'
// import { ChatDrawer } from './messaging/chat-drawer'

interface Tab {
  name: string,
  href: string,
  current?: Boolean,
  icon: string
}

export enum ModalType {
  BulkAssign,
  Appointment,
  Medication,
  Condition,
  ContactDisposition,
  Ineligible,
  Bookmark
}
// todo model types
type PatientContextType = {
  patient: object | null,
  mutatePatient: KeyedMutator<any>,
  mutateContacts: KeyedMutator<any>,
  mutate: KeyedMutator<object> | null,
  isIdle: boolean,
  isResumed: boolean,
  ineligibleModalOpen: boolean,
  bookmarkModalOpen: boolean,
  // TODO: Migrate these to setModalOpen<ModalType>()
  setNewAppointmentModalOpen: Dispatch<SetStateAction<boolean>>,
  setScheduleInESourceModalOpen : Dispatch<SetStateAction<boolean>>,
  setNewMedicationModalOpen: Dispatch<SetStateAction<boolean>>,
  setIneligibleModalOpen: Dispatch<SetStateAction<boolean>>,
  setNewConditionModalOpen: Dispatch<SetStateAction<boolean>>,
  setNewVisitModalOpen: Dispatch<SetStateAction<boolean>>,
  setEditVisitModalOpen: Dispatch<SetStateAction<boolean>>,
  contactDispositionOpen: boolean,
  setContactDispositionOpen: Dispatch<SetStateAction<boolean>>,
  contactModalEditingId: string,
  setContactModalEditingId: Dispatch<SetStateAction<string>>,
  setContactModalOpen: Dispatch<SetStateAction<boolean>>,
  setBookmarkModalOpen: Dispatch<SetStateAction<boolean>>,
  setCurrentStudyScreenName: Dispatch<SetStateAction<string>>,
  setCurrentStudyScreenId: Dispatch<SetStateAction<string>>,
  currentStudyScreenId: string,
  setCurrentVisitId:Dispatch<SetStateAction<string>>,
};

export function usePatientContext() {
  return useOutletContext<PatientContextType>();
}
export function Patient() {
  let { patientId, screenId } = useParams()
  const { getAccessTokenSilently } = useAuth0()
  const { data: patient, isLoading, isError, mutate: mutatePatient } = useShow('patients', patientId, {getAccessTokenSilently})
  const { data: screens, mutate: mutateScreens } = useIndex('screens', `patient_id=${patientId}`)
  const nowISO = useMemo(() => new Date().toISOString().slice(0, -1), [])
  const { data: patientAppointments, mutate: mutatePatientAppointments } = useIndex('patient_appointments', `patient_id=${patientId}&after_dt=${nowISO}`)
  const { data: contacts, isLoading: isLoadingContacts, isError: isErrorContacts, mutate: mutateContacts } = useIndex('contacts', `patient_id=${patientId}`,)
  const { register: registerContactPref, handleSubmit: handleSubmitContactPref, watch: watchContactPref, reset: resetContactPref } = useForm()
  const { register: registerDutyToWarnSent, handleSubmit: handleSubmitDutyToWarnSent, watch: watchDutyToWarnSent, reset: resetDutyToWarnSent } = useForm()
  const { register: registerDutyToWarnAcknowledged, handleSubmit: handleSubmitDutyToWarnAcknowledged, watch: watchDutyToWarnAcknowledged, reset: resetDutyToWarnAcknowledged } = useForm()
  const [modalOpen, setModalOpen] = useState<ModalType>()
  const [newAppointmentModalOpen, setNewAppointmentModalOpen] = useState(false)
  const [scheduleInESourceModalOpen, setScheduleInESourceModalOpen] = useState(false)
  const [newMedicationModalOpen, setNewMedicationModalOpen] = useState(false)
  const [newConditionModalOpen, setNewConditionModalOpen] = useState(false)
  const [newVisitModalOpen, setNewVisitModalOpen] = useState(false)
  const [editVisitModalOpen, setEditVisitModalOpen] = useState(false)
  const [prompted, setPrompted] = useState(false)
  const [isIdleState, setIsIdleState] = useState(false)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [contactModalEditingId, setContactModalEditingId] = useState('')
  const [ineligibleModalOpen, setIneligibleModalOpen] = useState(false)
  const [contactDispositionOpen, setContactDispositionOpen] = useState(false)
  const [currentStudyScreenName, setCurrentStudyScreenName] = useState('')
  const [currentStudyScreenId, setCurrentStudyScreenId] = useState('')
  const [bookmarkModalOpen, setBookmarkModalOpen] = useState(false)
  const [currentVisitId, setCurrentVisitId] = useState('')

  let tabs: Tab[] = [
    { name: 'Studies', href: `/patients/${patientId}/screens`, current: false, icon: "ClipboardIcon" },
    { name: 'Timeline', href: `/patients/${patientId}/timeline`, current: false, icon: "DocumentTextIcon" },
    { name: 'Patient Info', href: `/patients/${patientId}/info`, current: false, icon: "PencilIcon" },
    { name: 'Appointments', href: `/patients/${patientId}/appointments`, current: false, icon: "CalendarIcon" },
    { name: 'Visits', href: `/patients/${patientId}/visits`, current: false, icon: "OfficeBuildingIcon" },
    // { name: 'Medications', href: `/patients/${patientId}/medications`, current: false },
    // { name: 'Conditions', href: `/patients/${patientId}/conditions`, current: false },
  ]
  const [infoExpanded, setInfoExpanded] = useState(true)
  const [chatDrawerIsOpen, setChatDrawerIsOpen] = useState(false)


  // Dynamically set tab current value
  tabs.map((tab) => {
    let resolved = useResolvedPath(tab.href);
    let match = useMatch({ path: resolved.pathname, end: false });
    tab.current = match && true
  })

  // no match found, assume first tab
  if (tabs.filter(tab => tab.current === true).length === 0) {
    tabs[0].current = true
  }

  const tabLabelIcon = (tab) => {
    return (<HeroIcon
      icon={tab.icon}
      className='w-6 h-6 mr-2'
    />)
  }

  useEffect(() => {
    event({eventName: 'PatientView', meta: {patient_id: patientId}, getAccessTokenSilently})
  }, [patientId])

  const clickEvent = (name) => {
    event({eventName: 'Click', meta: {element: `${name.replace(/\s/g, '')}`}, getAccessTokenSilently})
  }
  const handlePatientInfoExpand = () => {
    infoExpanded ? clickEvent('PatientInfoCollapse') : clickEvent('PatientInfoExpand')
    setInfoExpanded(!infoExpanded)
  }

  let resolvedScreenPath = useResolvedPath(`/patients/${patientId}/screens/${screenId}`);
  let matchScreenPath = useMatch({ path: resolvedScreenPath.pathname, end: false });

  const onPrompt = () => {
    // Pause the idle checker
    // only if screening
    if (!matchScreenPath) return
    setPrompted(true)
    event({eventName: 'ScreenIdlePrompt', meta: {patient_id: patientId, screen_id: screenId}, getAccessTokenSilently})
  }
  const onIdle = () => {
    // Pause the idle checker
    // only if screening
    if (!matchScreenPath) return
    event({eventName: 'ScreenIdleTimeout', meta: {patient_id: patientId, screen_id: screenId}, getAccessTokenSilently})
    event({eventName: 'ScreenStop', meta: {patient_id: patientId, screen_id: screenId}, getAccessTokenSilently})
    setIsIdleState(true)
  }

  const resumeAfterIdlePrompt = async () => {
    // Fired on Close Modal click
    event({eventName: 'ScreenIdleResume', meta: {patient_id: patientId, screen_id: screenId}, getAccessTokenSilently})
    // only resume screen timer
    if (isIdleState) {
      event({eventName: 'ScreenStart', meta: {patient_id: patientId, screen_id: screenId}, getAccessTokenSilently})
      setIsIdleState(false)
    }
    reset()
    setPrompted(false)
  }
  const timeout = process.env.TIMEOUT ? parseInt(process.env.TIMEOUT) : (1000 * 590) // 50 seconds to prompt, use env if present
  const { isIdle, reset } = useIdleTimer({
    timeout: timeout,
    onIdle,
    onPrompt,
    promptTimeout: 1000 * 10 // 10 seconds after prompt to timeout (and end screen)
  })

  const submitDutyToWarnSent = (data) => {
    useUpdate('patients', patientId, data, getAccessTokenSilently)
    event({eventName: 'PatientDutyToWarnSent', meta: { patient_id: patientId }, getAccessTokenSilently})
    mutatePatient()
  }

  const submitDutyToWarnAcknowledged = async (data) => {
    await useUpdate('patients', patientId, data, getAccessTokenSilently)
    event({eventName: 'PatientDutyToWarnAcknowledged', meta: { patient_id: patientId }, getAccessTokenSilently})
    mutatePatient()
  }

  const submitContactPref = async (data) => {
    await useUpdate('patients', patientId, data, getAccessTokenSilently)
    event({eventName: 'PatientContactPrefUpdated', meta: { patient_id: patientId }, getAccessTokenSilently})
    mutatePatient()
    // set all screens to DQ
    if (data.can_contact == false) {
      screens.forEach(async (screen) => {
        await useUpdate('screens', screen.id, {status: 'disqualified'}, getAccessTokenSilently)
        await event({eventName: `ScreenUpdateStatus`, meta: { patient_id: patientId, screen_id: screen.id, assigned_to_user_id: screen.assigned_to_user_id }, getAccessTokenSilently})
      })
      mutateScreens()
    }
  }

  const mutatePatientMedications = () => {
    mutate([`${indexUrl('patient_medications')}?patient_id=${patientId}`, getAccessTokenSilently])
  }

  const mutatePatientConditions = () => {
    mutate([`${indexUrl('patient_conditions')}?patient_id=${patientId}`, getAccessTokenSilently])
  }

  const mutatePatientVisits = () => {
    mutate([`${indexUrl('visits')}?patient_id=${patientId}`, getAccessTokenSilently])
  }

  useEffect(() => {
    // Guard if API data isn't back yet
    if (patient?.can_contact === undefined) return
    // Set up initial form data
    resetContactPref({can_contact: patient?.can_contact})
    // Subscribe to changes on the form
    // TODO: Fix these types to get rid of the red squigglies
    const subscription = watchContactPref(handleSubmitContactPref(submitContactPref));
    // Leave no trace
    return () => subscription.unsubscribe();
  }, [patient.can_contact])

  const navigate = useNavigate()
  useEffect(() => {
    if (isLoading) return
    if (!patient) navigate('/patients')
    // patient not authorized
    // navigate to /patients

    return () => {}
  }, [isLoading])


  if (isLoading) return <>Loading...</>
  if (isError) return <>Error. Please contact your support team.</>

  return (
    <>

      <div className="flex-1 relative z-0 flex overflow-hidden h-full dark:text-gray-200 ">
        <main className="flex-1 relative z-0 overflow-y-overlay focus:outline-none xl:order-last">
          {infoExpanded ?
            <div className='hover:cursor-pointer pt-2' onClick={() => handlePatientInfoExpand()}>
              <HeroIcon icon={'ChevronLeftIcon'} className="w-6 h-6 text-gray-900"/>
            </div>
            :
              <div className="hover:cursor-pointer pt-2" onClick={() => handlePatientInfoExpand()}>
                <HeroIcon icon={'InformationCircleIcon'} />
              </div>
          }
          {/* Main Content */}
          <div className=" lg:block lg:flex-shrink-0 lg:order-first">
            {/* Breadcrumbs */}
            <nav className="hidden pb-4" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-2">
                <li className="inline-flex items-center">
                  <Link
                    to="/dashboard"
                    onClick={() => clickEvent("BreadcrumbsDashboard")}
                    className="inline-flex items-center text-gray-700 hover:text-gray-900 dark:text-gray-300 dark:hover:text-white"
                  >
                    <svg
                      className="w-5 h-5 mr-1"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                  </Link>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <Link
                      to="/patients"
                      onClick={() => clickEvent("BreadcrumbsPatients")}
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-300 dark:hover:text-white"
                    >
                      Patients!
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg
                      className="w-6 h-6 text-gray-400"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                    <Link
                      to={`/patients/${patientId}`}
                      onClick={() => clickEvent("BreadcrumbsPatientInfo")}
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-300 dark:hover:text-white"
                    >
                      {patient.given_name} {patient.family_name}
                    </Link>
                  </div>
                </li>
                {/* <li>
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"></path></svg>
                    <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500" aria-current="page">Study Screen</span>
                  </div>
                </li> */}
              </ol>
            </nav>
            <div className="md:flex lg:flex lg:space-x-4 md:space-x-3">
              {infoExpanded && (
                <div className="flex flex-col sm:w-full md:w-1/4 lg:w-1/4">
                  <PatientInfoHeader
                    mutatePatient={mutatePatient}
                    patient={patient}
                    screens={screens}
                    submitContactPref={submitContactPref}
                    submitDutyToWarnSent={submitDutyToWarnSent}
                    submitDutyToWarnAcknowledged={submitDutyToWarnAcknowledged}
                    registerContactPref={registerContactPref}
                    handlePatientInfoExpand={handlePatientInfoExpand}
                    infoExpanded={infoExpanded}
                  />
                  {/* TODO: Refactor these all out into a PatientProvider. */}
                  <PatientInfoSidebar
                    patient={patient}
                    mutatePatient={mutatePatient}
                    mutatePatientAppointments={mutatePatientAppointments}
                    setNewAppointmentModalOpen={setNewAppointmentModalOpen}
                    setContactModalOpen={setContactModalOpen}
                    setContactDispositionOpen={setContactDispositionOpen}
                    setChatDrawerIsOpen={setChatDrawerIsOpen}
                    contactDispositionOpen={contactDispositionOpen}
                    contactModalEditingId={contactModalEditingId}
                    setContactModalEditingId={setContactModalEditingId}
                    mutateContacts={mutateContacts}
                    patientAppointments={patientAppointments}
                    contacts={contacts}
                  />
                </div>
              )}
              <div className="flex flex-col flex-1 pr-3">
                {/* Start Tab Bar */}
                  <div className="sm:hidden bg-white dark:bg-transparent">
                    <label htmlFor="tabs" className="sr-only">
                      Select a tab
                    </label>
                    {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
                    <select
                      id="tabs"
                      name="tabs"
                      className="block w-full focus:ring-indigo-800 focus:border-indigo-500 border-gray-300 rounded-md"
                      defaultValue={tabs.find((tab) => tab.current)?.name}
                    >
                      {tabs.map((tab, index) => (
                        <option key={index}>{tab.name}</option>
                      ))}
                    </select>
                  </div>
                <div className="hidden sm:block">
                  <nav
                    className="relative z-0 flex space-x-[14px] bg-transparent dark:bg-transparent"
                    aria-label="Tabs"
                  >
                    {tabs.map((tab, tabIdx) => (
                        <Link
                          key={tabIdx}
                          to={
                            tab.name.includes("Studies") && currentStudyScreenId
                              ? `/patients/${patientId}/screens/${currentStudyScreenId}`
                              : tab.href
                          }
                          onClick={() => clickEvent("PatientTab" + tab.name)}
                          className={classNames(
                            tab.current
                              ? "bg-white text-gray-900 dark:text-gray-100 dark:bg-transparent"
                              : "bg-transparent text-gray-500 hover:text-gray-700 dark:bg-gray-900 dark:bg-opacity-30 dark:border-gray-700",
                            tabIdx === 0 ? "" : "",
                            tabIdx > 1 ? "" : "",
                            tabIdx === tabs.length - 1 ? "" : "",
                            "border-l border-t border-r rounded-t-lg group relative min-w-0 flex-1 overflow-hidden py-[20px] text-md font-medium text-center hover:bg-gray-50 dark:hover:bg-gray-700 dark:hover:text-gray-300 focus:z-10"
                          )}
                          aria-current={tab.current ? "page" : undefined}
                        >
                          <span>
                            {currentStudyScreenName && tab.name == "Studies"
                              ? <span className="justify-center flex">
                                {tabLabelIcon(tab)}
                                {currentStudyScreenName}
                              </span>
                              :
                              <span className="justify-center flex flex-nowrap">
                                {tabLabelIcon(tab)}
                                {tab.name === 'Studies' ? screens.length : ''} {tab.name}
                              </span>
                            }
                          </span>
                          <span
                            aria-hidden="true"
                            className="absolute inset-x-0 bottom-0 h-0.5"
                          />
                        </Link>
                    ))}
                  </nav>
                </div>
                {/* End Tab Bar */}
                <IdleTimerProvider
                // ref={idleTimer}
                // timeout={1000 * 3}
                // onPrompt={onPrompt}
                // onIdle={onIdle}
                // onActive={onActive}
                // onAction={onAction}
                >
                  <Outlet
                    context={{
                      patient,
                      mutatePatient,
                      setModalOpen,
                      setNewAppointmentModalOpen,
                      setNewMedicationModalOpen,
                      setNewConditionModalOpen,
                      setNewVisitModalOpen,
                      setEditVisitModalOpen,
                      setIneligibleModalOpen,
                      ineligibleModalOpen,
                      setCurrentStudyScreenName,
                      setCurrentStudyScreenId,
                      currentStudyScreenId,
                      contactDispositionOpen,
                      setContactDispositionOpen,
                      setContactModalOpen,
                      setContactModalEditingId,
                      setScheduleInESourceModalOpen,
                      setCurrentVisitId,
                    }}
                  />
                </IdleTimerProvider>
                {/* End Main Content */}
              </div>
            </div>
          </div>
        </main>
      </div>
      {/* Modals */}
      {/* They are here because of weird Z-indexy stuff with the sidebar.  future todo to figure that out and move into their respective components */}
      {/* Or: just provide a place where a sub-component can pass a modal component and render it here with an outlet or something */}
      {prompted && <TimeoutModal resumeAfterIdle={resumeAfterIdlePrompt} />}
      <ChatDrawer isOpen={chatDrawerIsOpen} setIsOpen={setChatDrawerIsOpen} patientId={patient?.id} onMessageSent={mutateContacts} />
      {newAppointmentModalOpen && (
        <NewAppointmentModal
          patientId={patientId}
          setOpen={setNewAppointmentModalOpen}
          refreshData={mutatePatientAppointments}
        />
      )}
      {scheduleInESourceModalOpen && (
        <ScheduleInESourceModal
          patientId={patientId}
          screenId={screenId}
          setOpen={setScheduleInESourceModalOpen}
        />
      )}
      {newMedicationModalOpen && (
        <NewMedicationModal
          patientId={patientId}
          setOpen={setNewMedicationModalOpen}
          refreshData={mutatePatientMedications}
        />
      )}
      {newConditionModalOpen && (
        <NewConditionModal
          patientId={patientId}
          setOpen={setNewConditionModalOpen}
          refreshData={mutatePatientConditions}
        />
      )}
      {newVisitModalOpen && (
        <NewVisitModal
          patientId={patientId}
          setCurrentVisitId ={setCurrentVisitId}
          setOpen={setNewVisitModalOpen}
          refreshData={mutatePatientVisits}
        />
      )}
      {editVisitModalOpen && (
        <EditVisitModal
          visitId={currentVisitId}
          setOpen={setEditVisitModalOpen}
          refreshData={mutatePatientVisits}
        />
      )}
      {contactModalOpen && (
        <ContactModal
          patientId={patientId}
          screenId={currentStudyScreenId}
          setOpen={setContactModalOpen}
          contactId={contactModalEditingId}
          setContactModalEditingId={setContactModalEditingId}
          refreshData={mutateContacts}
        />
      )}
      {ineligibleModalOpen && (
        <IneligibleModal
          setOpen={setIneligibleModalOpen}
          setCurrentStudyScreenName={setCurrentStudyScreenName}
          setCurrentStudyScreenId={setCurrentStudyScreenId}
        />
      )}
      {bookmarkModalOpen && (
        <BookmarkModal
          setOpen={setBookmarkModalOpen}
        />
      )}
      {/* { modalOpen == ModalType.BulkAssign && <BulkAssignModal setOpen={setModalOpen} />} */}
    </>
  )
}
