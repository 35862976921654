import {
  type GridFilterModel,
  gridSortModelSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import { Button, Label, Popover, Tooltip } from "flowbite-react"
import { useContext, useEffect, useState } from "react"
import { HeroIcon } from "../../components/hero-icon"
import { managerOrUser } from "../../components/protected-admin"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { statuses } from "../../shared/statuses"
import { snakeToTitle } from "../../shared/text-utils"
import { useIndex } from "../../shared/use-rest"
import { useIndexQuery } from "../../shared/use-rest-query"
import { PopoverTheme } from "../../styles/flowbite-themes"
import { SearchableDropDown } from "./components/searchable-drop-down"

export const ChartReviewDirectoryFilters = ({
  apiRef,
  filterAndSort,
  setFilterAndSort,
  customSortableColumns,
  studyFilterOn,
  setStudyFilterOn,
  onlyStudyFilterOn,
  setOnlyStudyFilterOn,
  defaultStudySorts,
  defaultSorts,
}) => {
  const { currentUser } = useContext(AuthorizationContext)
  const [open, setOpen] = useState(false)
  const { data: users, isLoading: isLoadingUsers } = useIndex("users")
  const [meFirstUsers, setMeFirstUsers] = useState<unknown[]>([])

  const { data: practiceStudiesData, isLoading: isLoadingPracticeStudies } =
    useIndex("practice_studies")

  const [practiceStudies, setPracticeStudies] = useState([])
  const [studyAbbreviationToStudyId, setStudyAbbreviationToStudyId] = useState(
    {},
  )

  useEffect(() => {
    if (
      filterAndSort?.filtering?.filters?.some(
        (filter) => filter.field === "study",
      )
    ) {
      setStudyFilterOn(true)
    } else {
      setStudyFilterOn(false)
    }
  }, [filterAndSort, setStudyFilterOn])

  useEffect(() => {
    if (
      filterAndSort?.filtering?.filters?.length === 1 &&
      filterAndSort?.filtering?.filters?.some(
        (filter) => filter.field === "study",
      ) &&
      filterAndSort?.sorting?.length === 0
    ) {
      setOnlyStudyFilterOn(true)
    } else {
      setOnlyStudyFilterOn(false)
    }
  }, [filterAndSort, setOnlyStudyFilterOn])

  const queryClient = useQueryClient()

  const {
    data: { data: bookmarkTemplates },
  } = useIndexQuery("bookmark_templates")

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const studyAbbreviation = filterAndSort?.filtering?.filters?.find(
      (filter) => filter.field === "study",
    )?.value

    const studyId = studyAbbreviationToStudyId?.[studyAbbreviation]
    const bookmarkTemplate = JSON.parse(
      bookmarkTemplates?.find(
        (bookmarkTemplate) => bookmarkTemplate?.study_id === studyId,
      )?.content ?? "{}",
    )
    if (Object.keys(bookmarkTemplate).length !== 0) {
      setFilterAndSort(bookmarkTemplate)
    }
  }, [onlyStudyFilterOn])

  useEffect(() => {
    if (isLoadingPracticeStudies) return
    setPracticeStudies(
      practiceStudiesData.filter(
        (practiceStudy) => practiceStudy?.study?.status !== "Closed",
      ),
    )
    const studyAbbreviationToStudyIdMap = {}
    for (const practiceStudy of practiceStudiesData) {
      studyAbbreviationToStudyIdMap[practiceStudy?.study?.abbreviation] =
        practiceStudy?.study?.id
    }

    setStudyAbbreviationToStudyId(studyAbbreviationToStudyIdMap)
    return () => {}
  }, [practiceStudiesData, isLoadingPracticeStudies])

  useEffect(() => {
    if (isLoadingUsers) return

    setMeFirstUsers([
      ...users.filter((user) => user.id === currentUser.id),
      ...users
        .filter((user) => user.id !== currentUser.id && managerOrUser(user))
        .sort((a, b) => a.name.localeCompare(b.name)),
    ])
  }, [users, currentUser, isLoadingUsers])

  const handleClearFiltersClick = () => {
    apiRef.current.setFilterModel({ items: [] }, "upsertFilterItems")
    setFilterAndSort((filterAndSort) => ({
      filtering: {
        filters: [],
        link_operator: filterAndSort?.link_operator,
      },
      sorting: filterAndSort?.sorting,
    }))
  }

  const handleScreensFilterClick = (field, value) => {
    const filtering = filterAndSort?.filtering ?? {}
    const currentFilters = filtering?.filters ?? []
    const indexToUpdate = currentFilters.findIndex(
      (item) => item.field === field,
    )

    const newFilter = {
      id: field,
      field,
      operator_value: "equals",
      value,
    }

    if (indexToUpdate === -1) {
      currentFilters.push(newFilter)
    } else if (
      currentFilters[indexToUpdate].operator_value === "equals" &&
      currentFilters[indexToUpdate].value !== value
    ) {
      currentFilters[indexToUpdate] = {
        id: field,
        field,
        operator_value: "is_any_of",
        value: [currentFilters[indexToUpdate].value, value],
      }
    } else if (
      currentFilters[indexToUpdate].operator_value === "is_any_of" &&
      !currentFilters[indexToUpdate].value.includes(value)
    ) {
      currentFilters[indexToUpdate].value.push(value)
    } else if (
      currentFilters[indexToUpdate].operator_value === "is_any_of" &&
      currentFilters[indexToUpdate].value.includes(value)
    ) {
      const indexToRemove = currentFilters[indexToUpdate].value.findIndex(
        (item) => item === value,
      )
      currentFilters[indexToUpdate].value.splice(indexToRemove, 1)

      if (currentFilters[indexToUpdate].value.length === 1) {
        currentFilters[indexToUpdate] = {
          id: field,
          field,
          operator_value: "equals",
          value: currentFilters[indexToUpdate].value[0],
        }
      }
    } else {
      currentFilters.splice(indexToUpdate, 1)
    }

    filtering.filters = [...currentFilters]

    setFilterAndSort({
      ...filterAndSort,
      filtering: {
        ...filterAndSort.filtering,
        filters: [...currentFilters],
      },
    })

    const newGridFilterModel: GridFilterModel = {
      items: filtering?.filters.map(({ field, operator_value, value }) => ({
        id: field,
        field: field,
        operator: operator_value,
        value,
      })),
    }

    apiRef.current.setFilterModel(newGridFilterModel, "upsertFilterItems")
  }

  const gridFilterModelContainsScreenItem = (field, value) =>
    filterAndSort?.filtering?.filters?.some(
      (item) =>
        (item.operator_value === "equals" &&
          item?.field === field &&
          item?.value === value) ||
        (item.operator_value === "is_any_of" &&
          item?.field === field &&
          item?.value.includes(value)),
    )

  const gridSortModel = useGridSelector(apiRef, gridSortModelSelector)
  const gridSortModelContainsItem = (field) =>
    gridSortModel?.some(
      (item) => item?.field === field && item?.sort !== undefined,
    )

  const gridSortDirection = (field) =>
    gridSortModel?.find((item) => item?.field === field)?.sort

  const handleSortClick = (field) => {
    if (gridSortModelContainsItem(field)) {
      const newSortModel = gridSortModel
        .map((item) => {
          if (item?.field === field && item?.sort === "desc")
            return { ...item, sort: "asc" }
        })
        .filter((item) => item !== undefined)
      newSortModel && apiRef.current.setSortModel(newSortModel)
    } else {
      apiRef.current.setSortModel([{ field: field, sort: "desc" }])
    }
  }

  const handleClearSortClick = () => apiRef.current.setSortModel([])

  return (
    <Popover
      open={open}
      onOpenChange={setOpen}
      theme={PopoverTheme}
      content={
        <div style={{ width: "400px", overflowY: "scroll", maxHeight: "70vh" }}>
          {!isLoadingPracticeStudies && (
            <form>
              <div className="flex w-100 flex-col gap-4 p-4 text-sm text-gray-500 dark:text-gray-400">
                <div className="flex flex-col gap-2">
                  <div className="justify-between flex flex-row">
                    <h2 id="area-popover" className="text-base text-gray-500">
                      Filter
                    </h2>
                    <div
                      className="ml-2 font-normal text-xs underline self-center hover:cursor-pointer"
                      onClick={() => handleClearFiltersClick()}
                      onKeyUp={() => handleClearFiltersClick()}
                    >
                      Clear All Filters
                    </div>
                  </div>

                  {/* Study */}
                  <div className="mt-2 block">
                    <Label htmlFor="study" value="Study" />
                  </div>
                  <div className="max-w-md w-full">
                    <SearchableDropDown
                      options={practiceStudies?.map(
                        (study) => study?.study?.abbreviation,
                      )}
                      valueIsSelected={(value) =>
                        gridFilterModelContainsScreenItem("study", value)
                      }
                      setValue={(value) =>
                        handleScreensFilterClick("study", value)
                      }
                    />
                    <div className="mt-2 flex flex-wrap gap-2">
                      {practiceStudies?.map(
                        (study) =>
                          gridFilterModelContainsScreenItem(
                            "study",
                            study?.study?.abbreviation,
                          ) && (
                            <span
                              key={study.id}
                              className={
                                "px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm flex items-center"
                              }
                            >
                              {study?.study?.abbreviation}
                              <button
                                type="button"
                                className="ml-1 text-xs font-bold focus:outline-none"
                                onClick={() =>
                                  handleScreensFilterClick(
                                    "study",
                                    study?.study?.abbreviation,
                                  )
                                }
                              >
                                x
                              </button>
                            </span>
                          ),
                      )}
                    </div>
                  </div>
                  {/* Status */}
                  <div className="mt-4 block">
                    <Label htmlFor="status" value="Status" />
                  </div>
                  <div className="max-w-md w-full">
                    <SearchableDropDown
                      options={statuses}
                      valueIsSelected={(value) =>
                        gridFilterModelContainsScreenItem("status", value)
                      }
                      setValue={(value) =>
                        handleScreensFilterClick("status", value)
                      }
                      sortOptions={false}
                      formatDisplay={(value) => snakeToTitle(value)}
                    />
                    <div className="mt-2 flex flex-wrap gap-2">
                      {statuses?.map(
                        (status) =>
                          gridFilterModelContainsScreenItem(
                            "status",
                            status,
                          ) && (
                            <span
                              key={status}
                              className={
                                "px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm flex items-center"
                              }
                            >
                              {snakeToTitle(status)}
                              <button
                                type="button"
                                className="ml-1 text-xs font-bold focus:outline-none"
                                onClick={() =>
                                  handleScreensFilterClick("status", status)
                                }
                              >
                                x
                              </button>
                            </span>
                          ),
                      )}
                    </div>
                  </div>
                  {/* Assigned */}
                  <div className="mt-4 block">
                    <Label htmlFor="assigned" value="Assigned" />
                  </div>
                  <div className="max-w-md w-full">
                    <SearchableDropDown
                      options={meFirstUsers}
                      getSearchValue={(user) => user?.name}
                      getSubmitValue={(user) => user?.id}
                      valueIsSelected={(value) =>
                        gridFilterModelContainsScreenItem("assigned", value)
                      }
                      setValue={(value) =>
                        handleScreensFilterClick("assigned", value)
                      }
                      sortOptions={false}
                      formatDisplay={(value) =>
                        value?.name !== currentUser?.name
                          ? value.name
                          : `${value.name} (Me)`
                      }
                    />
                    <div className="mt-2 flex flex-wrap gap-2">
                      {meFirstUsers?.map(
                        (user) =>
                          gridFilterModelContainsScreenItem(
                            "assigned",
                            user?.id,
                          ) && (
                            <span
                              key={user.id}
                              className={
                                "px-2 py-1 bg-blue-100 text-blue-800 rounded-full text-sm flex items-center"
                              }
                            >
                              {user?.name !== currentUser?.name
                                ? user.name
                                : `${user.name} (Me)`}
                              <button
                                type="button"
                                className="ml-1 text-xs font-bold focus:outline-none"
                                onClick={() =>
                                  handleScreensFilterClick("assigned", user.id)
                                }
                              >
                                x
                              </button>
                            </span>
                          ),
                      )}
                    </div>
                  </div>
                  <hr className="my-4 border-t-2" />
                  {/* Sorts */}
                  <div className="justify-between flex flex-row">
                    <h2 id="area-popover" className="text-base text-gray-500">
                      Sort
                    </h2>
                    <div
                      className="ml-2 font-normal text-xs underline self-center hover:cursor-pointer"
                      onClick={() => handleClearSortClick()}
                      onKeyUp={() => handleClearSortClick()}
                    >
                      Clear All Sorts
                    </div>
                  </div>
                  {/* Sorts */}
                  <div className="flex-inline items-center space-y-2">
                    {customSortableColumns
                      ?.filter((column) => column !== "")
                      ?.map((column) => (
                        <button
                          key={`customSortableColumns${column}`}
                          type="button"
                          onClick={() => handleSortClick(column)}
                          className={`mr-2 inline-flex items-center p-2 border border-blue-800 shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 ${
                            gridSortModelContainsItem(column)
                              ? "text-white bg-blue-800  dark:bg-gray-800 dark:text-blue-500 hover:bg-blue-100 hover:text-blue-800"
                              : "text-blue-800 bg-white hover:bg-blue-300 hover:text-topo-white"
                          }`}
                        >
                          <HeroIcon
                            icon={
                              gridSortDirection(column) === "asc"
                                ? "SortAscendingIcon"
                                : "SortDescendingIcon"
                            }
                            className={`h-4 mr-1 ${
                              gridSortModelContainsItem(column)
                                ? "text-white"
                                : "text-blue-800"
                            }`}
                            outline={true}
                          />
                          {snakeToTitle(column)}
                        </button>
                      ))}
                    {studyFilterOn &&
                      defaultStudySorts?.map(({ field, direction }) => (
                        <div
                          key={`customSortableColumns${field}`}
                          className={"inline-flex"}
                        >
                          <Tooltip
                            content={"Default filter, cannot be changed."}
                          >
                            <button
                              type="button"
                              className="mr-2 inline-flex items-center p-2 border border-blue-800 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-800  dark:bg-gray-800 dark:text-blue-500"
                            >
                              <HeroIcon
                                icon={
                                  direction === "asc"
                                    ? "SortAscendingIcon"
                                    : "SortDescendingIcon"
                                }
                                className={"h-4 mr-1 text-white"}
                                outline={true}
                              />
                              {snakeToTitle(field)}
                            </button>
                          </Tooltip>
                        </div>
                      ))}
                    {defaultSorts?.map(({ field, direction }) => (
                      <div
                        key={`customSortableColumns${field}`}
                        className={"inline-flex"}
                      >
                        <Tooltip content={"Default filter, cannot be changed."}>
                          <button
                            type="button"
                            className="mr-2 inline-flex items-center p-2 border border-blue-800 shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-blue-800  dark:bg-gray-800 dark:text-blue-500"
                          >
                            <HeroIcon
                              icon={
                                direction === "asc"
                                  ? "SortAscendingIcon"
                                  : "SortDescendingIcon"
                              }
                              className={"h-4 mr-1 text-white"}
                              outline={true}
                            />
                            {snakeToTitle(field)}
                          </button>
                        </Tooltip>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex gap-2 justify-end p-4">
                <Button color="gray" onClick={() => setOpen(false)}>
                  Close
                </Button>
              </div>
            </form>
          )}
        </div>
      }
    >
      <button
        type="button"
        className={
          "mr-2 inline-flex items-center p-2 pb-1.5 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white hover:cursor-default"
        }
      >
        <HeroIcon
          icon="FilterIcon"
          className={"h-5 w-7 mr-1 text-white"}
          outline={true}
        />
      </button>
    </Popover>
  )
}
