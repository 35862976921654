import { useAuth0 } from "@auth0/auth0-react"
import { Button, Select, TextInput } from "flowbite-react"
import React, { useState } from "react"
import { useCreate } from "../../shared/use-rest"
import { useIndexQuery } from "../../shared/use-rest-query"

function BatchStats({ stats }) {
  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h2 className="text-xl font-bold">Queue Stats</h2>
      <div className="flex flex-col gap-4">Unreserved: {stats?.queue}</div>
      <div className="flex flex-col gap-4">
        Reserved by others:{" "}
        {stats?.batched - stats?.batched_by_user > 0
          ? stats?.batched - stats?.batched_by_user
          : 0}
      </div>
      <div className="flex flex-col gap-4">
        Reserved by you: {stats?.batched_by_user}
      </div>
    </div>
  )
}

export default BatchStats
