import { useQueryClient } from "@tanstack/react-query"
import {
  Badge,
  Button,
  Card,
  Select,
  Sidebar,
  Tabs,
  Tooltip,
} from "flowbite-react"
import { useEffect, useState } from "react"
import { FaExternalLinkAlt } from "react-icons/fa"
import { HiClock, HiViewList } from "react-icons/hi"
import { Link, useNavigate } from "react-router-dom"
import { HeroIcon } from "../../components/hero-icon"
import { StudyScreenStatusChip } from "../../components/patients/study-screen-status-chip"
import { snakeToTitle } from "../../shared/text-utils"
import { useShow } from "../../shared/use-rest"
import { useIndexQuery, useShowQuery } from "../../shared/use-rest-query"
import BatchCreation from "./chart-review-batch-creator"
import BatchStats from "./chart-review-batch-stats"
import BatchDeletion from "./chart-review-clear-batch"

function HistorySidebar({
  studyId,
  setStudyId,
  batchesByStudyId,
  studies,
  currentScreenIndex,
  batch,
  refetchBatch,
  setCurrentScreenId,
}) {
  const [expanded, setExpanded] = useState(true) // Start expanded
  const [activeTab, setActiveTab] = useState("batch")
  const [batchHistoriesLimit, setBatchHistoriesLimit] = useState(25)

  const navigate = useNavigate()
  const {
    data: { data: batchHistories },
    refetch: refetchBatchHistories,
  } = useIndexQuery(
    "screen_review_histories",
    `study_id=${studyId}&limit=${batchHistoriesLimit}`,
  )

  const {
    data: { data: batchStats },
  } = useShowQuery("batch_counts", studyId)

  const queryClient = useQueryClient()
  const refetchBatchStats = () => {
    queryClient.invalidateQueries({
      queryKey: ["batch_counts", studyId],
    })
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refetchBatchHistories()
    refetchBatchStats()
  }, [batch])

  const handleNavExpand = () => {
    setExpanded(!expanded)
  }

  const handleIconClick = (tab) => {
    setActiveTab(tab)
    setExpanded(true)
  }

  const getColorClass = (tier, max) => {
    const mid = Math.round(max / 2)
    const tierColorValue = Math.min(max, Math.max(0, tier))

    if (tierColorValue <= mid) {
      // Green at lower tiers...
      return "success"
    }
    if (tierColorValue > mid && tierColorValue < max) {
      return "warning"
    }
    return "failure"
  }

  // highlight the currently selected screen in the batch or history
  // fix the height of the page
  //
  return (
    <div className="flex h-screen">
      {" "}
      {/* Sidebar */}
      <Sidebar
        aria-label="Sidebar with review history"
        className={`bg-gray-100 ${expanded ? "" : "w-40"}`}
        style={{ width: expanded ? "18vw" : "3vw" }}
      >
        <Sidebar.Items>
          <Sidebar.ItemGroup>
            {/* Chevron for expanding/collapsing */}
            <div className="relative h-full">
              <div
                className={`flex hover:cursor-pointer ${
                  expanded ? "justify-end mr-2" : "justify-center"
                }`}
                onClick={handleNavExpand}
                onKeyUp={handleNavExpand}
                title={expanded ? "Minimize" : "Expand"} // Tooltip for chevron
              >
                <HeroIcon
                  icon={expanded ? "ChevronLeftIcon" : "ChevronRightIcon"}
                  className={"w-6 h-6 text-gray-900"}
                />
              </div>
            </div>

            {/* Icons for History and Batch */}
            {!expanded && (
              <div className="flex flex-col items-center mt-2 space-y-4">
                <HiClock
                  className="cursor-pointer text-gray-600 hover:text-gray-900 w-5 h-5"
                  onClick={() => handleIconClick("history")}
                  title="History" // Tooltip for history icon
                />
                <HiViewList
                  className="cursor-pointer text-gray-600 hover:text-gray-900 w-5 h-5"
                  onClick={() => handleIconClick("batch")}
                  title="Current Batch" // Tooltip for current batch icon
                />
              </div>
            )}

            {/* Tabs for History and Current Batch */}
            {expanded && (
              <div className="space-y-4">
                <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
                  <h2 className="text-xl font-bold">Select a Study</h2>

                  <Select
                    id="study"
                    value={studyId}
                    onChange={(e) => setStudyId(e.target.value)}
                    required
                  >
                    <option value="" defaultChecked disabled>
                      Select a study...
                    </option>
                    {studies.map((study) => (
                      <option key={study.study_id} value={study.study_id}>
                        {study.study.abbreviation} ({study.study.name})
                      </option>
                    ))}
                  </Select>
                </div>
                {batchStats && <BatchStats stats={batchStats} />}
                {!batch || batch?.length === 0 ? (
                  <BatchCreation
                    studyId={studyId}
                    setStudyId={setStudyId}
                    studies={studies}
                    refetchBatch={refetchBatch}
                  />
                ) : (
                  <BatchDeletion
                    studyId={studyId}
                    refetchBatch={refetchBatch}
                  />
                )}
                <Tabs
                  aria-label="History and Current Batch Tabs"
                  variant="underline"
                >
                  <Tabs.Item
                    active={activeTab === "current_batch"}
                    title="Current Batch"
                    icon={HiViewList}
                  >
                    <div className="space-y-3">
                      {batch && batch?.length > 0 ? (
                        batch?.map((item, index) => (
                          <Card
                            key={item.id}
                            onClick={() => {
                              setCurrentScreenId(item.id)
                            }}
                            className={`text-sm relative cursor-pointer ${index === currentScreenIndex ? "bg-blue-100" : ""}`}
                          >
                            <div className="flex flex-row  ">
                              <Link
                                to={`/patients/${item?.patient?.id}/screens/${item?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="absolute top-0 right-0 p-2"
                              >
                                <FaExternalLinkAlt />
                              </Link>
                              <div className="space-y-1 w-3/4">
                                <div>
                                  <strong>Patient:</strong>{" "}
                                  {`${item.patient.given_name} ${item.patient.family_name}`}
                                </div>
                                <div>
                                  <strong>Status:</strong>{" "}
                                  {snakeToTitle(item.status)}
                                </div>
                                <div>
                                  <strong>Updated:</strong>{" "}
                                  {`${new Date(item.updated_at).toLocaleString(
                                    undefined,
                                    {
                                      timeZoneName: "short",
                                    },
                                  )}`}
                                </div>
                              </div>

                              <div className="w-1/4 flex justify-center items-center">
                                <Tooltip content={item?.tier_description}>
                                  <Badge color={getColorClass(item.tier, 21)}>
                                    Tier: {item.tier ? item.tier : "-"}
                                  </Badge>
                                </Tooltip>
                              </div>
                            </div>
                          </Card>
                        ))
                      ) : (
                        <p>This batch is empty.</p>
                      )}
                    </div>
                  </Tabs.Item>
                  <Tabs.Item
                    active={activeTab === "history"}
                    title="History"
                    icon={HiClock}
                  >
                    {batchHistories && batchHistories?.length > 0 ? (
                      <div className="space-y-3">
                        {batchHistories.map((item) => (
                          <Card key={item.id} className="text-sm relative">
                            <div className="flex flex-row  ">
                              <Link
                                to={`/patients/${item?.patient?.id}/screens/${item?.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="absolute top-0 right-0 p-2"
                              >
                                <FaExternalLinkAlt />
                              </Link>
                              <div className="space-y-1 w-3/4">
                                <div>
                                  <strong>Patient:</strong>{" "}
                                  {`${item.patient.given_name} ${item.patient.family_name}`}
                                </div>
                                <div>
                                  <strong>Status:</strong>{" "}
                                  {snakeToTitle(item.status)}
                                </div>
                                <div>
                                  <strong>Updated:</strong>{" "}
                                  {`${new Date(item.updated_at).toLocaleString(
                                    undefined,
                                    {
                                      timeZoneName: "short",
                                    },
                                  )}`}
                                </div>
                              </div>

                              <div className="w-1/4 flex justify-center items-center">
                                <Tooltip content={item?.tier_description}>
                                  <Badge color={getColorClass(item.tier, 21)}>
                                    Tier: {item.tier ? item.tier : "-"}
                                  </Badge>
                                </Tooltip>
                              </div>
                            </div>
                          </Card>
                        ))}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            padding: "2rem",
                          }}
                        >
                          <Button
                            onClick={() => {
                              setBatchHistoriesLimit(batchHistoriesLimit + 25)
                            }}
                          >
                            Load More...
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <p>No history.</p>
                    )}
                  </Tabs.Item>
                </Tabs>
              </div>
            )}
          </Sidebar.ItemGroup>
        </Sidebar.Items>
      </Sidebar>
    </div>
  )
}

export default HistorySidebar
