import { useAuth0 } from "@auth0/auth0-react"
import { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { HeroIcon } from "../../components/hero-icon"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { messagingEnabled } from "../../shared/feature-flag-provider"
import { friendlyDateTime } from "../../shared/friendly-dates"
import { statusOptions } from "../../shared/statuses"
import { useCreate, useIndex, useUpdate } from "../../shared/use-rest"

import { useQueryClient } from "@tanstack/react-query"
import { useShowQuery } from "../../shared/use-rest-query"
import { ChatDrawer } from "../messaging/chat-drawer"
import { ContactModal } from "../patients/contact-modal"

const iconForContactType = (contactType) => {
  switch (contactType) {
    case "phone_call":
      return "PhoneIcon"
    case "in_person":
      return "UsersIcon"
    case "sms":
      return "ChatAltIcon"
    case "email":
      return "AtSymbolIcon"
    case "postcard_letter":
      return "MailOpenIcon"
    default:
      return "AnnotationIcon"
  }
}

export function Contacts({ screen, mutateScreen, isLoadingScreen }) {
  const screenId = screen?.id

  const {
    data: { data: patient },
    isFetching: isFetchingPatient,
    isLoading,
    isError,
  } = useShowQuery("patients", screen?.patient_id)

  const queryClient = useQueryClient()
  const mutatePatient = () => {
    queryClient.invalidateQueries({
      queryKey: ["patients", screen?.patient_id],
    })
  }

  const [contactDispositionOpen, setContactDispositionOpen] = useState(false)
  const [contactModalOpen, setContactModalOpen] = useState(false)
  const [contactModalEditingId, setContactModalEditingId] = useState(null)
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false)
  const { getAccessTokenSilently } = useAuth0()

  const {
    data: contacts,
    isLoading: isLoadingContacts,
    isError: isErrorContacts,
    mutate: mutateContacts,
  } = useIndex("contacts", `patient_id=${patient?.id}`)

  const handleEdit = (contactId) => {
    setContactModalEditingId(contactId)
    setContactModalOpen(true)
  }

  const handleMakeCall = async () => {
    await useCreate(
      "contacts",
      {
        screen_id: screenId,
        patient_id: patient?.id,
        contact_type: "phone_call",
        contact_reason: "",
        contact_length: "",
        disposition: "",
        contacted_at: new Date().toISOString().slice(0, -1),
      },
      getAccessTokenSilently,
    ).then((response) => {
      setContactModalEditingId(response?.data.data?.id)
      event({
        eventName: "ContactSave",
        meta: {
          patient_id: patient?.id,
          screen_id: screenId,
          contact_id: response?.data?.data?.id,
        },
        getAccessTokenSilently,
      })
    })
    mutateContacts()
    setContactDispositionOpen(true)
  }

  const handleIDidntMakeACall = async () => {
    setContactDispositionOpen(false)
    if (contactModalEditingId) {
      await useUpdate(
        "contacts",
        contactModalEditingId,
        {
          deleted_at: new Date().toISOString().slice(0, -1),
        },
        getAccessTokenSilently,
      ).then((response) => {
        setContactModalEditingId("")
        event({
          eventName: "ContactDelete",
          meta: {
            patient_id: patient?.id,
            screen_id: screenId,
            contact_id: response?.data?.data?.id,
          },
          getAccessTokenSilently,
        })
      })
      mutateContacts()
    }
  }
  const { practiceName } = useContext(AuthorizationContext)

  const handleIMadeACall = async () => {
    setContactModalOpen(true)
    setContactDispositionOpen(false)
  }

  return (
    <div className="p-4 mb-4 rounded-lg border border-gray-200 shadow-md text-gray-700 bg-white dark:bg-transparent dark:text-gray-200">
      {patient?.id && (
        <ChatDrawer
          isOpen={chatDrawerOpen}
          setIsOpen={setChatDrawerOpen}
          patientId={patient?.id}
        />
      )}
      {contactModalOpen && patient?.id && screenId && (
        <ContactModal
          patientId={patient?.id}
          screenId={screenId}
          setOpen={setContactModalOpen}
          contactId={contactModalEditingId}
          setContactModalEditingId={setContactModalEditingId}
          refreshData={mutateContacts}
        />
      )}
      <div className={"sm:flex xl:block sm:space-x-4 xl:space-x-0"}>
        <div>
          <div className="flex justify-between">
            <h3 className="mb-2 text-base font-bold text-gray-900 dark:text-gray-200">
              Contact Log
            </h3>
          </div>
        </div>
      </div>
      <div className="flex flex-row mb-4">
        <a href={`tel:${patient?.preferred_phone || patient?.phone}`}>
          <button
            type="button"
            onClick={() => handleMakeCall()}
            className=" text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-3.5 py-1.5 text-center inline-flex items-center"
          >
            <HeroIcon icon="PhoneIcon" className="fill-white w-4 h-4 mr-2" />
            {patient?.preferred_phone || patient?.phone ? "Call" : "No Phone"}
          </button>
        </a>
        {messagingEnabled(practiceName) && (
          <button
            type="button"
            onClick={() => setChatDrawerOpen(true)}
            className=" ml-2 text-white bg-topo-navy hover:bg-topo-navy focus:bg-topo-navy focus:outline-none focus:ring-2 focus:ring-topo-navy-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
          >
            <HeroIcon icon="ChatIcon" className="fill-white w-4 h-4 mr-2" />
            Message
          </button>
        )}
        <button
          type="button"
          onClick={() => setContactModalOpen(true)}
          className=" ml-2 text-white bg-topo-navy hover:bg-topo-navy focus:bg-topo-navy focus:outline-none focus:ring-2 focus:ring-topo-navy-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
        >
          <HeroIcon
            icon="PlusCircleIcon"
            className="fill-white w-4 h-4 mr-2 rotate-90"
          />
          Log Contact
        </button>
      </div>
      {contactDispositionOpen && (
        <div className="mt-4 mb-2 bg-yellow-100 rounded p-2 pl-2 pt-2 shadow-md">
          <p className="font-medium text-yellow-700">Just made a call?</p>
          <p className="text-yellow-700 mb-2">
            Please fill out this call log before you move onto another patient.
          </p>
          <div className="flex flex-row">
            <button
              type="button"
              className={
                "inline-flex items-center p-2 pr-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-yellow-700 bg-yellow-300 hover:bg-yellow-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-500"
              }
              onClick={() => handleIMadeACall()}
            >
              <HeroIcon
                icon="EyeIcon"
                className={"h-5 w-7 mr-1 text-yellow-700"}
                outline
              />
              Update Call Info
            </button>
            <button
              type="button"
              className={
                "ml-2 inline-flex items-center p-2 pr-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-red-700 bg-red-300 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              }
              onClick={() => handleIDidntMakeACall()}
            >
              <HeroIcon
                icon="EyeIcon"
                className={"h-5 w-7 mr-1 text-red-700"}
                outline
              />
              I Didn't Make a Call
            </button>
          </div>
        </div>
      )}
      {contacts?.length === 0 && (
        <div className="text-xs mb-2">
          No Contacts Logged
          <div className="mt-2 underline">
            {/* <Link to={`/patients/${patient?.id}`}>View All</Link> */}
          </div>
        </div>
      )}

      {contacts?.map((contact) => (
        <div
          key={contact.id}
          className="flex flex-row mb-2 border-b border-gray-200 dark:border-gray-800 bg-gray-50  dark:bg-gray-700 dark:opacity-95 rounded p-1 pl-0 pt-2"
        >
          <div className="block w-full">
            <div className="flex justify-between">
              <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex">
                {" "}
                <HeroIcon
                  icon="UserIcon"
                  outline
                  className="h-4 mr-2 rounded-md"
                />
                {contact?.created_by?.name}
              </div>
              <button
                type="button"
                onClick={() => handleEdit(contact.id)}
                className={
                  "mr-2 align-middle group-hover:inline-block hover:text-gray-500"
                }
              >
                <HeroIcon className="h-4 w-4" icon="PencilAltIcon" />
              </button>
            </div>
            <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex">
              {" "}
              <HeroIcon
                icon="ClockIcon"
                outline
                className="h-4 mr-2 rounded-md"
              />
              {friendlyDateTime(contact.contacted_at)}
            </div>
            <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex">
              {" "}
              <HeroIcon
                icon={iconForContactType(contact.contact_type)}
                outline
                className="h-4 mr-2 rounded-md"
              />{" "}
              {contact.contact_reason}
              {contact.contact_reason && " - "}
              {contact.disposition}
            </div>
            {contact?.screen && (
              <div className="text-xs mb-1 font-medium mr-2 px-2.5 py-0.5 rounded-md whitespace-nowrap flex ">
                <HeroIcon
                  icon={
                    statusOptions[contact?.screen?.status]?.icon ||
                    "DotsHorizontalIcon"
                  }
                  outline
                  className="h-4 mr-2 rounded-md"
                />
                <Link
                  to={`/patients/${patient?.id}/screens/${contact.screen_id}`}
                >
                  {contact?.screen?.study?.abbreviation}
                </Link>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}
