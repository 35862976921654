import * as Sentry from "@sentry/browser"
import { useCreate } from "./use-rest"

export const event = async ({
  eventName,
  meta = {},
  getAccessTokenSilently,
}) => {
  try {
    const data = { name: eventName, meta: meta }
    if (process.env.ENV === "dev") {
      console.log(
        `Event: firing event with name: ${eventName} meta: ${JSON.stringify(
          meta,
        )}`,
      )
    }
    await useCreate("events", data, getAccessTokenSilently)
  } catch (error) {
    if (process.env.ENV === "dev") {
      console.error(`Error while firing event: ${error}`)
    } else {
      Sentry.captureException(error)
    }
  }
}
