import { useAuth0 } from "@auth0/auth0-react"
import { useQueryClient } from "@tanstack/react-query"
import { Label } from "flowbite-react"
import { type Dispatch, type SetStateAction, useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { ButtonLeadingIcon } from "../../../components/button-leading-icon"
import SearchableMultiSelect from "../../../components/searchable-multi-select"
import SubmitButton from "../../../components/submit-button"
import { statusOptions } from "../../../shared/statuses"
import { snakeToTitle } from "../../../shared/text-utils"
import { useCreate, useShow, useUpdate } from "../../../shared/use-rest"
import { useIndexQuery } from "../../../shared/use-rest-query"
import { useKeyPress } from "../../../shared/useKeyPress"

interface Props {
  studyId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData: () => void
  open?: boolean
}

const DEFAULT_FILTER_AND_SORT = {
  sorting: [],
  filtering: {
    filters: [],
    link_operator: "and",
  },
  search: "",
}

const SORT_FIELDS = [
  "created_at",
  "confidence_score",
  "next_appointment",
  "tier",
  "updated_at",
]

const STATUSES = Object.keys(statusOptions)

export function FilterAndSortEditModal(props: Props) {
  const { studyId } = props
  const queryClient = useQueryClient()
  const [isLoading, setIsLoading] = useState(false)
  const { getAccessTokenSilently } = useAuth0()
  const [bookmarkTemplate, setBookmarkTemplate] = useState({})
  const [bookmarkTemplateId, setBookmarkTemplateId] = useState(null)

  props.open = props.open ? props.open : false
  const { register, handleSubmit, getValues, setValue, reset, control } =
    useForm({
      defaultValues: {
        statuses: [],
        sorts: [],
        study: "",
      },
    })

  const {
    fields: sorts,
    append,
    remove,
  } = useFieldArray({
    control,
    name: "sorts",
  })

  const { data: study, mutate: mutateStudy } = useShow("admin/studies", studyId)

  const {
    data: { data: bookmarkTemplates },
  } = useIndexQuery("bookmark_templates", `study_id=${studyId}`)

  const mutateBookmarkTemplates = () => {
    queryClient.invalidateQueries({
      queryKey: ["bookmark_templates", `study_id=${studyId}`],
    })
  }

  useEffect(() => {
    if (bookmarkTemplates?.[0]) {
      setBookmarkTemplate(JSON.parse(bookmarkTemplates[0]?.content))
      setBookmarkTemplateId(bookmarkTemplates[0].id)
    } else {
      setBookmarkTemplate(DEFAULT_FILTER_AND_SORT)
    }
  }, [bookmarkTemplates])

  const addSort = () => {
    append({})
  }
  const removeSort = (index) => {
    remove(index)
  }

  const onKeyPress = (event) => {
    props.setOpen(false)
  }

  const closeModal = () => {
    props.setOpen(false)
  }

  useKeyPress(["Escape"], onKeyPress)

  const onSubmit = (data) => {
    const sorting = data.sorts.map((sort) => ({
      field: sort.field,
      sort: sort.order,
    }))

    let filters = []
    if (bookmarkTemplate?.filtering?.filters) {
      filters = [...bookmarkTemplate.filtering.filters]
    }

    if (data?.statuses) {
      filters = [
        ...filters.filter((filter) => filter.field !== "status"),
        {
          field: "status",
          operator_value: "is_any_of",
          value: data.statuses,
        },
      ]
    }

    if (study) {
      filters = [
        ...filters.filter((filter) => filter.field !== "study"),
        {
          field: "study",
          operator_value: "equals",
          value: study?.abbreviation,
        },
      ]
    }

    const filtering = {
      filters,
      link_operator: "and",
    }

    const updatedTemplate = {
      ...bookmarkTemplate,
      filtering,
      sorting,
    }

    setBookmarkTemplate(updatedTemplate)
    setIsLoading(true)
    if (bookmarkTemplateId) {
      useUpdate(
        "admin/bookmark_templates",
        bookmarkTemplateId,
        { filter_and_sort: updatedTemplate, study_id: study?.id },
        getAccessTokenSilently,
      )
        .then((response) => {
          mutateBookmarkTemplates()
        })
        .finally(() => setIsLoading(false))
    } else {
      useCreate(
        "admin/bookmark_templates",
        { filter_and_sort: updatedTemplate, study_id: study?.id },
        getAccessTokenSilently,
      )
        .then((response) => {
          mutateBookmarkTemplates()
        })
        .finally(() => setIsLoading(false))
    }
    props.setOpen(false)
  }

  return (
    <>
      <div
        className="fixed pt-10 pb-10 z-50 left-0 right-0 items-center justify-center overflow-x-hidden md:inset-0"
        id="edit-filter-sort-modal"
      >
        {study && (
          <div className="relative w-full h-full max-w-4xl px-4 md:h-auto mx-auto modal-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex w-100 flex-col gap-4 p-4 text-sm text-gray-500 dark:text-gray-400 w-full h-full max-w-4xl px-4 md:h-auto mx-auto modal-container">
                <div className="flex flex-col gap-2 bg-white rounded-lg shadow dark:bg-gray-800 space-y-4">
                  <div className="flex items-start p-5 border-b rounded-t dark:border-gray-700">
                    <h3 className="text-xl font-semibold dark:text-white">
                      Default Filter and Sort
                    </h3>
                    <button
                      type="button"
                      className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                      onClick={() => props.setOpen(false)}
                    >
                      <svg
                        className="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Exit</title>
                        <path
                          fillRule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="px-4 flex items-center justify-between">
                    <div className="mt-2 block">
                      <Label htmlFor="study" value="Study" />
                    </div>
                    <div className="max-w-md w-full">
                      <div className="mt-2 flex flex-wrap gap-2">
                        <select
                          id="study"
                          {...register("study")}
                          className="display:flex flex-grow shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          disabled
                          defaultValue={study?.abbreviation}
                        >
                          <option value={study?.id} key={study?.id}>
                            {study?.abbreviation}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="px-4 flex items-center justify-between">
                    <div className="mt-2 block">
                      <Label htmlFor="status" value="Statuses" />
                    </div>
                    <div className="max-w-md w-full">
                      <div className="mt-2 flex flex-wrap gap-2">
                        <SearchableMultiSelect
                          control={control}
                          name="statuses"
                          options={STATUSES}
                          getValue={() => getValues("statuses")}
                          setValue={(value) => {
                            setValue("statuses", value)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="px-4 flex  justify-between flex-col sm:flex-row">
                    <div className="mt-2 block">
                      <Label htmlFor="sorts" value="Sorts" />
                    </div>

                    <div className="max-w-md w-full space-y-4">
                      {sorts?.map((field, index) => (
                        <div
                          key={`${field.field}${index}`}
                          className="flex justify-between items-start mb-2"
                        >
                          <div className="w-full md:w-9/12">
                            <div className="flex justify-between space-x-4">
                              {/** Sort Field **/}
                              <div>
                                <select
                                  {...register(`sorts[${index}].field`)}
                                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                  defaultValue={field.field}
                                >
                                  {[
                                    ...SORT_FIELDS.filter(
                                      (sortField) =>
                                        !sorts.some(
                                          (field) => field.field === sortField,
                                        ),
                                    ),
                                    field?.field,
                                  ].map((sortField) => (
                                    <option
                                      key={`${sortField}${
                                        // biome-ignore lint/suspicious/noArrayIndexKey: <explanation>
                                        index
                                      }`}
                                      value={sortField}
                                    >
                                      {snakeToTitle(sortField)}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              {/** Order **/}
                              <div>
                                <select
                                  {...register(`sorts[${index}].order`)}
                                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                >
                                  <option defaultChecked value="desc">
                                    Descending
                                  </option>
                                  <option value="asc">Ascending</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {/** Remove Button **/}
                          <div className="flex flex-col justify-center md:justify-start md:ml-4">
                            <button
                              type="button"
                              className="text-red-600"
                              onClick={() => removeSort(index)}
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ))}

                      {/** Add Sort Button **/}
                      <div className="w-full mt-3 sm:mt-0 text-end">
                        <ButtonLeadingIcon
                          icon="PlusCircleIcon"
                          text="Add Sort"
                          onClick={() => addSort()}
                          onKeyUp={() => addSort()}
                          // className="inline-flex items-center justify-center h-10 w-1/2 px-3 py-2 text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:ring-green-300 sm:w-auto dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="items-center p-4 border-t border-gray-200 dark:border-gray-700 rounded-b text-right">
                    {isLoading ? (
                      <SubmitButton />
                    ) : (
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="submit"
                      >
                        Apply
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  )
}
