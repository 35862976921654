import { useAuth0 } from "@auth0/auth0-react"
import { Button, Select, TextInput } from "flowbite-react"
import React, { useState } from "react"
import { useCreate } from "../../shared/use-rest"

function BatchCreation({ studyId, setStudyId, studies, refetchBatch }) {
  const { getAccessTokenSilently } = useAuth0()
  const [batchSize, setBatchSize] = useState(25)

  const handleSubmit = async () => {
    const patient_appointment_response = await useCreate(
      "batches",
      { study_id: studyId, batch_size: batchSize },
      getAccessTokenSilently,
    )
    refetchBatch()
  }

  // TODO - add a note when a chart is under review by another person
  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h2 className="text-xl font-bold">Create Batch</h2>

      <TextInput
        type="number"
        id="batchSize"
        placeholder="Enter batch size)"
        min={0}
        max={200}
        value={batchSize}
        onChange={(e) => setBatchSize(Number(e.target.value))}
      />

      <Button onClick={handleSubmit}>Submit</Button>
    </div>
  )
}

export default BatchCreation
