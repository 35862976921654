import { Disclosure, Transition } from "@headlessui/react"
import {
  type GridFilterModel,
  type GridSortItem,
  gridExpandedSortedRowEntriesSelector,
  gridFilterModelSelector,
  gridSortModelSelector,
  useGridApiContext,
  useGridSelector,
} from "@mui/x-data-grid-pro"
import { Button, Label, Popover, TextInput, Tooltip } from "flowbite-react"
import { compact } from "lodash"
import { useContext, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import CustomDatePicker from "../../components/date-picker"
import { HeroIcon } from "../../components/hero-icon"
import { managerOrUser } from "../../components/protected-admin"
import { Spinner } from "../../components/spinner"
import { useParticles } from "../../particles-provider"
import { AuthorizationContext } from "../../shared/authorization-provider"
import {
  GridToolbarCustom,
  GridToolbarCustomColumnDetails,
} from "../../shared/mui-custom-toolbar-buttons"
import { statuses } from "../../shared/statuses"
import { snakeToTitle } from "../../shared/text-utils"
import useDebounce from "../../shared/use-debounce-hooks"
import { useIndex } from "../../shared/use-rest"
import { PopoverTheme } from "../../styles/flowbite-themes"
import { ChartReviewDirectoryFilters } from "./chart-review-directory-filters"
import { MultiselectSearchableDropDown } from "./components/multiselect-searchable-drop-down"
import { SearchableDropDown } from "./components/searchable-drop-down"
import { displayFilters } from "./display_filters"

export function ChartReviewDirectoryToolbar(props) {
  const {
    currentUser,
    navigate,
    setQuickAddOpen,
    filtersOpen,
    setFiltersOpen,
    customSortableColumns,
    columns,
    disableColumnFilter,
    setDisableColumnFilter,
    setFilterAndSort,
    filterAndSort,
    search,
    setSearch,
    isValidatingPatients,
    ...gridToolbarProps
  } = props
  const { practiceId } = useContext(AuthorizationContext) // TODO: MultiTenancy
  const { data: users, isLoading: isLoadingUsers } = useIndex("users")
  const [meFirstUsers, setMeFirstUsers] = useState<unknown[]>([])
  const apiRef = useGridApiContext()
  const [searchInput, setSearchInput] = useState(search)
  const [initSearch, setInitSearch] = useState(true)
  const { data: practiceStudiesData, isLoading: isLoadingPracticeStudies } =
    useIndex("practice_studies")
  const [practiceStudies, setPracticeStudies] = useState([])
  const { triggerConfetti } = useParticles()

  const [open, setOpen] = useState(false)
  const [studyFilterOn, setStudyFilterOn] = useState(false)
  const [onlyStudyFilterOn, setOnlyStudyFilterOn] = useState(false)

  const defaultStudySorts = [
    {
      field: "tier",
      direction: "asc",
    },
    {
      field: "confidence_score",
      direction: "desc",
    },
    {
      field: "feedback_score",
      direction: "desc",
    },
  ]

  const defaultSorts = [
    {
      field: "updated_at",
      direction: "desc",
    },
  ]

  useEffect(() => {
    if (isLoadingPracticeStudies) return
    setPracticeStudies(
      practiceStudiesData.filter(
        (practiceStudy) => practiceStudy?.study?.status !== "Closed",
      ),
    )
    return () => {}
  }, [practiceStudiesData, isLoadingPracticeStudies])

  useEffect(() => {
    if (isLoadingUsers) return

    setMeFirstUsers([
      ...users.filter((user) => user.id === currentUser.id),
      ...users
        .filter((user) => user.id !== currentUser.id && managerOrUser(user))
        .sort((a, b) => a.name.localeCompare(b.name)),
    ])
  }, [users, currentUser, isLoadingUsers])

  useEffect(() => {
    if (search !== "" && initSearch) {
      setSearchInput(search)
      setInitSearch(false)
    }
  }, [search, initSearch])

  const gridFilterModelContainsItem = (field, value) =>
    useGridSelector(apiRef, gridFilterModelSelector)?.items?.some(
      (item) => item.field === field && item.value === value,
    )

  const screensArrayIndex = { study: 0, status: 1, assigned: 2 }

  const gridFilterModelContainsScreenItem = (field, value) =>
    filterAndSort?.filtering?.filters?.some(
      (item) => item?.field === field && item?.value === value,
    )

  const handleScreensFilterClick = (field, value) => {
    const filtering = filterAndSort?.filtering ?? {}
    const currentFilters = filtering?.filters ?? []
    const indexToUpdate = currentFilters.findIndex(
      (item) => item.field === field,
    )

    const newFilter = {
      id: field,
      field,
      operator_value: "equals",
      value,
    }

    if (indexToUpdate === -1) {
      currentFilters.push(newFilter)
    } else if (currentFilters[indexToUpdate].value !== value) {
      currentFilters[indexToUpdate] = newFilter
    } else {
      currentFilters.splice(indexToUpdate, 1)
    }
    filtering.filters = [...currentFilters]

    const newGridFilterModel: GridFilterModel = {
      items: filtering?.filters.map(({ field, operator_value, value }) => ({
        field: field,
        operator: operator_value,
        value,
      })),
    }

    apiRef.current.setFilterModel(newGridFilterModel, "upsertFilterItems")
  }
  // Unused for now until another non-screen filter is added
  const handleFilterClick = (field, value) => {
    const gridFilterState = useGridSelector(
      apiRef,
      gridFilterModelSelector,
    )?.items

    const itemToDelete = gridFilterState
      .slice()
      .find(
        (gridFilterModelItem) =>
          gridFilterModelItem?.field === field &&
          gridFilterModelItem?.value === value,
      )
    if (itemToDelete) {
      apiRef.current.deleteFilterItem(itemToDelete)
      return
    }
    const operator = "equals" // TODO: store the operator in the URL or in the column def
    const newFilterItem = {
      id: uuidv4(),
      field: field,
      operator_value: operator,
      value: value,
    }
    // Delete other filters for the same column

    for (const gridFilterModelItem of gridFilterState) {
      if (gridFilterModelItem?.field === field) {
        apiRef.current.deleteFilterItem(gridFilterModelItem)
      }
    }

    apiRef.current.setFilterModel(newFilterItem)
  }

  const handleClearFiltersClick = () => {
    apiRef.current.setFilterModel({ items: [] }, "upsertFilterItems")
    setFilterAndSort((filterAndSort) => ({
      filtering: {
        filters: [],
        link_operator: filterAndSort?.link_operator,
      },
      sorting: filterAndSort?.sorting,
    }))
  }

  const gridSortModel = useGridSelector(apiRef, gridSortModelSelector)
  const gridSortModelContainsItem = (field) =>
    gridSortModel?.some(
      (item) => item?.field === field && item?.sort !== undefined,
    )

  const gridSortDirection = (field) =>
    gridSortModel?.find((item) => item?.field === field)?.sort

  const handleSortClick = (field) => {
    if (gridSortModelContainsItem(field)) {
      const newSortModel = gridSortModel
        .map((item) => {
          if (item?.field === field && item?.sort === "desc")
            return { ...item, sort: "asc" }
        })
        .filter((item) => item !== undefined)
      newSortModel && apiRef.current.setSortModel(newSortModel)
    } else {
      apiRef.current.setSortModel([{ field: field, sort: "desc" }])
    }
  }

  const handleClearSortClick = () => apiRef.current.setSortModel([])

  const quickFilterParser = (searchInput: string): string[] => {
    // trim whitespace, remove special hidden characters and + () - for phones
    return compact(
      searchInput.split(" ").map((input) =>
        input
          .trim()
          // biome-ignore lint/suspicious/noControlCharactersInRegex: <striping these out of input>
          .replace(/[\u0000-\u001F\u007F-\u009F\u200B\+\(\)\-]/g, ""),
      ),
    )
  }

  const debouncedSetSearch = useDebounce(
    (value) => {
      setSearch(value)
    },
    500,
    [],
  )

  const handleSearchChange = (event) => {
    setInitSearch(false)
    setSearchInput(event.target.value)
    debouncedSetSearch(event.target.value)
  }

  const clearSearch = () => {
    setSearchInput("")
    setSearch("")
  }

  const handleDeleteFilterItem = (item) => {
    const newFilters = [...filterAndSort.filtering.filters]

    const indexToDelete = newFilters.findIndex(
      (filter) => filter.field === item.field,
    )

    if (indexToDelete !== -1) {
      newFilters.splice(indexToDelete, 1)
    }

    apiRef.current.setFilterModel({ items: [] }, "upsertFilterItems")
    setFilterAndSort({
      filtering: {
        filters: newFilters,
        link_operator: filterAndSort.link_operator,
      },
      sorting: filterAndSort.sorting,
    })
  }

  const handleDeleteSortItem = (item) => {
    const newSorts = [...filterAndSort.sorting]

    const indexToDelete = newSorts.findIndex(
      (sort) => sort.field === item.field,
    )

    if (indexToDelete !== -1) {
      newSorts.splice(indexToDelete, 1)
    }

    apiRef.current.setSortModel([])
    setFilterAndSort({
      ...filterAndSort,
      sorting: newSorts,
    })
  }
  const [searchTerm, setSearchTerm] = useState("")
  const [isOpen, setIsOpen] = useState(false)

  const toolbarButtonStyle =
    "inline-flex items-center px-3 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-topo-blue hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
  const toolbarButtonGroupStyle =
    "inline-flex items-center px-4 border border-gray shadow-sm text-sm leading-4 font-medium text-gray-600 bg-gray-100 [&.active]:bg-topo-blue [&.active]:text-white [&.active]:border-transparent hover:bg-blue-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800"
  return (
    <div className="text-gray-900 dark:text-gray-200">
      <Disclosure>
        <div className="flex justify-between">
          <div className="flex gap-1 mr-1 mb-4">
            <div className="flex">
              <button
                type="button"
                onClick={() => {
                  triggerConfetti()
                  setQuickAddOpen(true)
                }}
                className={toolbarButtonStyle}
              >
                <HeroIcon
                  icon="PlusCircleIcon"
                  className={"h-5 w-7 mr-1 text-white"}
                  outline={false}
                />
                Quick Add
              </button>
            </div>

            <div className="flex">
              <GridToolbarCustomColumnDetails {...gridToolbarProps} />
            </div>
            <Tooltip
              content={
                'Enclose search terms in double quotes ("example a") to treat them as a single term. Separate all other terms with spaces to treat them individually.'
              }
            >
              <div className="relative ml-4">
                <input
                  placeholder="Search..."
                  type="text"
                  name="search"
                  id="search"
                  value={searchInput}
                  onChange={handleSearchChange}
                  className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg pl-10 pr-8 py-2.5 focus:ring-blue-500 focus:border-blue-500 block w-full dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                <HeroIcon
                  icon="SearchIcon"
                  className="absolute left-2 top-1/2 transform -translate-y-1/2 w-6 h-6"
                />
                {search && (
                  <div onClick={clearSearch} onKeyUp={clearSearch}>
                    <HeroIcon
                      icon="XIcon"
                      className="absolute top-1/2 right-2 transform -translate-y-1/2 w-6 h-6 cursor-pointer hover:text-red-500"
                    />
                  </div>
                )}
              </div>

              {false && (
                <div
                  className="flex"
                  onClick={() => navigate("/uploads")}
                  onKeyUp={() => navigate("/uploads")}
                >
                  <button type="button" className={toolbarButtonStyle}>
                    <HeroIcon
                      icon="UploadIcon"
                      className={"h-5 w-7 mr-1 text-white"}
                      outline={true}
                    />
                    Upload Patients
                  </button>
                </div>
              )}
            </Tooltip>
            <ChartReviewDirectoryFilters
              apiRef={apiRef}
              filterAndSort={filterAndSort}
              setFilterAndSort={setFilterAndSort}
              customSortableColumns={["created_at", "dob", ""]}
              studyFilterOn={studyFilterOn}
              setStudyFilterOn={setStudyFilterOn}
              onlyStudyFilterOn={onlyStudyFilterOn}
              setOnlyStudyFilterOn={setOnlyStudyFilterOn}
              defaultStudySorts={defaultStudySorts}
              defaultSorts={defaultSorts}
            />
            <div className="flex ml-4 items-center text-black dark:text-gray-300 text-xs">
              Viewing:{" "}
              {
                useGridSelector(apiRef, gridExpandedSortedRowEntriesSelector)
                  .length
              }{" "}
              of {apiRef.current.getRowsCount()} patients
            </div>
            {isValidatingPatients && (
              <div className="flex ml-4 items-center text-black dark:text-gray-300 text-md">
                <Spinner /> Loading...
              </div>
            )}
          </div>
          <GridToolbarCustom {...gridToolbarProps} />
        </div>
        <Transition
          enter="transition duration-100 ease-out origin-top"
          enterFrom="transform scale-y-0"
          enterTo="transform scale-y-100"
          leave="transition duration-100 ease-in origin-top"
          leaveFrom="transform scale-y-100"
          leaveTo="transform scale-y-0"
        >
          <Disclosure.Panel>
            <div className="mb-4 pb-4 hover:cursor-default border-b dark:border-gray-700">
              <div className="font-medium flex mb-4 text-base items-center">
                <HeroIcon
                  icon="FilterIcon"
                  className={"h-5 -ml-0.5 mr-1"}
                  outline={false}
                />{" "}
                Filters{" "}
                <div
                  className="ml-2 font-normal text-xs underline self-center hover:cursor-pointer"
                  onClick={() => handleClearFiltersClick()}
                  onKeyUp={() => handleClearFiltersClick()}
                >
                  Clear
                </div>
              </div>

              <div className="font-medium mb-2">Studies</div>
              <div className="flex">
                {practiceStudies?.map((study) => (
                  <button
                    key={study?.id}
                    type="button"
                    className={`mr-2 inline-flex items-center p-2 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 ${
                      gridFilterModelContainsScreenItem(
                        "study",
                        study?.study?.abbreviation,
                      )
                        ? "text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white"
                        : "text-topo-blue bg-white  dark:bg-gray-800 dark:text-blue-500 hover:bg-blue-800 hover:text-white"
                    }`}
                    onClick={() =>
                      handleScreensFilterClick(
                        "study",
                        study?.study?.abbreviation,
                      )
                    }
                  >
                    {study?.study?.abbreviation}
                  </button>
                ))}
              </div>
              <div className="font-medium mt-4 mb-2">Status</div>
              {statuses.map((status) => (
                <button
                  key={`status${status}`}
                  type="button"
                  className={`mr-2 inline-flex items-center p-2 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 ${
                    gridFilterModelContainsScreenItem("status", status)
                      ? "text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white"
                      : "text-topo-blue bg-white  dark:bg-gray-800 dark:text-blue-500 hover:bg-blue-800 hover:text-white"
                  }`}
                  onClick={() => handleScreensFilterClick("status", status)}
                >
                  {snakeToTitle(status)}
                </button>
              ))}
              <div className="font-medium mt-4 mb-2">Assigned</div>
              <div>
                {/* Pushes me to front and add null option for unassigned */}
                {meFirstUsers.map((user) => (
                  <button
                    key={`meFirstUsers${user?.id}`}
                    type="button"
                    onClick={() =>
                      handleScreensFilterClick("assigned", user?.id)
                    }
                    className={`mr-2 inline-flex items-center p-2 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 ${
                      gridFilterModelContainsScreenItem("assigned", user?.id)
                        ? "text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white"
                        : "text-topo-blue bg-white dark:bg-gray-800 dark:text-blue-500 hover:bg-blue-800 hover:text-white"
                    }`}
                  >
                    {user?.name !== currentUser?.name
                      ? user.name
                      : `${user.name} (Me)`}
                  </button>
                ))}
              </div>
              <div className="font-medium flex mt-4 mb-4 text-base items-center">
                <HeroIcon
                  icon="SortAscendingIcon"
                  className={"h-5 mr-1"}
                  outline={false}
                />{" "}
                Sorts{" "}
                <div
                  className="ml-2 font-normal text-xs underline self-center hover:cursor-pointer"
                  onClick={() => handleClearSortClick()}
                  onKeyUp={() => handleClearSortClick()}
                >
                  Clear
                </div>
              </div>

              <div className="font-medium  mt-4 mb-2">Sort By</div>
              <div className="flex items-center">
                {customSortableColumns.map((column) => (
                  <button
                    key={`customSortableColumns${column}`}
                    type="button"
                    onClick={() => handleSortClick(column)}
                    className={`mr-2 inline-flex items-center p-2 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 ${
                      gridSortModelContainsItem(column)
                        ? "text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white"
                        : "text-topo-blue bg-white  dark:bg-gray-800 dark:text-blue-500 hover:bg-blue-800 hover:text-white"
                    }`}
                  >
                    <HeroIcon
                      icon={
                        gridSortDirection(column) === "asc"
                          ? "SortAscendingIcon"
                          : "SortDescendingIcon"
                      }
                      className={"h-4 mr-1 text-white"}
                      outline={true}
                    />

                    {
                      columns.find((gridColumn) => gridColumn?.field === column)
                        ?.headerName
                    }
                  </button>
                ))}
              </div>
            </div>
          </Disclosure.Panel>
        </Transition>
        <div className="mb-4 select-none">
          {(filterAndSort?.filtering?.filters?.length > 0 ||
            filterAndSort?.sorting?.length > 0) && (
            <div className="inline-flex items-center text-sm mr-2 p-2 border leading-4 font-medium rounded-md text-topo-blue dark:text-blue-500 bg-white dark:bg-transparent dark:border dark:border-topo-blue">
              Viewing:
            </div>
          )}
          {filterAndSort?.filtering?.filters
            ?.filter((item) =>
              ["study", "status", "assigned"].includes(item?.field),
            )
            .map((item) => (
              <div
                key={`${item.field}-${item.value}`}
                className={
                  "mr-2 inline-flex items-center p-2 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white hover:cursor-default"
                }
              >
                <div>
                  <Tooltip content={"Click to remove filter."}>
                    <button
                      type="button"
                      onClick={() => handleDeleteFilterItem(item)}
                      onKeyUp={() => handleDeleteFilterItem(item)}
                    >
                      <HeroIcon
                        icon="FilterIcon"
                        className={"w-4 mr-1 inline-flex text-white"}
                        outline={true}
                      />
                      {item.field === "assigned"
                        ? displayFilters(item, users)
                        : displayFilters(item)}
                    </button>
                  </Tooltip>
                </div>
              </div>
            ))}
          {filterAndSort?.filtering?.filters
            ?.filter(
              (item) => !["study", "status", "assigned"].includes(item?.field),
            )
            .map((item) => (
              <div
                key={`${item.field}-${item.value}`}
                className={
                  "mr-2 inline-flex items-center p-2 pb-1.5 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white hover:cursor-default"
                }
              >
                <div>
                  <Tooltip content={"Click to remove filter."}>
                    <button
                      type="button"
                      onClick={() => handleDeleteFilterItem(item)}
                      onKeyUp={() => handleDeleteFilterItem(item)}
                    >
                      <HeroIcon
                        icon="FilterIcon"
                        className={"w-4 mr-1 inline-flex text-white"}
                        outline={true}
                      />
                      {displayFilters(item)}
                    </button>
                  </Tooltip>
                </div>
              </div>
            ))}
          {useGridSelector(apiRef, gridSortModelSelector).map(
            (gridSortItem: GridSortItem) => (
              <div
                className={
                  "mr-2 inline-flex items-center p-2 pb-1.5 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 text-white bg-topo-blue hover:bg-blue-900 hover:text-topo-white hover:cursor-default"
                }
                key={gridSortItem.field}
              >
                <div>
                  <Tooltip content={"Click to remove sort."}>
                    <button
                      type="button"
                      onClick={() => handleDeleteSortItem(gridSortItem)}
                      onKeyUp={() => handleDeleteSortItem(gridSortItem)}
                    >
                      {gridSortDirection(gridSortItem.field) !== undefined && (
                        <HeroIcon
                          icon={
                            gridSortDirection(gridSortItem.field) === "asc"
                              ? "SortAscendingIcon"
                              : "SortDescendingIcon"
                          }
                          className={"w-4 mr-1 inline-flex text-white"}
                          outline={true}
                        />
                      )}
                      {columns.find(
                        (gridColumn) =>
                          gridSortItem?.field === gridColumn.field,
                      )?.headerName || gridSortItem?.field}
                    </button>
                  </Tooltip>
                </div>
              </div>
            ),
          )}
          {studyFilterOn &&
            !onlyStudyFilterOn &&
            defaultStudySorts?.map(({ field, direction }) => (
              <div
                key={`customSortableColumns${field}`}
                className="mr-2 inline-flex items-center p-2 pb-1.5 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 text-white bg-topo-blue"
              >
                <Tooltip content={"Default filter, cannot be changed."}>
                  <button type="button">
                    <HeroIcon
                      icon={
                        direction === "asc"
                          ? "SortAscendingIcon"
                          : "SortDescendingIcon"
                      }
                      className={"h-4 mr-1 inline-flex text-white"}
                      outline={true}
                    />
                    {snakeToTitle(field)}
                  </button>
                </Tooltip>
              </div>
            ))}
          {defaultSorts?.map(({ field, direction }) => (
            <div
              key={`customSortableColumns${field}`}
              className="mr-2 inline-flex items-center p-2 pb-1.5 border border-topo-blue shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-800 text-white bg-topo-blue"
            >
              <Tooltip content={"Default filter, cannot be changed."}>
                <button type="button">
                  <HeroIcon
                    icon={
                      direction === "asc"
                        ? "SortAscendingIcon"
                        : "SortDescendingIcon"
                    }
                    className={"h-4 mr-1 inline-flex text-white"}
                    outline={true}
                  />
                  {snakeToTitle(field)}
                </button>
              </Tooltip>
            </div>
          ))}
        </div>
      </Disclosure>
    </div>
  )
}
