import { Card, Tabs } from "flowbite-react"
import { type SetStateAction, useContext, useEffect, useState } from "react"
import { HiCalendar, HiChat, HiPhone, HiUserCircle } from "react-icons/hi"
import { PatientInfo } from "./patient-info"
import { Screen } from "./screen"

import { useQueryClient } from "@tanstack/react-query"
import type { MutatorOptions } from "swr"
import { PatientInfoSidebarContacts } from "../../components/patients/patient-info-sidebar-contacts"
import { SidebarComments } from "../../components/patients/sidebar-comments"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { useShowQuery } from "../../shared/use-rest-query"
import { usePatientContext } from "../patient"
import { PatientAppointments } from "../patient-appointments"
import { PatientVisits } from "../patient-visits"
import { Contacts } from "./contact"
import { Schedule } from "./schedule"

export default function ChartReview({ currentScreenId, refetchBatch }) {
  const { practiceId } = useContext(AuthorizationContext)

  const [screen, setScreen] = useState(null)

  const {
    data: { data: screenData },
    isFetching: isLoadingScreen = true,
  } = useShowQuery("screens", currentScreenId, {
    params: `practice_id=${practiceId}`,
  })

  const queryClient = useQueryClient()
  const mutateScreen = () => {
    queryClient.invalidateQueries({
      queryKey: ["screens", currentScreenId],
    })
    refetchBatch()
  }

  useEffect(() => {
    if (screenData && Object.keys(screenData).length > 0) {
      setScreen({ ...screenData })
    }
  }, [screenData])

  return screen ? (
    <div className="container mx-auto p-4">
      <PatientInfo
        screen={screen}
        mutateScreen={mutateScreen}
        isLoadingScreen={isLoadingScreen}
      />
      <Tabs variant="underline">
        <Tabs.Item active title="Screen" icon={HiUserCircle}>
          {
            <Screen
              screen={screen}
              mutateScreen={mutateScreen}
              isLoadingScreen={isLoadingScreen}
            />
          }
        </Tabs.Item>
        <Tabs.Item title="Comments" icon={HiChat}>
          {screen?.patient_id ? (
            <SidebarComments patientId={screen?.patient_id} />
          ) : null}
        </Tabs.Item>
        <Tabs.Item title="Contact" icon={HiPhone}>
          {screen?.patient_id ? (
            <Contacts
              screen={screen}
              mutateScreen={mutateScreen}
              isLoadingScreen={isLoadingScreen}
            />
          ) : null}
        </Tabs.Item>
        <Tabs.Item title="Schedule" icon={HiCalendar}>
          {screen ? <Schedule screen={screen} /> : null}
        </Tabs.Item>
      </Tabs>
    </div>
  ) : (
    <></>
  )
}
