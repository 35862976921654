import { Select } from "flowbite-react"

function StudySelection({ studyId, setStudyId, batchesByStudyId }) {
  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h2 className="text-xl font-bold">Select Study</h2>

      <Select
        id="study"
        value={studyId}
        onChange={(e) => setStudyId(e.target.value)}
        required
      >
        {batchesByStudyId.map((batch) => (
          <option key={batch.study_id} value={batch.study_id}>
            {batch.study.abbreviation} ({batch.study.name})
          </option>
        ))}
      </Select>
    </div>
  )
}
export default StudySelection
