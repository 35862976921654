import { useState } from "react"
import { HeroIcon } from "../hero-icon"
import { BooleanQuestion } from "../screens/boolean-question"

interface Props {
  question: object | never
  registerResponseField
  criteria: []
}

export function ScreenQuestion(props: Props) {
  const { question, registerResponseField, criteria } = props
  const questionCriteria = criteria?.filter(
    (criterion) => criterion.question_id === question.Question.id,
  )
  if (question === undefined) return <></>

  return (
    <div>
      <div className="flex justify-between items-center py-4">
        {question.Question.question_type === "boolean" && (
          <BooleanQuestion question={question} />
        )}

        {question.Question.question_type === "text" && (
          <div className="flex w-full">
            <div className="w-36 flex items-center">
              <div style={{ width: "34px" }}> </div>
              <HeroIcon
                outline={false}
                icon="PencilAltIcon"
                extraClasses={"w-8 h-8"}
              />
            </div>
            <div className="flex flex-col">
              <div className="text-lg font-semibold text-gray-900 dark:text-gray-200">
                {question.Question.full_text}
              </div>
              <div className="text-base font-normal text-gray-900 dark:text-gray-200">
                {question.Question.short_text}{" "}
              </div>

              <div className="flex-2 pt-2 pb-2">
                <input
                  {...registerResponseField(question.StudyQuestion.id)}
                  type="text"
                  placeholder="Enter their answer here..."
                  className={
                    "w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700"
                  }
                />
              </div>
            </div>
          </div>
        )}

        {question.Question.question_type === "single_option" && (
          <div className="flex">
            <div className="flex mr-4 items-center">
              <div className="">
                <select
                  {...registerResponseField(question.StudyQuestion.id)}
                  className="w-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700"
                >
                  <option />
                  {question.Question.allowed_values?.map((allowed_value) => (
                    <option key={allowed_value}>{allowed_value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex-1">
              <div className="text-md font-semibold text-gray-900 dark:text-gray-200">
                {question.Question.full_text}
              </div>
              <div className="text-base font-normal text-gray-900 dark:text-gray-200">
                {question.Question.short_text}{" "}
              </div>
            </div>
          </div>
        )}
        {question.Question.question_type === "multi_option" && (
          <div className="flex">
            <div className="flex mr-4 items-center">
              <div className="">
                <select
                  multiple
                  {...registerResponseField(question.StudyQuestion.id)}
                  className="w-32 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 dark:bg-gray-700"
                >
                  <option />
                  {question.Question.allowed_values.map((allowed_value) => (
                    <option key={allowed_value}>{allowed_value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="flex-1">
              <div className="text-md font-semibold text-gray-900 dark:text-gray-200">
                {question.Question.full_text}
              </div>
              <div className="text-base font-normal text-gray-900 dark:text-gray-200">
                {question.Question.short_text}{" "}
              </div>
            </div>
          </div>
        )}
        {/* <FormFieldSpinner
          id={question.StudyQuestion.id}
          saving={savingQuestionIds}
        /> */}
      </div>
      <div
        className={`ml-36 ${
          questionCriteria?.filter((criteria) => !!criteria?.term).length > 0 &&
          "mb-4"
        }`}
      >
        {/* Criteria */}
        {questionCriteria
          ?.filter((criteria) => !!criteria?.term)
          ?.map((criterion) => (
            <div className="flex items-center space-x-1" key={criterion.id}>
              {criterion.evidence && (
                <HeroIcon
                  icon={
                    question.StudyQuestion?.exclusion_criteria
                      ? "ExclamationCircleIcon"
                      : "CheckCircleIcon"
                  }
                  className={`h-4 ${
                    question.StudyQuestion?.exclusion_criteria
                      ? "text-red-700"
                      : "text-green-800"
                  }`}
                />
              )}
              {!criterion.evidence && criterion.question_id && (
                <HeroIcon
                  icon="QuestionMarkCircleIcon"
                  className="h-4 text-gray-400"
                />
              )}
              <div
                className={
                  criterion.evidence &&
                  (question.StudyQuestion?.exclusion_criteria
                    ? "text-red-700"
                    : "text-green-800")
                }
              >
                {criterion.term} - {criterion.evidence || "Unknown"}{" "}
                {criterion.evidence_value}{" "}
                {criterion.evidence_date &&
                  `on ${criterion.evidence_date}`}{" "}
              </div>
            </div>
          ))}
      </div>
    </div>
  )
}
