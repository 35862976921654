// TODO: Edit Visit
import { useAuth0 } from "@auth0/auth0-react"
import {
  DataGridPro,
  type GridCellParams,
  type GridColDef,
  type GridRowsProp,
  type MuiEvent,
} from "@mui/x-data-grid-pro"
import { useContext, useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { EditVisitModal } from "../../components/edit-visit-modal"
import { HeroIcon } from "../../components/hero-icon"
import { NewVisitModal } from "../../components/new-visit-modal"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { event } from "../../shared/event"
import { friendlyDateTime } from "../../shared/friendly-dates"
import { useIndex } from "../../shared/use-rest"
import { usePatientContext } from "../patient"

export function Visits({ patientId }) {
  const { practiceId } = useContext(AuthorizationContext)

  const [newVisitModalOpen, setNewVisitModalOpen] = useState(false)
  const [editVisitModalOpen, setEditVisitModalOpen] = useState(false)
  const [currentStudyScreenId, setCurrentStudyScreenId] = useState("")
  const [currentVisitId, setCurrentVisitId] = useState("")

  const { data, isLoading, isError, mutate } = useIndex(
    "visits",
    `patient_id=${patientId}`,
  )

  const {
    data: practiceStudies,
    isLoading: practiceStudiesLoading,
    isError: practiceStudiesError,
    mutate: practiceStudiesMutate,
  } = useIndex("practice_studies")

  const { getAccessTokenSilently } = useAuth0()

  const rows: GridRowsProp = data
  const columnDefaults: Partial<GridColDef> = {
    width: 150,
    headerClassName: "bg-gray-100 text-gray-500",
    display: "flex",
  }
  const columns: GridColDef[] = [
    {
      field: "start_dt",
      headerName: "Visit Start",
      ...columnDefaults,
      type: "dateTime",
      width: 225,
      valueFormatter: (value, row, column, apiRef) => friendlyDateTime(value),
    },
    {
      field: "study",
      headerName: "Study",
      ...columnDefaults,
      valueGetter: (value, row, column, apiRef) => row.study?.abbreviation,
    },
    {
      field: "visit",
      headerName: "Visit",
      ...columnDefaults,
      width: 225,
      valueGetter: (value, row, column, apiRef) =>
        row.practice_study_visit?.study_visit?.visit_name,
    },
    {
      field: "task_list",
      headerName: "Task List",
      filterable: false,
      ...columnDefaults,
      valueGetter: (value, row, column, apiRef) =>
        buildMondayUrl(practiceStudies, row?.study_id),
      renderCell: (params) => renderCrioUrl(params, "Monday.com"),
    },
    {
      field: "crio",
      headerName: "CRIO",
      flex: 1,
      filterable: false,
      ...columnDefaults,
      valueGetter: (value, row, column, apiRef) => row.xref_subject_study_url,
      renderCell: (params) => renderCrioUrl(params, "CRIO"),
    },
  ]

  useEffect(() => {
    event({
      eventName: "PatientVisitsViewStart",
      meta: { patient_id: patientId, screen_id: currentStudyScreenId },
      getAccessTokenSilently,
    })
    return () => {
      event({
        eventName: "PatientVisitsViewEnd",
        meta: { patient_id: patientId, screen_id: currentStudyScreenId },
        getAccessTokenSilently,
      })
    }
  }, [currentStudyScreenId, getAccessTokenSilently, patientId])

  return (
    <>
      {newVisitModalOpen && (
        <NewVisitModal
          patientId={patientId}
          setCurrentVisitId={setCurrentVisitId}
          setOpen={setNewVisitModalOpen}
          refreshData={mutate}
        />
      )}
      {editVisitModalOpen && (
        <EditVisitModal
          visitId={currentVisitId}
          setOpen={setEditVisitModalOpen}
          refreshData={mutate}
        />
      )}
      {isLoading ? (
        <>Loading...</>
      ) : (
        <div className="p-4 rounded-lg border border-gray-200 shadow-md bg-white dark:bg-transparent col-start-2">
          <div className="flex justify-between items-center">
            <h3 className="p-4 mb-4 text-xl font-bold text-gray-900 dark:text-white">
              Visits
            </h3>
            <button
              type="button"
              onClick={() => setNewVisitModalOpen(true)}
              onKeyUp={() => setNewVisitModalOpen(true)}
              className="h-10 text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
            >
              <HeroIcon
                icon="PlusCircleIcon"
                className="fill-white w-4 h-4 mr-2 rotate-90"
              />
              Add Today's Visit
            </button>
          </div>

          <div className="flex">
            <div className="flex-grow  h-1/2" style={{ maxHeight: "h-screen" }}>
              <DataGridPro
                rows={rows}
                columns={columns}
                disableDensitySelector={true}
                onCellDoubleClick={(
                  params: GridCellParams,
                  event: MuiEvent<MouseEvent>,
                ) => {
                  setCurrentVisitId(params?.row?.id)
                  setEditVisitModalOpen(true)
                }}
                initialState={{
                  columns: {
                    columnVisibilityModel: {},
                  },
                  sorting: {
                    sortModel: [{ field: "start_dt", sort: "desc" }],
                  },
                  density: "comfortable", // contact
                }}
                sx={{
                  "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus":
                    {
                      outline: "none",
                    },
                  ".dark & .MuiDataGrid-columnHeader": {
                    backgroundColor: "rgb(55 65 81)",
                    color: "#FFFFFF",
                  },
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

const buildMondayUrl = (practiceStudies, studyId) => {
  const practiceStudy = practiceStudies.find(
    (practiceStudy) => practiceStudy?.study_id === studyId,
  )
  return practiceStudy?.task_board_xref_id
    ? `https://topography-health-team.monday.com/boards/${practiceStudy?.task_board_xref_id}`
    : undefined
}

function renderCrioUrl(params, destination) {
  return (
    <>
      {params.value ? (
        <Link
          target="_blank"
          to={params.value}
          className="text-sm font-medium hover:cursor-pointer w-full flex-col"
        >
          <button
            type="button"
            className="h-10 text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-xs px-2.5 py-1.5 text-center inline-flex items-center"
          >
            {destination}
            <HeroIcon
              icon="ExternalLinkIcon"
              className="fill-white w-4 h-4 ml-2"
            />
          </button>
        </Link>
      ) : (
        <></>
      )}
    </>
  )
}
