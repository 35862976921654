import { PatientVisits } from "../patient-visits"
import { Appointments } from "./appointments"
import { Visits } from "./visits"

export function Schedule({ screen }) {
  const patientId = screen?.patient_id
  return (
    <div className="space-y-4">
      <Appointments patientId={patientId} />
      <Visits patientId={patientId} />
    </div>
  )
}
