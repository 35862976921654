import { useAuth0 } from "@auth0/auth0-react"
import { Button, Tooltip } from "flowbite-react"
import { FaArrowLeft, FaArrowRight, FaCheck, FaTimes } from "react-icons/fa"
import Pluralize from "react-pluralize"
import { Spinner } from "../../components/spinner"
import { useUpdate } from "../../shared/use-rest"

export default function ReviewNavbar({
  batch,
  isLoading,
  setCurrentScreenId,
  currentScreenIndex,
  refetchBatch,
}) {
  const { getAccessTokenSilently } = useAuth0()
  const decrementCurrentScreenIndex = () => {
    setCurrentScreenId(batch[currentScreenIndex - 1].id)
  }

  const incrementCurrentScreenIndex = () => {
    setCurrentScreenId(batch[currentScreenIndex + 1].id)
  }

  const submitScreenReview = async () => {
    await useUpdate(
      "screens",
      batch[currentScreenIndex].id,
      {
        batched: false,
        batched_by_user_id: null,
        batched_dt: null,
      },
      getAccessTokenSilently,
    )
    refetchBatch()
  }

  return (
    <div className="flex items-center justify-between p-4 bg-gray-100 shadow-md">
      <div className="flex-grow text-center w-1/4">
        {currentScreenIndex > 0 && (
          <button
            type="button"
            className="text-xl inline-flex items-center" // Add items-center for vertical alignment
            onClick={() => decrementCurrentScreenIndex()}
          >
            <FaArrowLeft />
            <span className="ml-2">Previous</span>
          </button>
        )}
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="flex mx-4 text-2xl">
          <Pluralize
            singular="screen"
            count={batch?.length ?? 0}
            showCount={true}
          />
          {/* This inserts a space */}
          <span>&nbsp;left in review batch</span>
        </div>
      )}

      <div className="flex-grow text-center w-1/4">
        {batch.length > 0 && (
          <div className="inline-flex space-x-2">
            <Tooltip content="Remove from batch">
              <Button
                onClick={() => submitScreenReview()}
                className="text-xl inline-flex items-center text-red-500  bg-white shadow-md"
              >
                <FaTimes className="size-8" />{" "}
                {/* Use 'FaTimes' for 'x' icon */}
              </Button>
            </Tooltip>

            <Tooltip content="Skip">
              <Button
                className="text-xl inline-flex items-center text-yellow-500  bg-white shadow-md"
                onClick={() => incrementCurrentScreenIndex()}
              >
                <FaArrowRight className="size-8" />
              </Button>
            </Tooltip>
            <Tooltip content="Mark reviewed and go to next">
              <Button
                className="text-xl inline-flex items-center text-green-500  bg-white shadow-md"
                onClick={() => submitScreenReview()}
              >
                <FaCheck className="size-8" />
              </Button>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  )
}
