import { useQueryClient } from "@tanstack/react-query"
import { Flowbite } from "flowbite-react"
import { useContext, useEffect, useState } from "react"
import { isUUID } from "validator"
import { AuthorizationContext } from "../shared/authorization-provider"
import { chartReviewBatchEnabled } from "../shared/feature-flag-provider"
import { useIndexQuery } from "../shared/use-rest-query"
import ChartReview from "./chart-review-batch/chart-review-batch"
import BatchCreation from "./chart-review-batch/chart-review-batch-creator"
import BatchDeletion from "./chart-review-batch/chart-review-clear-batch"
import HistorySidebar from "./chart-review-batch/chart-review-history"
import ReviewNavbar from "./chart-review-batch/chart-review-navbar"
import StudySelection from "./chart-review-batch/chart-review-study-selection"

export function ChartReviewBatch() {
  const [currentScreenId, setCurrentScreenId] = useState()
  const [studyId, setStudyId] = useState(
    localStorage.getItem("chartReviewBatchStudyId") ?? null,
  )
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const {
    data: { data: batchesByStudyId },
    isFetching: isFetchingBatchesByStudyId,
  } = useIndexQuery("batches")

  const {
    data: { data: batch },
    isFetching: isFetchingBatches,
  } = useIndexQuery("batches/study_id", `study_id=${studyId}`)

  const queryClient = useQueryClient()
  const refetchBatch = () => {
    queryClient.invalidateQueries({
      queryKey: ["batches/study_id", `study_id=${studyId}`],
    })
    queryClient.invalidateQueries({
      queryKey: ["batches"],
    })
  }

  const {
    data: { data: studies },
  } = useIndexQuery("practice_studies")

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (studyId && isUUID(studyId)) {
      localStorage.setItem("chartReviewBatchStudyId", studyId)
      refetchBatch()
      setCurrentScreenId(null)
    }
  }, [studyId])

  useEffect(() => {
    const index = batch.findIndex((screen) => screen.id === currentScreenId)
    setCurrentScreenIndex(index === -1 ? 0 : index)
  }, [batch, currentScreenId])

  useEffect(() => {
    if (!currentScreenId) {
      setCurrentScreenId(batch?.[0]?.id)
    }
  }, [batch, currentScreenId])

  useEffect(() => {
    setIsLoading(isFetchingBatchesByStudyId || isFetchingBatches)
  }, [isFetchingBatchesByStudyId, isFetchingBatches])

  const { practiceName } = useContext(AuthorizationContext)
  if (!chartReviewBatchEnabled(practiceName)) {
    return (
      <div className="p-4 font-semibold">
        This feature has not been enabled yet. Please contact an administrator.
        🤙
      </div>
    )
  }

  return (
    <Flowbite>
      <div className="flex h-screen bg-gray-100">
        <div>
          <HistorySidebar
            studyId={studyId}
            setStudyId={setStudyId}
            batchesByStudyId={batchesByStudyId}
            studies={studies}
            currentScreenIndex={currentScreenIndex}
            batch={batch}
            refetchBatch={refetchBatch}
            setCurrentScreenId={setCurrentScreenId}
          />
        </div>
        <div className="flex flex-col flex-1">
          <ReviewNavbar
            batch={batch}
            isLoading={isLoading}
            currentScreenIndex={currentScreenIndex}
            setCurrentScreenId={setCurrentScreenId}
            refetchBatch={refetchBatch}
          />
          {studyId &&
            batchesByStudyId.filter((batch) => batch.study_id === studyId)
              .length > 0 &&
            currentScreenId && (
              <ChartReview
                currentScreenId={currentScreenId}
                refetchBatch={refetchBatch}
              />
            )}
          {(studyId === null ||
            !batchesByStudyId.filter(
              (batch) => batch.study_id === studyId,
            )) && (
            <BatchCreation
              studyId={studyId}
              setStudyId={setStudyId}
              studies={studies}
              refetchBatch={refetchBatch}
            />
          )}
          {studyId === null && batchesByStudyId > 0 && (
            <StudySelection
              studyId={studyId}
              setStudyId={setStudyId}
              batchesByStudyId={batchesByStudyId}
            />
          )}
        </div>
      </div>
    </Flowbite>
  )
}
