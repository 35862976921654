import { useAuth0 } from "@auth0/auth0-react"
import { type Dispatch, type SetStateAction, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import SubmitButton from "../../../components/submit-button"
import Tiptap from "../../../components/tiptap-editor/tiptap-editor"
import { useIndex, useShow, useUpdate } from "../../../shared/use-rest"
import { useKeyPress } from "../../../shared/useKeyPress"

interface Props {
  studyId: string
  setOpen: Dispatch<SetStateAction<boolean>>
  refreshData
  open?: boolean
}

export function StudyEditModal(props: Props) {
  props.open = props.open ? props.open : false
  const { register, handleSubmit, reset, control, setValue } = useForm()

  const { data: study, mutate: mutateStudy } = useShow(
    "admin/studies",
    props.studyId,
  )
  const { data: sponsors } = useIndex("admin/sponsors")
  const { data: indications } = useIndex("admin/indications")
  const { getAccessTokenSilently } = useAuth0()
  const [isLoading, setIsLoading] = useState(false)

  const onKeyPress = (event) => {
    props.setOpen(false)
  }

  const closeModal = () => {
    props.setOpen(false)
  }

  useKeyPress(["Escape"], onKeyPress)

  useEffect(() => {
    // extract only the ids
    const indicationIds = study.indications?.map((indication) => indication.id)
    if (indicationIds) {
      setValue("indication_ids", indicationIds)
    }
  }, [study, setValue])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    reset(study)
  }, [study])

  const onSubmit = (data) => {
    setIsLoading(true)
    const indication_ids = data.indication_ids
    data.indication_ids = undefined
    data.indications = undefined
    data.sponsor = undefined
    useUpdate("admin/studies", props.studyId, data, getAccessTokenSilently)
      .then(() => {
        useUpdate(
          "admin/studies/indications",
          props.studyId,
          {
            indication_ids,
          },
          getAccessTokenSilently,
        )
      })
      .then(
        (response) => {
          props.setOpen(false)
          props?.refreshData()
          reset()
        },
        (error) => {
          // console.dir(error);
        },
      )
    setIsLoading(false)
  }

  const entity = {
    label: "Study",
    key: "study",
  }
  const fields = [
    { key: "name", label: "Name" },
    { key: "nickname", label: "Nickname" },
    { key: "abbreviation", label: "Abbreviation" },
    { key: "description", label: "Description" },
    { key: "protocol_version", label: "Protocol Version" },
    { key: "compound", label: "Compound" },
    { key: "call_script", label: "Call Script" },
    { key: "sponsor_id", label: "Sponsor", options: sponsors },
    { key: "indication_ids", label: "Indications", options: indications },
  ]

  return (
    <>
      <div
        className="fixed pt-10 pb-10 z-50 left-0 right-0 items-center justify-center overflow-x-hidden md:inset-0"
        id="edit-study-modal"
      >
        {study && sponsors && (
          <div className="relative w-full h-full max-w-4xl px-4 md:h-auto mx-auto modal-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <!-- Modal content --> */}
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
                {/* <!-- Modal header --> */}
                <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
                  <h3 className="text-xl font-semibold dark:text-white">
                    Editing {study.name} ({study.abbreviation})
                  </h3>
                  <button
                    type="button"
                    className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                    onClick={() => props.setOpen(false)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title>Edit</title>
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>

                {/* <!-- Modal body --> */}
                <div className="p-6 space-y-6">
                  <div className="grid grid-cols-6 gap-6">
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="name"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Name
                      </label>
                      <input
                        {...register("name")}
                        type="text"
                        name="name"
                        id="name"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="NN"
                        required
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="nickname"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Nickname
                      </label>
                      <input
                        {...register("nickname")}
                        type="text"
                        name="nickname"
                        id="nickname"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="Green"
                        required
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="abbreviation"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Abbreviation
                      </label>
                      <input
                        {...register("abbreviation")}
                        type="text"
                        name="abbreviation"
                        id="abbreviation"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="Green"
                        required
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="description"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Description
                      </label>
                      <input
                        {...register("description")}
                        type="text"
                        name="description"
                        id="description"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                        required
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="location"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Protocol Version
                      </label>
                      <input
                        {...register("protocol_version")}
                        type="text"
                        name="protocol_version"
                        id="protocol_version"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="indications"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Indications
                      </label>
                      <select
                        {...register("indication_ids")}
                        name="indication_ids"
                        id="indication_ids"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                        multiple
                      >
                        <option disabled />
                        {indications?.map((indication) => (
                          <option
                            key={indication.id}
                            value={indication.id}
                            selected={study.indications?.includes(
                              indication.id,
                            )}
                          >
                            {indication.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="call_script"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Call Script
                      </label>
                      <textarea
                        {...register("call_script")}
                        name="call_script"
                        id="call_script"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="objectives"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Objectives
                      </label>
                      <input
                        {...register("objectives")}
                        type="text"
                        name="objectives"
                        id="objectives"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="study_type"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Study Type
                      </label>
                      <select
                        {...register("study_type")}
                        name="study_type"
                        id="study_type"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                        required
                      >
                        <option />
                        <option>Interventional</option>
                        <option>Biomarker</option>
                        <option>Registry</option>
                        <option>Vaccine</option>
                      </select>
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="sponsor_id"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Sponsor
                      </label>
                      <select
                        {...register("sponsor_id")}
                        defaultValue={
                          sponsors.find(
                            (sponsor) => sponsor.id === study.sponsor_id,
                          )?.name
                        }
                        name="sponsor_id"
                        id="sponsor_id"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                        required
                      >
                        <option />
                        {sponsors?.map((sponsor) => (
                          <option key={sponsor.id} value={sponsor.id}>
                            {sponsor.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="provider_summary"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Trial Summary
                      </label>
                      <Tiptap
                        control={control}
                        fieldName="provider_summary"
                        defaultValue={study.provider_summary}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="ie_criteria"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Key Inclusion/Exclusion Criteria
                      </label>
                      <Tiptap
                        control={control}
                        fieldName="ie_criteria"
                        defaultValue={study.ie_criteria}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="how_you_can_help"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Patient Pitch
                      </label>
                      <Tiptap
                        control={control}
                        fieldName="how_you_can_help"
                        defaultValue={study.how_you_can_help}
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="compound"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Compound/Asset
                      </label>
                      <input
                        {...register("compound")}
                        type="text"
                        name="compound"
                        id="compound"
                        className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        // placeholder="example@company.com"
                      />
                    </div>
                    <div className="col-span-6 sm:col-span-6">
                      <label
                        htmlFor="key_stats"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Study Specifics
                      </label>
                      <Tiptap
                        control={control}
                        fieldName="key_stats"
                        defaultValue={study.key_stats}
                      />
                    </div>
                    <div className="hidden col-span-6 sm:col-span-6">
                      <label
                        htmlFor="criteria_summary"
                        className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Criteria Summary
                      </label>
                      <Tiptap
                        control={control}
                        fieldName="criteria_summary"
                        defaultValue={study.criteria_summary}
                      />
                    </div>
                  </div>
                  {/* <!-- Modal footer --> */}
                  <div className="items-center pt-4 border-t border-gray-200 rounded-b dark:border-gray-700">
                    {isLoading ? (
                      <SubmitButton />
                    ) : (
                      <button
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="submit"
                      >
                        Save
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
      <div
        onKeyUp={() => closeModal()}
        onClick={() => closeModal()}
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
