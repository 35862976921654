import { useAuth0 } from "@auth0/auth0-react"
import LinearProgress from "@mui/material/LinearProgress"
import {
  DataGridPro,
  type GridCallbackDetails,
  type GridCellParams,
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridComparatorFn,
  type GridEventListener,
  type GridFilterModel,
  type GridRowHeightParams,
  type GridSortModel,
  type GridValueGetterParams,
  type MuiEvent,
  getGridStringOperators,
  gridFilterModelSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid-pro"
import { useQueryClient } from "@tanstack/react-query"
import {
  type MouseEvent,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import resolveConfig from "tailwindcss/resolveConfig"
import type { v4 as uuidv4 } from "uuid"
import isUUID from "validator/lib/isUUID"
import tailwindConfig from "../../tailwind.config.js"
import { BulkAssignModal } from "../components/bulk-assign-modal.js"
import { HeroIcon } from "../components/hero-icon.js"
import { PatientQuickAdd } from "../components/patients/patient-quick-add.js"
import { StudyScreenStatusChip } from "../components/patients/study-screen-status-chip.js"
import { isAdmin } from "../components/protected-admin.js"
import { AuthorizationContext } from "../shared/authorization-provider.js"
import {
  dateTimeISO,
  dateTimeISOWithoutTimezone,
  friendlyDateTime,
} from "../shared/friendly-dates.js"
import { safelyParseJSON } from "../shared/json-utils.js"
import { snakeToTitle } from "../shared/text-utils.js"
import { useIndexQuery } from "../shared/use-rest-query.js"
import {
  useCreate,
  useGet,
  useGetIndexInfinite,
  useShow,
} from "../shared/use-rest.js"
import { ChartReviewDirectoryScreens } from "./chart-review-directory/chart-review-directory-screens.js"
import { ChartReviewDirectoryToolbar } from "./chart-review-directory/chart-review-directory-toolbar.js"
import { ModalType } from "./patient.js"
import { BookmarkModal } from "./patients/bookmark-modal.js"

const fullConfig = resolveConfig(tailwindConfig)
const DEFAULT_FILTER_AND_SORT = (practiceId) => {
  return {
    sorting: [],
    filtering: { filters: [], link_operator: "and" },
    search: "",
    practice_id: practiceId,
  }
}

const defaultContext = {
  currentUser: {},
  isLoading: true,
  authorizedPractices: [],
  practiceId: "",
  practiceName: "",
  changePractice: (id) => {},
}

export const ChartReviewDirectoryContext = createContext(defaultContext)

export function ChartReviewDirectory() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { practiceId, changePractice, authorizedPractices } =
    useContext(AuthorizationContext)
  const limit =
    searchParams.get("limit") || process.env.PATIENT_DIRECTORY_LIMIT || 5000
  const pageSize = 50
  const [bookmarkId, setBookmarkId] = useState(
    searchParams.get("bookmarkId")
      ? searchParams.get("bookmarkId")
      : localStorage.getItem("bookmarkId"),
  )
  const [initialState, setInitialState] = useState(true)
  const [updateFilterAndSort, setUpdateFilterAndSort] = useState(false)
  const [bookmarkPracticeId, setBookmarkPracticeId] = useState(undefined)
  const [filterAndSort, setFilterAndSort] = useState(undefined)
  const [search, setSearch] = useState("")
  const defaultWidth = 150
  const apiRef = useGridApiRef()
  const { getAccessTokenSilently } = useAuth0()
  const { currentUser, isLoading: isLoadingCurrentUser } =
    useContext(AuthorizationContext)
  const {
    data: patientsData,
    isValidating: isValidatingPatients,
    error: isErrorPatients,
    mutate: mutatePatients,
    size: pagePatients,
    setSize: setPagePatients,
  } = useGetIndexInfinite(
    bookmarkId && practiceId && `patients_filter_and_sort/${bookmarkId}`,
    `practice_id=${practiceId}`,
    { pageSize: pageSize },
  )

  const { data: patientQueryCount } = useShow(
    bookmarkId && practiceId && "patients_filter_and_sort_count",
    bookmarkId,
    `practice_id=${practiceId}`,
  )

  useEffect(() => {
    console.dir("isErrorPatients")
    console.dir(isErrorPatients)
    // if (isErrorPatients) {
    //   localStorage.removeItem("bookmarkId")
    // }
    return () => {}
  }, [isErrorPatients])

  const [patients, setPatients] = useState([])
  const [selection, setSelection] = useState(false)
  const toggleSelection = () => setSelection(!selection)
  const selectionEnabled = () => isAdmin(currentUser)
  const [modalOpen, setModalOpen] = useState<ModalType>()

  useEffect(() => {
    if (!patientsData) return

    const newPatients = patientsData.flatMap((data) => data.data)
    setPatients([...newPatients])
    return () => setPatients([])
  }, [patientsData])

  const handleScroll = () => {
    if (patients.length > 0) {
      setPagePatients(pagePatients + 1)
    }
  }
  const isLoadingPatients = () => patients.length === 0

  const columnDefaults: Partial<GridColDef> = {
    width: defaultWidth,
    getApplyQuickFilterFn: undefined,
    display: "flex",
  }

  const columns: GridColDef[] = [
    {
      ...columnDefaults,
      field: "full_name",
      headerName: "Name",
      sortable: false,
      width: 200,
      valueGetter: valueGetterFullName,
    },
    {
      ...columnDefaults,
      field: "mrn",
      headerName: "MRN",
      width: defaultWidth,
    },
    {
      ...columnDefaults,
      field: "active_screen_count",
      headerName: "Active Screen Count",
      width: 200,
      sortable: false,
      valueGetter: (value, row, column, apiRef) => row?.screens?.length ?? 0,
    },
    {
      ...columnDefaults,
      field: "tier",
      headerName: "Min Tier",
      width: 220,
      sortable: false,
      valueGetter: (value, row, column, apiRef) => {
        const reducer = (min, currentValue) => {
          return min < currentValue?.tier ? min : currentValue?.tier
        }

        return row?.screens?.reduce(reducer, null)
      },
    },
    {
      ...columnDefaults,
      field: "age",
      headerName: "Age",
      width: 75,
      getApplyQuickFilterFn: undefined,
      sortable: false,
      valueGetter: (value, row, column, apiRef) => {
        const dob = new Date(row.dob)
        const diffMs = Date.now() - dob.getTime()
        const ageDt = new Date(diffMs)

        return Math.abs(ageDt.getUTCFullYear() - 1970)
      },
    },
    {
      ...columnDefaults,
      field: "dob",
      headerName: "DOB",
      width: defaultWidth,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "referring_provider",
      headerName: "Provider",
      width: defaultWidth,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "phone",
      headerName: "Phone Number",
      width: defaultWidth,
      sortable: false,
      renderCell: (params) => {
        const result = params?.row?.can_contact ? (
          <a href={`tel:${params?.row?.preferred_phone || params?.row?.phone}`}>
            {params?.row?.preferred_phone || params?.row?.phone}
          </a>
        ) : (
          <span className="bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-md">
            DO NOT CONTACT
          </span>
        )

        return result
      },
    },
    {
      ...columnDefaults,
      field: "contact_count",
      headerName: "Contacts",
      width: defaultWidth,
      valueGetter: valueGetterContacts,
    },
    {
      ...columnDefaults,
      field: "contact_count_phone",
      headerName: "Phone Contacts",
      width: defaultWidth,
      valueGetter: valueGetterContactsPhone,
    },
    {
      ...columnDefaults,
      field: "preferred_language",
      headerName: "Preferred Language",
      width: defaultWidth,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "preferred_contact_time",
      headerName: "Preferred Contact Time",
      width: 180,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "source",
      headerName: "Source",
      valueGetter: (params) =>
        params?.value ? snakeToTitle(params?.value) : null,
      width: 200,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "created_at",
      type: "dateTime",
      headerName: "Created",
      width: 220,
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value, row, column, apiRef) => dateTimeISO(value),
    },
    {
      ...columnDefaults,
      field: "next_appointment",
      type: "dateTime",
      headerName: "Next Appointment",
      width: 220,
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value, row, column, apiRef) => dateTimeISO(value),
    },
    {
      ...columnDefaults,
      field: "",
      type: "actions",
      headerName: "", // dummy column to flex: 1
      width: 150,
      renderCell: () => <></>,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
  ]
  const customSortableColumns = ["created_at", "dob"]
  const [quickAddOpen, setQuickAddOpen] = useState(
    searchParams.get("quick_add") === "open",
  )
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [disableColumnFilter, setDisableColumnFilter] = useState(false)
  const navigate = useNavigate()

  const getRowHeight = useCallback(
    ({ model, densityFactor }: GridRowHeightParams) => {
      // Leaving this here as a reminder: Don't fight the framework :)
      // return model.screens.length * 20 * densityFactor + 20
      return "auto"
    },
    [],
  )

  // Init Columns from Localstorage
  useEffect(() => {
    for (const key of searchParams.keys()) {
      if (key.includes("filter_") || key.includes("sort_")) {
        searchParams.delete(key)
      }
    }

    apiRef.current.subscribeEvent("filterModelChange", handleFilterModelChange)
    apiRef.current.subscribeEvent("sortModelChange", handleSortModelChange)
    apiRef.current.subscribeEvent(
      "columnVisibilityModelChange",
      handleColumnVisibilityChange,
    )

    const newColumnVisibilityModelRaw = localStorage.getItem(
      "columnVisibilityModel",
    )
    if (newColumnVisibilityModelRaw !== null) {
      const newColumnVisibilityModel = JSON.parse(newColumnVisibilityModelRaw)
      apiRef.current.setColumnVisibilityModel(newColumnVisibilityModel)
    }
  }, [
    searchParams,
    apiRef.current.subscribeEvent,
    apiRef.current.setColumnVisibilityModel,
  ])

  // Update Columns when they change
  const handleColumnVisibilityChange: GridEventListener<
    "columnVisibilityModelChange"
  > = (
    gridVisibilityModel: GridColumnVisibilityModel,
    _event, // MuiEvent<{}>
    _details, // GridCallbackDetails
  ) =>
    localStorage.setItem(
      "columnVisibilityModel",
      JSON.stringify(gridVisibilityModel),
    )

  useEffect(() => {
    if (!filterAndSort && bookmarkId && practiceId) {
      ;(async () => {
        try {
          const { data, status } = await useGet(
            "filter_and_sort_bookmark",
            `${bookmarkId}`,
            getAccessTokenSilently,
          )
          const content = data?.data?.content
            ? JSON.parse(data?.data?.content)
            : undefined
          if (
            content.practice_id === practiceId ||
            content.practice_id === undefined
          ) {
            setFilterAndSort(content ?? content)
            setSearch(content?.search ?? "")
            content.search = undefined
          } else {
            setBookmarkPracticeId(content.practice_id)
            setModalOpen(ModalType.Bookmark)
          }
        } catch (error) {
          console.dir(error)
          setFilterAndSort(DEFAULT_FILTER_AND_SORT)
          return
        }
      })()
    }
  }, [practiceId, bookmarkId, filterAndSort, getAccessTokenSilently])

  useEffect(() => {
    if (
      bookmarkId &&
      searchParams.get("bookmarkId") !== bookmarkId &&
      isUUID(bookmarkId)
    ) {
      searchParams.set("bookmarkId", bookmarkId)
      setSearchParams(searchParams)
    }
  }, [bookmarkId, searchParams, setSearchParams])

  useEffect(() => {
    if (!practiceId) return
    if (bookmarkId && isUUID(bookmarkId)) {
      localStorage.setItem("bookmarkId", bookmarkId)
    } else {
      ;(async () => {
        console.dir("useEffectPracticeID")
        console.dir(practiceId)
        const response = await useCreate(
          "filter_and_sort_bookmark",
          {
            filter_and_sort: DEFAULT_FILTER_AND_SORT(practiceId),
          },
          getAccessTokenSilently,
        )
        setBookmarkId(response?.data?.data?.id)
      })()
    }
  }, [bookmarkId, getAccessTokenSilently, practiceId])

  useEffect(() => {
    if (!filterAndSort || !practiceId) {
      return
    }

    if (initialState) {
      const newGridFilterLinkOperator =
        filterAndSort?.filtering?.linkOperator || "and"
      const newGridSortModel: GridSortModel = filterAndSort?.sorting || []
      const newGridFilterModel: GridFilterModel = {
        items: filterAndSort?.filtering?.filters.map(
          ({ field, operator_value, value }) => ({
            id: field,
            field: field,
            operator: operator_value,
            value,
          }),
        ),
      }

      apiRef.current.setFilterModel(newGridFilterModel, "upsertFilterItems")
      apiRef.current.setSortModel(newGridSortModel)
      apiRef.current.setFilterLogicOperator(newGridFilterLinkOperator)
      setInitialState(false)
    } else {
      ;(async () => {
        const data = {
          filter_and_sort: {
            ...filterAndSort,
            search,
            practice_id: practiceId,
          },
        }
        const response = await useCreate(
          "filter_and_sort_bookmark",
          data,
          getAccessTokenSilently,
        )
        setBookmarkId(response?.data?.data?.id)
      })()
    }
  }, [
    filterAndSort,
    search,
    apiRef.current.setFilterModel,
    apiRef.current.setSortModel,
    apiRef.current.setFilterLogicOperator,
    practiceId,
    initialState,
    getAccessTokenSilently,
  ])

  const handleSortModelChange = (gridSortModel) => {
    setFilterAndSort((filterAndSort) => ({
      filtering: filterAndSort?.filtering ?? {
        filters: [],
        link_operator: "and",
      },
      sorting: gridSortModel,
    }))
  }

  const handleFilterModelChange = (gridFilterModel, details) => {
    if (details.reason === undefined || filterAndSort === undefined) return
    const filters = gridFilterModel.items?.map(({ field, operator, value }) => {
      if (field === "screens") field = "study"

      return {
        id: field,
        field: field,
        operator_value: operator,
        value,
      }
    })

    const selections = ((filterAndSort) => {
      return (
        filterAndSort?.filtering?.filters?.filter(
          (filterToCheck) =>
            ["study", "status", "assigned"].includes(filterToCheck?.field) &&
            !filters.find((filter) => filter.field === filterToCheck?.field),
        ) || []
      )
    })(filterAndSort)

    const filtering = {
      link_operator: gridFilterModel?.logicOperator || "and",
      filters: selections ? filters.concat(selections) : filters,
    }
    setFilterAndSort((filterAndSort) => ({
      sorting: filterAndSort?.sorting ?? [],
      filtering,
    }))
  }

  const handleColumnVisibilityModelChange = (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails<unknown>,
  ) => {
    setColumnVisibilityModel(model)
    localStorage.setItem("columnVisibilityModel", JSON.stringify(model))
  }

  const handleSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    if (newRowSelectionModel?.length > 0) {
      setModalOpen(ModalType.BulkAssign)
    } else if (newRowSelectionModel?.length === 0) {
      setModalOpen(null)
    }
  }

  const handleAssign = (newAssignedToUserId: uuidv4) => {
    const payload = { assigned_to_user_id: newAssignedToUserId }
    // TODO: figure out how to get screen_ids from directory, only if it's filtered by study
    return useCreate(
      "bulk_requests",
      { model: "Screen", payload: payload, entities: [] },
      getAccessTokenSilently,
    )
  }

  const handleArchive = async () => {
    const archiveResponse = await useCreate(
      "bulk_patient_deletion_requests",
      { patients: rowSelectionModel },
      getAccessTokenSilently,
    )
    mutatePatients()
    return archiveResponse
  }

  const columnVisibilityModelInitial = () => {
    const columnVisibilityModelDefault = {
      // Hide these columns by default, the other columns will remain visible
      study: false,
      status: false,
      source: false,
      dob: false,
      referring_provider: false,
      phone: false,
      can_contact: false,
      next_appointment_type: false,
      last_appointment_type: false,
      contact_count_phone: false,
      preferred_language: false,
      preferred_contact_time: false,
      updated_at: false,
      created_at: false,
    }
    return localStorage.getItem("columnVisibilityModel") !== null
      ? safelyParseJSON(localStorage.getItem("columnVisibilityModel"))
      : columnVisibilityModelDefault
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columnVisibilityModelInitial(),
  )

  return (
    <>
      {isErrorPatients ? "Failed to load patients. Is your VPN connected?" : ""}
      <PatientQuickAdd
        open={quickAddOpen}
        setOpen={setQuickAddOpen}
        refreshData={mutatePatients}
      />
      {modalOpen === ModalType.BulkAssign && (
        <BulkAssignModal
          setOpen={setModalOpen}
          handleAssign={handleAssign}
          handleArchive={handleArchive}
        />
      )}
      {modalOpen === ModalType.Bookmark && (
        <BookmarkModal
          setOpen={setModalOpen}
          setDefaultFilterAndSort={() =>
            setFilterAndSort(DEFAULT_FILTER_AND_SORT)
          }
          changePractice={() => changePractice(bookmarkPracticeId)}
        />
      )}
      <div className="flex h-full py-5 bg-gray-100 text-black dark:bg-gray-800 dark:text-gray-100">
        <div className="flex-grow ml-3">
          <DataGridPro
            apiRef={apiRef}
            rows={patients}
            rowCount={patientQueryCount.count}
            columns={columns}
            checkboxSelection={selectionEnabled()}
            onRowSelectionModelChange={handleSelectionModelChange}
            disableDensitySelector={true}
            disableColumnFilter={false}
            getRowHeight={getRowHeight}
            initialState={{
              density: "comfortable", // contact
            }}
            filterMode="server"
            sortingMode="server"
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            loading={isValidatingPatients}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onRowsScrollEnd={handleScroll}
            localeText={{ toolbarColumns: "Details" }}
            getDetailPanelHeight={({ row }) => row?.screens?.length * 180 || 10}
            getDetailPanelContent={({ row }) => (
              <ChartReviewDirectoryScreens screens={row?.screens} />
            )}
            onCellDoubleClick={(
              params: GridCellParams,
              event: MuiEvent<MouseEvent>,
            ) => {
              event.defaultMuiPrevented = true
              const patientPath = `/patients/${params.row.id}`
              const screens = currentlyFilteredScreens(
                params?.row?.screens,
                filterAndSort,
              )
              if (screens.length === 0 || screens.length > 1) {
                navigate(`${patientPath}/screens`)
                return
              }
              navigate(
                `${patientPath}/screens/${params?.row?.screens?.[0]?.id}`,
              )
            }}
            sx={{
              border: "none",
              height: "85%",
              width: "99%",
              "& .MuiDataGrid-columnHeaderTitle": {
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "12pt",
                color: "black",
              },
              ".dark & .MuiDataGrid-columnHeaderTitle": {
                color: fullConfig.theme.colors.gray[200],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "white",
                borderBottom: "none",
              },
              ".dark & .MuiDataGrid-columnHeaders": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-columnHeader": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-columnHeader--moving": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-columnHeader--moving": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-toolbarContainer": {
                "& .MuiButton-root": {
                  color: fullConfig.theme.colors["topo-blue"],
                },
              },
              "& .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[100],
              },
              "&": {
                display: "flex-grow",
                height: "100%",
                justifyContent: "flex-end",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[100],
              },
              ".dark & .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[600],
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-virtualScroller": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "white",
                borderBottomLeftRadius: ".5rem",
                borderBottomRightRadius: ".5rem",
              },
              ".dark & .MuiDataGrid-footerContainer": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGridPro-toolbarContainer": {
                justifyContent: "flex-end",
              },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "4px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-filterFormValueInput": {
                backgroundColor: "red",
              },
              "& .MuiDataGridPro-cell": {
                height: "100% !important", // Make button full height
                width: "100%", // Make button full width
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
              "& .MuiDataGrid-detailPanelToggleCell": {
                height: "100% !important", // Make button full height
                width: "100%", // Make button full width
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              },
            }}
            slots={{
              toolbar: ChartReviewDirectoryToolbar,
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              filterPanel: {
                sx: {
                  minWidth: "50vw",
                },
                filterFormProps: {
                  valueInputProps: {
                    sx: {
                      width: "inherit",
                    },
                  },
                },
              },
              toolbar: {
                currentUser,
                setQuickAddOpen,
                navigate,
                filtersOpen,
                setFiltersOpen,
                customSortableColumns,
                columns,
                disableColumnFilter,
                setDisableColumnFilter,
                filterAndSort,
                setFilterAndSort,
                search,
                csvOptions: { disableToolbarButton: !isAdmin(currentUser) },
                printOptions: { disableToolbarButton: true },
                setSearch,
                isValidatingPatients,
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

const currentlyFilteredScreens = (screens, filterAndSort) => {
  const screenFilterValue = filterAndSort?.filtering?.filters?.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  return sortScreens(screens)?.filter(
    (screen) =>
      !screenFilterValue || screen?.study?.abbreviation === screenFilterValue,
  )
}

const sortScreens = (screens) =>
  screens
    .slice()
    .sort(
      (v1, v2) =>
        new Date(v2.updated_at).getTime() - new Date(v1.updated_at).getTime(),
    )
const valueGetterContacts = (value, row, column, apiRef) => {
  return (
    row?.contact_count &&
    Object.values(row?.contact_count)?.reduce(
      (totalContacts: number, currentContactValue: number) =>
        totalContacts + currentContactValue,
      0,
    )
  )
}

const valueGetterContactsPhone = (value, row, column, apiRef) =>
  row.contact_count?.["Phone Call"]
const valueGetterFullName = (value, row, column, apiRef) => {
  const suffix = row.suffix?.length > 0 ? `, ${row.suffix}` : ""
  return `${row.given_name} ${row.family_name}${suffix}`
}

const singleStudyView = (filters) =>
  !!filters?.find((item) => item.field === "study")?.value

export const valueGetterScreenValue = (
  value,
  row,
  column,
  apiRef,
  filterAndSort,
  screenField: string,
) => {
  if (!singleStudyView(filterAndSort?.filtering?.filters))
    return "Select a Study"
  const screenFilterValue = filterAndSort?.filtering?.filters?.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  const currentlyFilteredScreens = sortScreens(row.screens)?.filter(
    (screen) =>
      !screenFilterValue || screen?.study?.abbreviation === screenFilterValue,
  )
  return currentlyFilteredScreens?.[0]?.[screenField]
}
