import { useAuth0 } from "@auth0/auth0-react"
import { TextField } from "@mui/material"
import { Rating } from "@smastrom/react-rating"
import "@smastrom/react-rating/dist/style.css"
import { useQueryClient } from "@tanstack/react-query"
import { upperCaseFirst } from "change-case-all"
import { Alert, Tooltip } from "flowbite-react"
import { createContext, useContext, useEffect, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-hot-toast"
import { HiInformationCircle } from "react-icons/hi"
import { Link, useBlocker, useNavigate, useParams } from "react-router-dom"
import { ConfirmDisqualificationModal } from "../components/confirm-disqualification-modal"
import { HeroIcon } from "../components/hero-icon"
import LoadingScreen from "../components/loading-screen"
import { TooltipCrioId } from "../components/patient-screen/tooltip-crio-id"
import { ScreenQualificationCriteria } from "../components/patients/screen-qualification-criteria"
import { ScreenQuestion } from "../components/patients/screen-question"
import { Screening } from "../components/patients/screening"
import {
  isAdmin,
  managerOrQaUser,
  managerOrUser,
} from "../components/protected-admin"
import { SaveSpinner } from "../components/save-spinner"
import { SelectCriterionModal } from "../components/select-criteria-modal"
import { AuthorizationContext } from "../shared/authorization-provider"
import { disqualificationReasons } from "../shared/disqualification-reasons"
import { event } from "../shared/event"
import {
  currentBrowserTimezone,
  dateTimeISOWithoutTimezone,
  friendlyRelative,
  friendlyRelativeWithoutTime,
  friendlyTime,
  parseLocalTimeToUTC,
} from "../shared/friendly-dates"
import {
  nextAppointmentISO,
  nextAppointmentLocalTime,
  nextAppointmentSlashFormat,
} from "../shared/next-appointment"
import { statusOptions } from "../shared/statuses"
import { snakeToTitle, toSnakeCase } from "../shared/text-utils"
import useDebounce from "../shared/use-debounce-hooks"
import { createUrl, useCreate, useIndex, useUpdate } from "../shared/use-rest"
import { useIndexQuery, useShowQuery } from "../shared/use-rest-query"
import { usePatientContext } from "./patient"

export const ScreenContext = createContext({
  submitQuestionResponse: (studyQuestionId: string, responseValue: string) =>
    Promise<void>,
})

export function PatientScreen() {
  const { patientId, screenId } = useParams()
  const {
    currentUser,
    isLoading: isLoadingCurrentUser,
    practiceId,
  } = useContext(AuthorizationContext)
  const [currentScreen, setCurrentScreen] = useState({})
  const screenIdRef = useRef(screenId)
  if (!screenIdRef.current) return <></>
  const { getAccessTokenSilently } = useAuth0()
  const [keepDirtyScreen, setKeepDirtyScreen] = useState(false)
  const queryClient = useQueryClient()
  const [screenStart, setScreenStart] = useState(Date.now())
  const [screenStartStatus, setScreenStartStatus] = useState()
  const [confirmDqBlocking, setConfirmDqBlocking] = useState(false)
  const [resolvePromise, setResolvePromise] = useState(() => () => {})
  const {
    patient,
    setCurrentStudyScreenId,
    setCurrentStudyScreenName,
    setContactModalOpen,
    setScheduleInESourceModalOpen,
    setIneligibleModalOpen,
    ineligibleModalOpen,
    setContactDispositionOpen,
  } = usePatientContext()

  const {
    data: { data: screen },
    isLoading: isLoadingScreen,
    refetch: mutateScreen,
  } = useShowQuery("screens", screenIdRef.current, {
    params: `practice_id=${practiceId}`,
  })

  const {
    data: { data: practice },
  } = useShowQuery("practices", practiceId)

  const {
    data: { data: practiceStudies },
  } = useIndexQuery("practice_studies")

  if (isLoadingScreen) return <></>

  const { data: criteria, isLoading: isLoadingCriteria } = useIndex(
    "screen_qualification_criteria",
    `screen_id=${screenIdRef.current}&limit=200`,
  )

  // get the screen questions and their responses
  const {
    data: { data: questions },
    isFetching: isFetchingQuestions,
  } = useIndexQuery(
    "study_question_responses",
    `screen_id=${screenIdRef.current}`,
  )

  const mutateStudyQuestionResponses = () => {
    queryClient.invalidateQueries({
      queryKey: [
        "study_question_responses",
        `screen_id=${screenIdRef.current}`,
      ],
    })
  }

  const submitQuestionResponse = async (studyQuestionId, responseValue) => {
    const study_question_response = {
      study_question_id: studyQuestionId,
      screen_id: screenIdRef.current,
      patient_id: patientId,
      response: {
        value: responseValue,
      },
    }
    await useCreate(
      "study_question_responses",
      study_question_response,
      getAccessTokenSilently,
    )
    // todo: optimistic mutate (provide the new value to the array)
    mutateStudyQuestionResponses()
    toast.success("Saved")
  }

  const [savingQuestionIds, setSavingQuestionIds] = useState([])

  const [savingMeta, setSavingMeta] = useState(false)

  const { data: usersData, isLoading: isLoadingUsers } = useIndex("users")
  const [users, setUsers] = useState([])

  useEffect(() => {
    if (isLoadingUsers) return
    setUsers(usersData.filter((user) => managerOrUser(user)))
    return () => {}
  }, [usersData, isLoadingUsers])

  // set up the response form
  const {
    register: registerResponseField,
    handleSubmit: handleSubmitResponses,
    watch: watchResponses,
    reset: resetResponses,
    formState: { dirtyFields },
  } = useForm({ mode: "onChange" })

  const watchedField = watchResponses()

  // Helper function that gets loaded into a listener on component mount
  const handleVisibilityChange = async (event) => {
    const eventName =
      document.visibilityState === "hidden" ? "ScreenStop" : "ScreenStart"
    const accessToken = await getAccessTokenSilently()
    const data = {
      name: eventName,
      meta: {
        practice_id: practiceId,
        patient_id: patientId,
        screen_id: screenIdRef.current,
      },
    }

    fetch(createUrl("events"), {
      keepalive: true,
      method: "POST",
      headers: {
        "content-type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(data),
    })
  }
  // onMount function called from useEffect hook
  const onMount = () => {
    event({
      eventName: "ScreenStart",
      meta: { patient_id: patientId, screen_id: screenIdRef.current },
      getAccessTokenSilently,
    })
    const touchAndMutateScreen = async () => {
      // Send an update which also triggers updated_by_user_id
      await useUpdate(
        "screens",
        screenIdRef.current,
        {},
        getAccessTokenSilently,
      )
      mutateScreen()
    }
    if (currentUser && !isAdmin(currentUser)) {
      touchAndMutateScreen()
    }

    window.addEventListener("visibilitychange", handleVisibilityChange)
  }
  // biome-ignore lint/correctness/useExhaustiveDependencies: Run once, on mount
  useEffect(() => {
    onMount()

    return () => {
      event({
        eventName: "ScreenStop",
        meta: { patient_id: patientId, screen_id: screenIdRef.current },
        getAccessTokenSilently,
      })
      window.removeEventListener("visibilitychange", handleVisibilityChange)
    }
  }, []) //

  const assignAndMutateScreen = async () => {
    const previousUserId = screen.assigned_to_user_id
    if (previousUserId === currentUser?.id) return
    if (isAdmin(currentUser)) return
    await useUpdate(
      "screens",
      screenIdRef.current,
      { assigned_to_user_id: currentUser?.id },
      getAccessTokenSilently,
    )
    resetScreen({ assigned_to_user_id: currentUser?.id })
    toast("✅ Self Assigned")
    mutateScreen()
  }
  // When currentUser is ready, assign screen to currentUser and TOAST
  // biome-ignore lint/correctness/useExhaustiveDependencies: tbh i don't know how to specify the function as a dep
  useEffect(() => {
    if (isLoadingCurrentUser || isLoadingScreen) return
    assignAndMutateScreen()
  }, [isLoadingCurrentUser, isLoadingScreen])

  const {
    register: registerScreen,
    handleSubmit: handleSubmitScreen,
    watch: watchScreen,
    reset: resetScreen,
    control: controlScreen,
    getValues: formValues,
    setValue: setFormValue,
  } = useForm()

  const handleResetScreen = () => {
    resetScreen(
      {
        id: screenIdRef.current,
        status: screen?.status,
        follow_up_dt: screen?.follow_up_dt,
        follow_up_reason: screen?.follow_up_reason,
        esource_xref_id: screen?.esource_xref_id,
        disqualification_reason: screen?.disqualification_reason,
        disqualification_detail: screen?.disqualification_detail,
        assigned_to_user_id: screen?.assigned_to_user_id,
        qa_feedback_detail: screen?.qa_feedback_detail,
      },
      { keepDirtyValues: keepDirtyScreen },
    )
    setKeepDirtyScreen(true)
  }

  useEffect(() => {
    screenIdRef.current = screenId // Update the ref when screenId changes
    setKeepDirtyScreen(false)
  }, [screenId])

  const navigate = useNavigate()
  // Adding a dependency on handleResetScreen somehow creates a circular dependency.
  // biome-ignore lint/correctness/useExhaustiveDependencies: Explanation above:
  useEffect(() => {
    if (!isLoadingScreen && !screen) {
      navigate("/patients")
      return
    }
    if (Object.keys(screen)?.length === 0 || isLoadingScreen) return
    handleResetScreen()
  }, [isLoadingScreen, screen, keepDirtyScreen])

  const submitQa = async (field, value) => {
    await submitScreenUpdate({ ...screen, [field]: value }, "QA")
  }

  const submitScreenUpdate = async (data, attribute) => {
    // Don't save a screen.id, if the screen id and the current screenId don't match!
    if (data.id !== screenIdRef.current) return
    if (data.assigned_to_user_id === "") data.assigned_to_user_id = null
    setSavingMeta(true)
    await useUpdate(
      "screens",
      screenIdRef.current,
      data,
      getAccessTokenSilently,
    )
    event({
      eventName: `ScreenUpdate${attribute}`,
      meta: {
        patient_id: patientId,
        screen_id: screenIdRef.current,
        assigned_to_user_id: data.assigned_to_user_id,
      },
      getAccessTokenSilently,
    })
    mutateScreen()
    toast.success("Saved")
    setSavingMeta(false)
  }
  const handleNextAppointmentClick = async (nextAppointmentDate) => {
    setFormValue("follow_up_dt", parseLocalTimeToUTC(nextAppointmentDate))
    submitScreenUpdate(formValues(), "FollowupDate")
  }
  const submitAssignedToUser = async (data) =>
    submitScreenUpdate(data, "AssignedUser")

  const showConfirmationModal = () => {
    return new Promise<boolean>((resolve) => {
      setConfirmDqBlocking(true) // Open the modal
      setResolvePromise(() => resolve) // Store the resolve function for later
    })
  }

  const handleConfirmDisqualification = () => {
    setConfirmDqBlocking(false) // Close modal
    if (resolvePromise) {
      resolvePromise(true) // Resolve with true
      setResolvePromise(null) // Clear the resolve function
    }
  }

  const handleRejectDisqualification = () => {
    setConfirmDqBlocking(false) // Close modal
    if (resolvePromise) {
      resolvePromise(false) // Resolve with false
      setResolvePromise(null) // Clear the resolve function
    }
  }

  const submitStatus = async (data) => {
    if (
      screenStartStatus === "assign" &&
      data?.status === "disqualified" &&
      (Date.now() - screenStart) / 1000 < 20
    ) {
      const result = await showConfirmationModal() // Call the modal function
      if (!result) {
        // User rejected
        return // Exit without proceeding
      }
    }

    setFormValue("status", data.status)
    const formData = formValues()
    submitScreenUpdate(formData, "Status")
  }

  const submitEsourceId = async (data) => submitScreenUpdate(data, "EsourceId")
  const submitDisqualificationReason = async (data) => {
    submitScreenUpdate(data, "DisqualificationReason")
  }

  const submitDisqualificationDetail = async (data) =>
    submitScreenUpdate(data, "DisqualificationDetail")
  const submitQaFeedbackDetail = async (data) =>
    submitScreenUpdate(data, "QaFeedbackDetail")
  const submitFeedbackScore = async (data) => {
    const scaledFeedbackScore = (Number.parseInt(data.feedback_score) - 1) * 25
    submitScreenUpdate(
      { id: screen.id, feedback_score: scaledFeedbackScore },
      "FeedbackScore",
    )
  }
  const submitFollowupDate = async (data) => {
    data.follow_up_dt = parseLocalTimeToUTC(data.follow_up_dt)
    submitScreenUpdate(data, "FollowupDate")
  }
  const submitFollowupReason = async (data) =>
    submitScreenUpdate(data, "FollowupReason")

  const handleBack = () => {
    event({
      eventName: "Click",
      meta: { element: "ScreenBackButton" },
      getAccessTokenSilently,
    })
    setCurrentStudyScreenId("")
    setCurrentStudyScreenName("")
  }

  const handleCallClick = () => {
    event({
      eventName: "CallPatientClick",
      meta: { patient_id: patientId, screen_id: screenIdRef.current },
      getAccessTokenSilently,
    })
    setContactDispositionOpen(true)
  }

  const handleValidation = (study_question_responses) => {
    for (const study_question_id of Object.keys(study_question_responses)) {
      const exclusion = questions
        .find((question) => question.StudyQuestion.id === study_question_id)
        .StudyQuestion?.meta?.categories?.includes("exclusion_criteria")
      if (
        exclusion &&
        study_question_responses[study_question_id] === true &&
        !ineligibleModalOpen
      ) {
        setIneligibleModalOpen(true)
      }
    }
  }

  const debouncedSubmitDisqualificationDetail = useDebounce(
    handleSubmitScreen(submitDisqualificationDetail),
    500,
  )
  const debouncedSubmitFollowupReason = useDebounce(
    handleSubmitScreen(submitFollowupReason),
    500,
  )
  const debouncedSubmitESourceId = useDebounce(
    handleSubmitScreen(submitEsourceId),
    500,
  )

  const debouncedSubmitQaFeedbackDetail = useDebounce(
    handleSubmitScreen(submitQaFeedbackDetail),
    500,
  )
  const debouncedSubmitQuestionResponse = useDebounce(
    submitQuestionResponse,
    1000,
  )

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setFormValue("disqualification_reason", screen.disqualification_reason)
    setFormValue("disqualification_detail", screen.disqualification_detail)
  }, [screen?.disqualification_detail, screen?.disqualification_reason])

  useEffect(() => {
    setCurrentScreen(screen)
  }, [screen])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!screenStartStatus) {
      setScreenStartStatus(screen?.status)
    }
  }, [screen])

  const {
    data: { data: batched_by_user },
  } = useShowQuery("users", currentScreen?.batched_by_user_id || "")

  const mutateBatchedByUser = () => {
    queryClient.invalidateQueries({
      queryKey: ["users", currentScreen?.batched_by_user_id],
    })
  }

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (currentScreen?.batched_by_user_id) {
      mutateBatchedByUser()
    }
  }, [currentScreen])

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const textQuestions = questions.filter(
      (question) => question.Question.question_type === "text",
    )

    for (const textQuestion of textQuestions) {
      const studyQuestionId = textQuestion.StudyQuestion.id

      if (dirtyFields[studyQuestionId]) {
        debouncedSubmitQuestionResponse(
          studyQuestionId,
          watchedField[studyQuestionId],
        )
      }
    }
  }, [watchedField, dirtyFields, debouncedSubmitQuestionResponse])

  useEffect(() => {
    // Guard if API data isn't back yet
    if (questions?.length === 0) return
    // Map the questions data into something RHF can use for defaultValues
    // eg { 'f59019a0-f210-4749-8b7b-c08598309d02': true }
    const defaultValues = questions?.reduce((object, question) => {
      object[question?.StudyQuestion.id] =
        question?.StudyQuestionResponse?.response?.value
      return object
    }, {})
    // Set up initial form defaultValues
    resetResponses(defaultValues)
  }, [questions, resetResponses])

  useEffect(() => {
    setCurrentStudyScreenName(screen?.study?.abbreviation)
  }, [screen, setCurrentStudyScreenName])

  const renderStatusIcon = (status) => {
    const icon = statusOptions[status].icon
    return <HeroIcon icon={icon} className="h-8 w-8" />
  }

  const addToESourceHandleClick = () => {
    setScheduleInESourceModalOpen(true)
  }

  const currentScreenStatus = (status) => {
    return snakeToTitle(status) === snakeToTitle(screen?.status)
  }

  const stars = () => {
    if (!screen?.feedback_score) return 0
    // Bound within the range of 0 to 100
    const bounded = Math.max(0, Math.min(100, screen?.feedback_score))
    // Adjust to the nearest of 0, 25, 50, 75, 100
    const adjusted = Math.round(bounded / 25) * 25
    // Map directly to 1-5 (0=1, 25=2, 50=3, 75=4, 100=5)
    return adjusted / 25 + 1
  }

  const noResponses =
    questions?.filter((question) => question?.StudyQuestionResponse)?.length ===
    0

  // // Prevent navigation away if status is DQ and there isn't a screen_response
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      screen?.status === "disqualified" &&
      noResponses,
  )

  const [blockedCount, setBlockedCount] = useState(0)

  const esourceURL = () => {
    let url = "https://app.clinicalresearch.io/topography-health/"

    const practiceStudy = practiceStudies.filter(
      (practiceStudy) => practiceStudy.study_id === screen?.study_id,
    )?.[0]

    if (
      screen?.esource_xref_id &&
      practice?.xref_esource_practice_slug &&
      practiceStudy?.xref_study_id
    ) {
      url = `${url}${practice.xref_esource_practice_slug}/study/${practiceStudy?.xref_study_id}/subject/${screen?.esource_xref_id}`
    } else if (
      practice?.xref_esource_practice_slug &&
      practiceStudies?.[0]?.xref_study_id
    ) {
      url = `${url}${practice.xref_esource_practice_slug}/study/${practiceStudy?.xref_study_id}/subjects`
    } else if (practice?.xref_esource_practice_slug) {
      url = `${url}${practice.xref_esource_practice_slug}/subjects`
    }
    return url
  }

  const statusOptionsComponent = (
    <div className="grid grid-cols-11 justify-center space-x-1 mb-4 mx-2 py-1.5">
      {/* Filter out the Provider Referral status unless it's a referral. */}
      {Object.keys(statusOptions)
        .filter(
          (status) =>
            (screen?.status === "provider_referral" && status !== "assign") ||
            (screen?.status !== "provider_referral" &&
              status !== "provider_referral"),
        )
        .map((statusKey) => (
          <div
            key={statusKey}
            className={`flex hover:cursor-pointer hover:border-gray-700 ${
              screen?.esource_status
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-gray-200"
            } text-gray-600 dark:text-gray-400 ${
              screen?.esource_status ? "" : "hover:dark:text-gray-600"
            }
            ${
              currentScreenStatus(statusKey)
                ? `${statusOptions[statusKey].bgColor} hover:cursor-default  dark:text-gray-700`
                : "hover:bg-gray-50 hover:dark:text-gray-600 hover:border-gray-700 hover:cursor-pointer"
            }
            ${
              currentScreenStatus(statusKey)
                ? statusOptions[statusKey].borderColor
                : ""
            }
            border-2 rounded-lg lg:h-32 pt-2 xl:h-24 md:pt-0 items-center justify-center place-items-center`}
            onKeyDown={
              screen?.esource_status
                ? undefined
                : () =>
                    handleSubmitScreen(() =>
                      submitStatus({ status: statusKey }),
                    )
            }
            onClick={
              screen?.esource_status
                ? undefined
                : handleSubmitScreen(() => submitStatus({ status: statusKey }))
            }
          >
            <div className="flex flex-col items-center">
              {renderStatusIcon(statusKey)}
              <p
                className={`text-center h-10 mt-1 text-sm font-semibold ${
                  statusKey === "disqualified" ? "text-xs 2xl:text-sm" : ""
                }`}
              >
                {snakeToTitle(statusKey)}
              </p>
            </div>
          </div>
        ))}
      <SaveSpinner isShowing={savingMeta} />
    </div>
  )

  return (
    <>
      {confirmDqBlocking && (
        <ConfirmDisqualificationModal
          confirm={handleConfirmDisqualification} // Confirm handler
          reject={handleRejectDisqualification} // Reject handler
        />
      )}
      {blocker.state === "blocked" && (
        <SelectCriterionModal
          closeModal={blocker?.reset}
          override={blocker.proceed}
          setBlockedCount={setBlockedCount}
          blockedCount={blockedCount}
        />
      )}
      <div className="flex px-4 pt-2 items-center bg-white dark:bg-transparent border-l border-r border-gray-200">
        <Link
          to={`/patients/${patientId}/screens`}
          className="text-sm flex items-center hover:underline"
          onClick={() => handleBack()}
        >
          <HeroIcon icon="ArrowSmLeftIcon" className="h-5 w-5" />
          Back to all studies
        </Link>
      </div>
      <div className="border-l border-r border-b rounded-b-lg shadow-md py-2 bg-white dark:bg-transparent">
        <div className="m-4">
          {screen?.batched &&
            batched_by_user?.id &&
            batched_by_user?.id !== currentUser?.id && (
              <Alert color="failure" icon={HiInformationCircle}>
                <span className="font-medium">Already Under Review</span> This
                screen is currently being reviewed
                {batched_by_user?.name ? ` by ${batched_by_user?.name}` : ""}.
              </Alert>
            )}
        </div>
        <div className="px-3 pt-2 justify-start w-full items-center">
          <div className="font-bold pl-1 text-lg">
            <div className="flex items-center">
              Next Step Potential{" "}
              <Tooltip
                placement="top"
                content="1 = Low likelihood of moving past next step. 5 = High likelihood of moving past next step."
              >
                <HeroIcon
                  icon="InformationCircleIcon"
                  className="h-5 ml-2"
                  outline
                />
              </Tooltip>
            </div>
          </div>
          <div className="flex w-1/5 pl-0.5">
            <form onChange={handleSubmitScreen(submitFeedbackScore)}>
              <Controller
                control={controlScreen}
                name="feedback_score"
                // rules={{
                //   validate: (rating) => rating > 0,
                // }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Rating
                    className="h-7"
                    value={stars()}
                    isRequired
                    onChange={(stars) =>
                      submitFeedbackScore({ feedback_score: stars })
                    }
                    visibleLabelId="rating_label"
                    onBlur={onBlur}
                  />
                )}
              />
            </form>
          </div>
        </div>
        <p className="p-0 mt-6 ml-4 mr-2 text-lg font-bold text-gray-900 dark:text-white flex items-center">
          Status
          <div className="ml-2 mt-0.5">
            {screen?.status_updated_at && (
              <div className="flex items-center">
                <div
                  title={screen?.status_updated_at}
                  className="font-normal text-xs text-gray-500"
                >
                  {upperCaseFirst(friendlyRelative(screen?.status_updated_at))}
                </div>
              </div>
            )}
          </div>
        </p>
        <div className="flex flex-1 items-center ml-4">
          <h4 className="block mr-1 font-bold text-md">ESource Status</h4>
          {screen?.esource_status ? (
            <p className="text-lg text-gray-500 px-3">
              {snakeToTitle(screen?.esource_status)}
            </p>
          ) : (
            <Tooltip content={"Add the CRIO ID below to track ESource Status."}>
              <p className="text-lg text-gray-300 px-3">Unknown</p>
            </Tooltip>
          )}
        </div>
        {screen?.esource_status ? (
          <Tooltip
            content={
              "Status changes disabled, once patient status is tracked in Esource."
            }
          >
            {statusOptionsComponent}
          </Tooltip>
        ) : (
          statusOptionsComponent
        )}
        <div className="flex ml-6 mb-4">
          <div className="flex items-center">
            <form onChange={handleSubmitScreen(submitAssignedToUser)}>
              <h4 className="mr-2 font-bold text-md">Assigned to</h4>
              <select
                {...registerScreen("assigned_to_user_id")}
                className="w-80 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-lg hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
              >
                <option value="">None</option>
                {users.map((user, index) => (
                  <option key={user.id} value={user.id} className="">
                    {user.name}
                  </option>
                ))}
              </select>
            </form>
          </div>
          <div>
            <form
              onSubmit={handleSubmitScreen(submitFollowupDate)}
              onChange={handleSubmitScreen(submitFollowupDate)}
              className="ml-4"
            >
              <h4 className="mr-2 font-bold text-md">Due (Follow-Up Date)</h4>
              <div className="flex">
                <Controller
                  name="follow_up_dt"
                  control={controlScreen}
                  defaultValue={
                    screen?.follow_up_dt &&
                    dateTimeISOWithoutTimezone(
                      new Date(`${screen?.follow_up_dt}Z`),
                    )
                  }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="datetime-local"
                      value={
                        screen?.follow_up_dt &&
                        dateTimeISOWithoutTimezone(
                          new Date(`${screen?.follow_up_dt}Z`),
                        )
                      }
                      sx={{
                        display: "flex",
                        padding: "0",
                        input: {
                          fontSize: "0.9rem",
                          padding: "0.8rem 0.9rem",
                        },
                        width: 250,
                        boxShadow: "none",
                      }}
                      InputProps={{
                        classes: {
                          input:
                            "text-sm dark:border dark:rounded dark:border-solid dark:hover:border-gray-600 dark:hover:border-solid dark:hover:rounded",
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
                <div className="flex items-center ml-2">
                  ({currentBrowserTimezone()})
                </div>
              </div>
            </form>
          </div>
          <div className="mx-4 w-full">
            <form
              onBlur={debouncedSubmitFollowupReason}
              onChange={debouncedSubmitFollowupReason}
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmitScreen(submitFollowupReason)
              }}
            >
              <h4 className="mr-2 font-bold text-md">Follow Up Reason</h4>
              <div className="">
                <input
                  {...registerScreen("follow_up_reason")}
                  type="text"
                  name="follow_up_reason"
                  id="follow_up_reason"
                  className="shadow-sm border border-gray-300 dark:hover:border-gray-600 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-3 dark:bg-gray-800 dark:border-gray-300 dark:placeholder-gray-800 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:border-gray-800"
                />
              </div>
            </form>
          </div>
        </div>
        <div className="flex ml-6 text-gray-500">
          {screen?.updated_at && screen?.updated_by && (
            <div className="w-80 items-center mb-2 ">
              <h4 className="mr-1 font-bold text-md">
                Updated{" "}
                {upperCaseFirst(
                  friendlyRelativeWithoutTime(screen?.updated_at),
                )}
              </h4>
              <p className="font-normal text-md" title={screen?.updated_at}>
                {upperCaseFirst(friendlyTime(screen?.updated_at))} by{" "}
                {screen?.updated_by.name || " "}
              </p>
            </div>
          )}
          {nextAppointmentISO(patient) && (
            <div className="items-center ml-4 mb-2">
              <Tooltip
                content={"Click to set Next Appointment as Follow Up Date."}
              >
                <div
                  className="hover:cursor-pointer"
                  onClick={() =>
                    handleNextAppointmentClick(
                      nextAppointmentLocalTime(patient),
                    )
                  }
                  onKeyDown={() =>
                    handleNextAppointmentClick(
                      nextAppointmentLocalTime(patient),
                    )
                  }
                >
                  <h4 className="mr-1 font-bold text-md"> Next Appointment </h4>
                  <p className="font-normal text-md">
                    {nextAppointmentSlashFormat(patient)}
                  </p>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
        <hr className="my-4 m-auto w-[97%]" />
        {/* DQ Reason / Detail */}
        <div
          className={`${
            screen?.status === "disqualified" ? "block" : "hidden"
          } w-full items-center mb-2`}
        >
          <div className="flex w-full my-2 ml-2">
            <div className="flex items-center mx-4 space-x-2 justify-start  w-[19.65%]">
              <div className="rounded-lg border-2 border-red-700 bg-red-300 ">
                <HeroIcon icon="ExclamationIcon" className="h-8 w-8" />
              </div>
              <h4 className="text-red-500 font-bold text-md">
                Reason for Disqualification
              </h4>
            </div>
            <form
              onChange={handleSubmitScreen(submitDisqualificationReason)}
              className={
                screen?.status === "disqualified"
                  ? "flex w-[75%]"
                  : "hidden w-[75%]"
              }
            >
              <select
                {...registerScreen("disqualification_reason")}
                className="w-full rounded-md border-2 border-red-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
              >
                <option disabled value="">
                  Select a reason
                </option>
                {disqualificationReasons.map((reason) => (
                  <option key={toSnakeCase(reason)} value={reason} className="">
                    {reason}
                  </option>
                ))}
              </select>
            </form>
          </div>
          <div className="flex w-full my-2 ml-2">
            <div className="flex items-center mx-4 space-x-2 justify-start w-[19.65%]">
              <div className="rounded-lg border-2">
                <HeroIcon
                  icon="DocumentTextIcon"
                  outline={true}
                  className="h-8 w-8"
                />
              </div>
              <h4 className="w-full font-bold text-md">
                Disqualification Detail
              </h4>
            </div>
            <form
              onBlur={handleSubmitScreen(submitDisqualificationDetail)}
              onSubmit={(e) => {
                e.preventDefault()
                handleSubmitScreen(submitDisqualificationDetail)
              }}
              className="w-[75%] flex"
            >
              <input
                type="text"
                {...registerScreen("disqualification_detail")}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault
                    handleSubmitScreen(submitDisqualificationDetail)
                  }
                }}
                className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm hover:border-gray-800 dark:bg-gray-800 dark:hover:border-gray-600"
              />
              <button
                type="submit"
                onClick={() => handleSubmitScreen(submitDisqualificationDetail)}
                className="ml-2 inline-flex justify-center rounded-md border border-transparent bg-topo-green py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-500 focus:ring-offset-2"
              >
                Save
              </button>
            </form>
          </div>
        </div>
        <div
          className={`${
            ["moved_to_esource", "schedule_for_screen"].includes(screen?.status)
              ? "flex items-center"
              : "hidden"
          } items-center mb-2`}
        >
          {/*  */}
          <div>
            <div className="flex items-center px-6">
              <div className="flex flex-1">
                {screen?.esource_xref_id && (
                  <div className="flex items-center">
                    <Link
                      to={esourceURL()}
                      target="_blank"
                      title={esourceURL()}
                      className="text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-sm px-2.5 py-1.5 text-center inline-flex items-center"
                    >
                      <HeroIcon
                        icon="ExternalLinkIcon"
                        className="fill-white w-4 h-4 mr-2 flex"
                      />
                      {screen?.esource_xref_id &&
                      practice?.xref_esource_practice_slug &&
                      practiceStudies.filter(
                        (practiceStudy) =>
                          practiceStudy.study_id === screen?.study_id,
                      )?.[0]?.xref_study_id
                        ? "View in Esource"
                        : "Open Esource"}
                    </Link>
                  </div>
                )}
                {!screen?.esource_xref_id && (
                  <button
                    type="button"
                    onClick={addToESourceHandleClick}
                    onKeyDown={addToESourceHandleClick}
                    className="text-white bg-topo-green-500 hover:bg-topo-green-600 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 rounded font-semibol text-sm px-2.5 py-1.5 text-center inline-flex items-center"
                  >
                    <HeroIcon
                      icon="PlusCircleIcon"
                      className="fill-white w-4 h-4 mr-2 rotate-90"
                    />
                    Add to eSource
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="px-6 flex dark:bg-transparent">
            {/* CRIO */}
            <div className="flex w-24 items-center">
              <Tooltip content={<TooltipCrioId />}>
                <div className="flex h-full items-center pr-1">
                  <h4 className="block mr-1 font-bold text-md">CRIO ID</h4>
                  <HeroIcon className="h-5 w-5" icon="InformationCircleIcon" />
                </div>
              </Tooltip>
            </div>
            <form
              onBlur={debouncedSubmitESourceId}
              onChange={debouncedSubmitESourceId}
              className="flex"
            >
              <input
                {...registerScreen("esource_xref_id")}
                type="text"
                name="esource_xref_id"
                id="esource_xref_id"
                className="w-36 shadow-sm border border-gray-300 dark:hover:border-gray-600 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-800 dark:border-gray-300 dark:placeholder-gray-800 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:border-gray-800"
              />
            </form>
          </div>
        </div>
      </div>
      {managerOrQaUser(currentUser) && (
        <div className="mt-4 p-6 pt-4 rounded-lg border border-gray-200 shadow-md bg-white dark:bg-transparent">
          <div className="items-center min-h-5 mb-2">
            <div className="flex justify-between">
              <div className="flex items-center">
                <HeroIcon
                  icon="CheckCircleIcon"
                  className="text-gray-900 w-6 h-6 mr-1 dark:text-gray-400"
                />
                <h3 className="p-0 text-xl font-bold text-gray-900 dark:text-white">
                  Quality Assurance
                </h3>
              </div>
            </div>
          </div>
          <div className="mb-2">
            <div className="">
              <div className="mb-2 text-xs ml-7 w-full items-center">
                Result of QA chart review
              </div>
              <div className="flex w-full items-center space-x-4 ml-7 justify-start">
                <button
                  type="button"
                  className={`inline-flex items-center px-4 py-2 border-2 border-gray-100 shadow-sm text-sm leading-4 font-medium rounded-md hover:border-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent  ${
                    screen?.qa_status === "no_quality_issue"
                      ? "text-green-700 bg-green-400 outline-none ring-2 ring-offset-2 ring-green-500 dark:bg-green-400"
                      : ""
                  }}`}
                  onClick={() => submitQa("qa_status", "no_quality_issue")}
                >
                  <div className="mr-1">Agree</div>
                  <HeroIcon extraClasses="text-green-700" icon="ThumbUpIcon" />
                </button>
                <button
                  type="button"
                  className={`inline-flex items-center px-4 py-2 border-2 border-gray-100 shadow-sm text-sm leading-4 font-medium rounded-md hover:border-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-transparent ${
                    screen?.qa_status === "quality_issue"
                      ? "text-red-700 bg-red-400 outline-none ring-2 ring-offset-2 ring-red-500 dark:bg-red-400"
                      : ""
                  }}`}
                  onClick={() => submitQa("qa_status", "quality_issue")}
                >
                  <div className="mr-1">Disagree</div>
                  <HeroIcon extraClasses="text-red-700" icon="ThumbDownIcon" />
                </button>
                <div className="flex items-center w-full">
                  <div className="ml-4 w-[50%]">
                    <form
                      className={"flex flex-row"}
                      onBlur={debouncedSubmitQaFeedbackDetail}
                      onChange={debouncedSubmitQaFeedbackDetail}
                      onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmitScreen(submitQaFeedbackDetail)
                      }}
                    >
                      <div className="flex items-center ">
                        <h4 className="mr-2 font-bold text-sm">QA Feedback</h4>
                      </div>
                      <input
                        {...registerScreen("qa_feedback_detail")}
                        type="text"
                        name="qa_feedback_detail"
                        id="qa_feedback_detail"
                        className="w-full shadow-sm border border-gray-300 dark:hover:border-gray-600 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-800 dark:border-gray-300 dark:placeholder-gray-800 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:border-gray-800"
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {criteria && (
        <ScreenQualificationCriteria
          screenId={screenId}
          criteria={criteria}
          questions={questions}
          confidenceScore={screen?.confidence_score}
          tier={screen?.tier}
          tierDescription={screen?.tier_description}
        />
      )}

      {/* Call Script */}
      {screen?.study?.call_script && (
        <div className="mt-4 p-6  rounded-lg border border-gray-200 shadow-md">
          <div className="flex justify-between">
            <h3 className="text-xl font-bold">Call Script</h3>
          </div>
          <div className="mt-2">{screen?.study?.call_script}</div>
        </div>
      )}

      {/* Screening Questions Form */}
      <div className="mt-4 p-6  rounded-lg border border-gray-200 shadow-md bg-white dark:bg-transparent">
        <h3 className="text-xl font-bold mb-4 ">Screening Questions</h3>
        <ScreenContext.Provider value={{ submitQuestionResponse }}>
          {!questions.length && isFetchingQuestions ? (
            <LoadingScreen isLoading={isFetchingQuestions} />
          ) : (
            <Screening
              questions={questions}
              registerResponseField={registerResponseField}
              criteria={criteria}
            />
          )}
        </ScreenContext.Provider>
      </div>
    </>
  )
}
