import { useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useKeyPress } from "../shared/useKeyPress"

interface Props {
  confirm?: () => void
  reject?: () => void
}

export function ConfirmDisqualificationModal({ confirm, reject }: Props) {
  const [timer, setTimer] = useState(10)
  const [isTimerActive, setIsTimerActive] = useState(true)

  useKeyPress(["Escape"], () => {
    if (isTimerActive) {
      handleReject()
    }
  })

  useEffect(() => {
    if (isTimerActive && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1)
      }, 1000)
      return () => clearInterval(interval)
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (timer === 0) {
      handleReject()
    }
  }, [isTimerActive, timer])

  const handleReject = () => {
    setIsTimerActive(false)
    reject()
  }

  const handleConfirm = () => {
    setIsTimerActive(false)
    confirm()
  }

  return (
    <>
      <div
        className="fixed flex left-0 ml-12 right-0 z-50 items-center justify-center overflow-x-hidden overflow-y-auto top-4 md:inset-0 h-modal sm:h-full"
        id="confirm-disqualification-modal"
      >
        <div className="relative ml-10 w-full h-full max-w-md px-4 md:h-auto">
          <div className="relative bg-white rounded-lg shadow dark:bg-gray-800">
            <div className="flex items-start justify-between p-5 border-b rounded-t dark:border-gray-700">
              <h3 className="text-xl font-semibold dark:text-white">
                Disqualification Confirmation
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-700 dark:hover:text-white"
                onClick={() => handleReject()}
              >
                <svg
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>X Button</title>
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>
            <div className="py-4 px-6 space-y-2 text-md">
              <p>That disqualification was pretty quick!</p>
              <p>Did you mean to disqualify this patient?</p>
            </div>
            <div className="items-center p-4 border-t border-gray-200 dark:border-gray-700 rounded-b">
              <button
                onClick={handleConfirm}
                className="text-white bg-topo-green hover:bg-topo-green-700 focus:ring-4 focus:ring-topo-green-600 font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                type="button"
              >
                Yes
              </button>
              <button
                onClick={handleReject}
                className="relative text-white font-medium rounded-lg text-sm mr-5 px-5 py-2.5 text-center"
                type="button"
                style={{
                  background: `linear-gradient(to right, rgba(255, 0, 0, 0.7) ${100 - (timer / 10) * 100}%, rgba(255, 0, 0, 1) ${100 - (timer / 10) * 100}%)`,
                  transition: "background 0.5s ease", // Smooth transition for background
                  color: "white", // Text color
                }}
              >
                No ({timer} seconds remaining)
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="modal-backdrop"
        className="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40"
      />
    </>
  )
}
