import { HeroIcon, type IconName } from "./hero-icon"

interface Props {
  icon: IconName
  className?: string
  iconClassName?: string
  outline?: boolean
  text: string
  bgColorClass?: string
  bgHoverColorClass?: string
  focusRingColorClass?: string
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  onKeyUp?: React.KeyboardEventHandler<HTMLButtonElement>
}

export function ButtonLeadingIcon(props: Props) {
  const props_with_defaults = {
    outline: false,
    bgColorClass: "bg-topo-green",
    bgHoverColorClass: "hover:bg-topo-green-600",
    focusRingColorClass: "focus:ring-topo-green-500",
    ...props,
  }

  return (
    <button
      type="button"
      onClick={props_with_defaults.onClick}
      onKeyUp={props_with_defaults.onKeyUp}
      className={`inline-flex items-center p-2 pr-4 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white ${props_with_defaults.bgColorClass} ${props_with_defaults.bgHoverColorClass} focus:outline-none focus:ring-2 focus:ring-offset-2 ${props_with_defaults.focusRingColorClass} ${props_with_defaults.className}`}
    >
      <HeroIcon
        icon={props_with_defaults.icon}
        className={`h-5 w-7 mr-1 text-white ${props_with_defaults.iconClassName}`}
        outline={props_with_defaults.outline}
      />
      {props_with_defaults.text}
    </button>
  )
}
