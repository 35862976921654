import { useAuth0 } from "@auth0/auth0-react"
import { Button, Select, TextInput } from "flowbite-react"
import React, { useState } from "react"
import { useCreate, useDelete } from "../../shared/use-rest"

function BatchDeletion({ studyId, refetchBatch }) {
  const { getAccessTokenSilently } = useAuth0()

  const handleSubmit = async () => {
    await useDelete("batches", studyId, getAccessTokenSilently)
    refetchBatch()
  }

  // TODO - add a note when a chart is under review by another person
  return (
    <div className="p-6 max-w-md mx-auto bg-white rounded-xl shadow-md space-y-4">
      <h2 className="text-xl font-bold">Clear Batch</h2>

      <Button onClick={handleSubmit}>Clear</Button>
    </div>
  )
}

export default BatchDeletion
