import { useAuth0 } from "@auth0/auth0-react"
import LinearProgress from "@mui/material/LinearProgress"
import {
  DataGridPro,
  type GridCallbackDetails,
  type GridCellParams,
  type GridColDef,
  type GridColumnVisibilityModel,
  type GridComparatorFn,
  type GridEventListener,
  type GridFilterModel,
  type GridRowHeightParams,
  type GridSortModel,
  type GridValueGetterParams,
  type MuiEvent,
  getGridStringOperators,
  gridFilterModelSelector,
  useGridApiContext,
  useGridApiRef,
  useGridSelector,
} from "@mui/x-data-grid-pro"
import { Tooltip } from "flowbite-react"
import {
  type MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import resolveConfig from "tailwindcss/resolveConfig"
import type { v4 as uuidv4 } from "uuid"
import isUUID from "validator/lib/isUUID"
import tailwindConfig from "../../tailwind.config.js"
import { BulkAssignModal } from "../components/bulk-assign-modal"
import { HeroIcon } from "../components/hero-icon"
import { PatientQuickAdd } from "../components/patients/patient-quick-add"
import { StudyScreenStatusChip } from "../components/patients/study-screen-status-chip"
import { isAdmin } from "../components/protected-admin"
import { AuthorizationContext } from "../shared/authorization-provider"
import {
  dateTimeISO,
  dateTimeISOWithoutTimezone,
  friendlyDateTime,
} from "../shared/friendly-dates"
import { safelyParseJSON } from "../shared/json-utils"
import { snakeToTitle } from "../shared/text-utils"
import {
  useCreate,
  useGet,
  useGetIndexInfinite,
  useShow,
} from "../shared/use-rest"
import { ModalType } from "./patient"
import { BookmarkModal } from "./patients/bookmark-modal.js"
import { ToolbarPatients } from "./patients/toolbar-patients"

const fullConfig = resolveConfig(tailwindConfig)
const DEFAULT_FILTER_AND_SORT = (practiceId) => {
  return {
    sorting: [],
    filtering: { filters: [], link_operator: "and" },
    search: "",
    practice_id: practiceId,
  }
}

export function Patients() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { practiceId, changePractice, authorizedPractices } =
    useContext(AuthorizationContext)
  const limit =
    searchParams.get("limit") || process.env.PATIENT_DIRECTORY_LIMIT || 5000
  const pageSize = 50
  const [bookmarkId, setBookmarkId] = useState(
    searchParams.get("bookmarkId")
      ? searchParams.get("bookmarkId")
      : localStorage.getItem("bookmarkId"),
  )
  const [initialState, setInitialState] = useState(true)
  const [updateFilterAndSort, setUpdateFilterAndSort] = useState(false)
  const [bookmarkPracticeId, setBookmarkPracticeId] = useState(undefined)
  const [filterAndSort, setFilterAndSort] = useState(undefined)
  const [search, setSearch] = useState("")
  const defaultWidth = 150
  const apiRef = useGridApiRef()
  const { getAccessTokenSilently } = useAuth0()
  const { currentUser, isLoading: isLoadingCurrentUser } =
    useContext(AuthorizationContext)
  const {
    data: patientsData,
    isValidating: isValidatingPatients,
    error: isErrorPatients,
    mutate: mutatePatients,
    size: pagePatients,
    setSize: setPagePatients,
  } = useGetIndexInfinite(
    bookmarkId && practiceId && `patients_filter_and_sort/${bookmarkId}`,
    `practice_id=${practiceId}`,
    { pageSize: pageSize },
  )

  const { data: patientQueryCount } = useShow(
    bookmarkId && practiceId && "patients_filter_and_sort_count",
    bookmarkId,
    `practice_id=${practiceId}`,
  )

  useEffect(() => {
    console.dir("isErrorPatients")
    console.dir(isErrorPatients)
    // if (isErrorPatients) {
    //   localStorage.removeItem("bookmarkId")
    // }
    return () => {}
  }, [isErrorPatients])

  const [patients, setPatients] = useState([])
  const [selection, setSelection] = useState(false)
  const toggleSelection = () => setSelection(!selection)
  const selectionEnabled = () => isAdmin(currentUser)
  const [modalOpen, setModalOpen] = useState<ModalType>()

  useEffect(() => {
    if (!patientsData) return

    const newPatients = patientsData.flatMap((data) => data.data)
    setPatients([...newPatients])
    return () => setPatients([])
  }, [patientsData])

  const handleScroll = () => {
    if (patients.length > 0) {
      setPagePatients(pagePatients + 1)
    }
  }
  const isLoadingPatients = () => patients.length === 0

  const columnDefaults: Partial<GridColDef> = {
    width: defaultWidth,
    getApplyQuickFilterFn: undefined,
    display: "flex",
  }

  const columns: GridColDef[] = [
    {
      ...columnDefaults,
      field: "Continue Screen",
      headerName: "",
      width: 110,
      renderCell: (params) => renderCellContinueScreen(params, filterAndSort),
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
    },
    {
      ...columnDefaults,
      field: "batched",
      headerName: "Batched",
      width: 110,
      disableColumnMenu: true,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "batched",
        ),

      renderCell: (params) =>
        params?.value === "Select a Study"
          ? "Select a Study"
          : params?.value === true
            ? "True"
            : "False",
    },
    {
      ...columnDefaults,
      field: "full_name",
      headerName: "Name",
      sortable: false,
      width: 200,
      valueGetter: valueGetterFullName,
    },
    {
      ...columnDefaults,
      field: "mrn",
      headerName: "MRN",
      width: defaultWidth,
    },
    {
      ...columnDefaults,
      field: "screens",
      headerName: "Study",
      width: 200,
      valueGetter: valueGetterScreens,
      renderCell: (params) => renderCellScreens(params, filterAndSort),
      getApplyQuickFilterFn: undefined,
      sortable: false,
    },
    {
      ...columnDefaults,
      field: "statuses",
      headerName: "Status",
      width: 200,
      valueGetter: valueGetterScreens,
      renderCell: (params) =>
        renderCellScreenStatusTitle(params, filterAndSort),
      getApplyQuickFilterFn: undefined,
      sortable: false,
    },
    {
      ...columnDefaults,
      field: "confidence_score",
      headerName: "Confidence Score",
      width: 220,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "confidence_score",
        ),
      renderCell: renderCellOverflowAuto,
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "tier",
      headerName: "Tier",
      width: 220,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreen(value, row, column, apiRef, filterAndSort),
      renderCell: renderCellTier,
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "next_appointment",
      type: "dateTime",
      headerName: "Next Appointment",
      width: 175,
      valueGetter: valueGetterNextAppointment,
      valueFormatter: valueFormatterNextAppointment,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "next_appointment_type",
      headerName: "Next Appointment Type",
      width: 175,
      valueGetter: valueGetterNextAppointmentType,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "next_appointment_location",
      headerName: "Next Appointment Location",
      width: 175,
      valueGetter: valueGetterNextAppointmentLocation,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "last_appointment",
      type: "dateTime",
      headerName: "Last Appointment",
      width: 175,
      valueGetter: valueGetterLastAppointment,
      valueFormatter: valueFormatterLastAppointment,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "last_appointment_type",
      headerName: "Last Appointment Type",
      width: 175,
      valueGetter: valueGetterLastAppointmentType,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "last_appointment_location",
      headerName: "Last Appointment Location",
      width: 175,
      valueGetter: valueGetterLastAppointmentLocation,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "dob",
      headerName: "DOB",
      width: defaultWidth,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "referring_provider",
      headerName: "Provider",
      width: defaultWidth,
      getApplyQuickFilterFn: undefined,
    },

    {
      ...columnDefaults,
      field: "assigned",
      headerName: "Assigned",
      width: 200,
      valueGetter: valueGetterScreens,
      renderCell: (params) => renderCellAssignedTo(params, filterAndSort),
      getApplyQuickFilterFn: undefined,
      sortable: false,
      filterOperators: getGridStringOperators().filter(
        (value, row, column, apiRef) =>
          ["isEmpty", "isNotEmpty"].includes(value),
      ),
    },
    {
      ...columnDefaults,
      field: "phone",
      headerName: "Phone Number",
      width: defaultWidth,
    },
    {
      ...columnDefaults,
      field: "contact_count",
      headerName: "Contacts",
      width: defaultWidth,
      valueGetter: valueGetterContacts,
    },
    {
      ...columnDefaults,
      field: "contact_count_phone",
      headerName: "Phone Contacts",
      width: defaultWidth,
      valueGetter: valueGetterContactsPhone,
    },
    {
      ...columnDefaults,
      field: "can_contact",
      headerName: "Can Contact",
      width: defaultWidth,
      valueGetter: (value, row, column, apiRef) =>
        value === true ? "Yes" : "No",
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "follow_up_reason",
      headerName: "Follow Up Reason",
      width: 175,
      renderCell: renderCellOverflow,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "follow_up_reason",
        ),
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "follow_up_dt",
      headerName: "Follow Up Date",
      width: 175,
      renderCell: renderCellStudyDateTime,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "follow_up_dt",
        ),
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "disqualification_reason",
      headerName: "Disqualification Reason",
      width: 175,
      renderCell: renderCellOverflow,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "disqualification_reason",
        ),
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "disqualification_detail",
      headerName: "Disqualification Detail",
      width: 175,
      renderCell: renderCellOverflow,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "disqualification_detail",
        ),
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "status_updated_at",
      headerName: "Last Status Change Date",
      width: 175,
      renderCell: renderCellStudyDateTime,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "status_updated_at",
        ),
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "preferred_language",
      headerName: "Preferred Language",
      width: defaultWidth,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "preferred_contact_time",
      headerName: "Preferred Contact Time",
      width: 180,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "source",
      headerName: "Source",
      valueGetter: (params) =>
        params?.value ? snakeToTitle(params?.value) : null,
      width: 200,
      getApplyQuickFilterFn: undefined,
    },
    {
      ...columnDefaults,
      field: "esource_xref_id",
      headerName: "CRIO ID",
      width: 200,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterScreenValue(
          value,
          row,
          column,
          apiRef,
          filterAndSort,
          "esource_xref_id",
        ),
      renderCell: renderCellOverflowAuto,
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "feedback_score",
      headerName: "Potential",
      width: 220,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterStars(
          valueGetterScreenValue(
            value,
            row,
            column,
            apiRef,
            filterAndSort,
            "feedback_score",
          ),
        ),
      renderCell: renderCellStars,
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "created_at",
      type: "dateTime",
      headerName: "Created",
      width: 220,
      getApplyQuickFilterFn: undefined,
      valueFormatter: (value, row, column, apiRef) => dateTimeISO(value),
    },
    {
      ...columnDefaults,
      field: "updated_at",
      headerName: "Last Updated",
      width: 220,
      valueGetter: (value, row, column, apiRef) =>
        valueGetterUpdatedAt(value, row, column, apiRef, filterAndSort),
      renderCell: renderCellOverflowAuto,
      getApplyQuickFilterFn: undefined,
      filterable: singleStudyView(filterAndSort?.filtering?.filters),
      sortable: singleStudyView(filterAndSort?.filtering?.filters),
    },
    {
      ...columnDefaults,
      field: "",
      type: "actions",
      headerName: "", // dummy column to flex: 1
      width: 150,
      renderCell: () => <></>,
      sortable: false,
      filterable: false,
      hideable: false,
      disableColumnMenu: true,
      flex: 1,
      align: "left",
      headerAlign: "left",
    },
  ]
  const customSortableColumns = [
    "updated_at",
    "created_at",
    "next_appointment",
    "dob",
  ]
  const [quickAddOpen, setQuickAddOpen] = useState(
    searchParams.get("quick_add") === "open",
  )
  const [filtersOpen, setFiltersOpen] = useState(false)
  const [rowSelectionModel, setRowSelectionModel] = useState([])
  const [disableColumnFilter, setDisableColumnFilter] = useState(false)
  const navigate = useNavigate()

  const getRowHeight = useCallback(
    ({ model, densityFactor }: GridRowHeightParams) => {
      // Leaving this here as a reminder: Don't fight the framework :)
      // return model.screens.length * 20 * densityFactor + 20
      return "auto"
    },
    [],
  )

  // Init Columns from Localstorage
  useEffect(() => {
    for (const key of searchParams.keys()) {
      if (key.includes("filter_") || key.includes("sort_")) {
        searchParams.delete(key)
      }
    }

    apiRef.current.subscribeEvent("filterModelChange", handleFilterModelChange)
    apiRef.current.subscribeEvent("sortModelChange", handleSortModelChange)
    apiRef.current.subscribeEvent(
      "columnVisibilityModelChange",
      handleColumnVisibilityChange,
    )

    const newColumnVisibilityModelRaw = localStorage.getItem(
      "columnVisibilityModel",
    )
    if (newColumnVisibilityModelRaw !== null) {
      const newColumnVisibilityModel = JSON.parse(newColumnVisibilityModelRaw)
      apiRef.current.setColumnVisibilityModel(newColumnVisibilityModel)
    }
  }, [
    searchParams,
    apiRef.current.subscribeEvent,
    apiRef.current.setColumnVisibilityModel,
  ])

  // Update Columns when they change
  const handleColumnVisibilityChange: GridEventListener<
    "columnVisibilityModelChange"
  > = (
    gridVisibilityModel: GridColumnVisibilityModel,
    _event, // MuiEvent<{}>
    _details, // GridCallbackDetails
  ) =>
    localStorage.setItem(
      "columnVisibilityModel",
      JSON.stringify(gridVisibilityModel),
    )

  useEffect(() => {
    if (!filterAndSort && bookmarkId && practiceId) {
      ;(async () => {
        try {
          const { data, status } = await useGet(
            "filter_and_sort_bookmark",
            `${bookmarkId}`,
            getAccessTokenSilently,
          )
          const content = data?.data?.content
            ? JSON.parse(data?.data?.content)
            : undefined
          if (
            content.practice_id === practiceId ||
            content.practice_id === undefined
          ) {
            setFilterAndSort(content ?? content)
            setSearch(content?.search ?? "")
            content.search = undefined
          } else {
            setBookmarkPracticeId(content.practice_id)
            setModalOpen(ModalType.Bookmark)
          }
        } catch (error) {
          console.dir(error)
          setFilterAndSort(DEFAULT_FILTER_AND_SORT)
          return
        }
      })()
    }
  }, [practiceId, bookmarkId, filterAndSort, getAccessTokenSilently])

  useEffect(() => {
    if (
      bookmarkId &&
      searchParams.get("bookmarkId") !== bookmarkId &&
      isUUID(bookmarkId)
    ) {
      searchParams.set("bookmarkId", bookmarkId)
      setSearchParams(searchParams)
    }
  }, [bookmarkId, searchParams, setSearchParams])

  useEffect(() => {
    if (!practiceId) return
    if (bookmarkId && isUUID(bookmarkId)) {
      localStorage.setItem("bookmarkId", bookmarkId)
    } else {
      ;(async () => {
        console.dir("useEffectPracticeID")
        console.dir(practiceId)
        const response = await useCreate(
          "filter_and_sort_bookmark",
          {
            filter_and_sort: DEFAULT_FILTER_AND_SORT(practiceId),
          },
          getAccessTokenSilently,
        )
        setBookmarkId(response?.data?.data?.id)
      })()
    }
  }, [bookmarkId, getAccessTokenSilently, practiceId])

  useEffect(() => {
    if (!filterAndSort || !practiceId) {
      return
    }

    if (initialState) {
      const newGridFilterLinkOperator =
        filterAndSort?.filtering?.linkOperator || "and"
      const newGridSortModel: GridSortModel = filterAndSort?.sorting || []
      const newGridFilterModel: GridFilterModel = {
        items: filterAndSort?.filtering?.filters.map(
          ({ field, operator_value, value }) => ({
            id: field,
            field: field,
            operator: operator_value,
            value,
          }),
        ),
      }

      apiRef.current.setFilterModel(newGridFilterModel, "upsertFilterItems")
      apiRef.current.setSortModel(newGridSortModel)
      apiRef.current.setFilterLogicOperator(newGridFilterLinkOperator)
      setInitialState(false)
    } else {
      ;(async () => {
        const data = {
          filter_and_sort: {
            ...filterAndSort,
            search,
            practice_id: practiceId,
          },
        }
        const response = await useCreate(
          "filter_and_sort_bookmark",
          data,
          getAccessTokenSilently,
        )
        setBookmarkId(response?.data?.data?.id)
      })()
    }
  }, [
    filterAndSort,
    search,
    apiRef.current.setFilterModel,
    apiRef.current.setSortModel,
    apiRef.current.setFilterLogicOperator,
    practiceId,
    initialState,
    getAccessTokenSilently,
  ])

  const handleSortModelChange = (gridSortModel) => {
    setFilterAndSort((filterAndSort) => ({
      filtering: filterAndSort?.filtering ?? {
        filters: [],
        link_operator: "and",
      },
      sorting: gridSortModel,
    }))
  }

  const handleFilterModelChange = (gridFilterModel, details) => {
    if (details.reason === undefined || filterAndSort === undefined) return
    const filters = gridFilterModel.items?.map(({ field, operator, value }) => {
      if (field === "screens") field = "study"

      return {
        id: field,
        field: field,
        operator_value: operator,
        value,
      }
    })

    const selections = ((filterAndSort) => {
      return (
        filterAndSort?.filtering?.filters?.filter(
          (filterToCheck) =>
            ["study", "status", "assigned"].includes(filterToCheck?.field) &&
            !filters.find((filter) => filter.field === filterToCheck?.field),
        ) || []
      )
    })(filterAndSort)

    const filtering = {
      link_operator: gridFilterModel?.logicOperator || "and",
      filters: selections ? filters.concat(selections) : filters,
    }
    setFilterAndSort((filterAndSort) => ({
      sorting: filterAndSort?.sorting ?? [],
      filtering,
    }))
  }

  const handleColumnVisibilityModelChange = (
    model: GridColumnVisibilityModel,
    details: GridCallbackDetails<unknown>,
  ) => {
    setColumnVisibilityModel(model)
    localStorage.setItem("columnVisibilityModel", JSON.stringify(model))
  }

  const handleSelectionModelChange = (newRowSelectionModel) => {
    setRowSelectionModel(newRowSelectionModel)
    if (newRowSelectionModel?.length > 0) {
      setModalOpen(ModalType.BulkAssign)
    } else if (newRowSelectionModel?.length === 0) {
      setModalOpen(null)
    }
  }

  const handleAssign = (newAssignedToUserId: uuidv4) => {
    const payload = { assigned_to_user_id: newAssignedToUserId }
    // TODO: figure out how to get screen_ids from directory, only if it's filtered by study
    return useCreate(
      "bulk_requests",
      { model: "Screen", payload: payload, entities: [] },
      getAccessTokenSilently,
    )
  }

  const handleArchive = async () => {
    const archiveResponse = await useCreate(
      "bulk_patient_deletion_requests",
      { patients: rowSelectionModel },
      getAccessTokenSilently,
    )
    mutatePatients()
    return archiveResponse
  }

  const columnVisibilityModelInitial = () => {
    const columnVisibilityModelDefault = {
      // Hide these columns by default, the other columns will remain visible
      study: false,
      status: false,
      source: false,
      dob: false,
      referring_provider: false,
      phone: false,
      can_contact: false,
      next_appointment_type: false,
      last_appointment_type: false,
      contact_count_phone: false,
      preferred_language: false,
      preferred_contact_time: false,
      updated_at: false,
      created_at: false,
    }
    return localStorage.getItem("columnVisibilityModel") !== null
      ? safelyParseJSON(localStorage.getItem("columnVisibilityModel"))
      : columnVisibilityModelDefault
  }

  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    columnVisibilityModelInitial(),
  )

  return (
    <>
      {isErrorPatients ? "Failed to load patients. Is your VPN connected?" : ""}
      <PatientQuickAdd
        open={quickAddOpen}
        setOpen={setQuickAddOpen}
        refreshData={mutatePatients}
      />
      {modalOpen === ModalType.BulkAssign && (
        <BulkAssignModal
          setOpen={setModalOpen}
          handleAssign={handleAssign}
          handleArchive={handleArchive}
        />
      )}
      {modalOpen === ModalType.Bookmark && (
        <BookmarkModal
          setOpen={setModalOpen}
          setDefaultFilterAndSort={() =>
            setFilterAndSort(DEFAULT_FILTER_AND_SORT)
          }
          changePractice={() => changePractice(bookmarkPracticeId)}
        />
      )}
      <div className="flex h-full py-5 bg-gray-100 text-black dark:bg-gray-800 dark:text-gray-100">
        <div className="flex-grow ml-3">
          <DataGridPro
            apiRef={apiRef}
            rows={patients}
            rowCount={patientQueryCount.count}
            columns={columns}
            checkboxSelection={selectionEnabled()}
            onRowSelectionModelChange={handleSelectionModelChange}
            disableDensitySelector={true}
            disableColumnFilter={false}
            getRowHeight={getRowHeight}
            initialState={{
              density: "comfortable", // contact
            }}
            filterMode="server"
            sortingMode="server"
            onFilterModelChange={handleFilterModelChange}
            onSortModelChange={handleSortModelChange}
            loading={isValidatingPatients}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityModelChange}
            onRowsScrollEnd={handleScroll}
            localeText={{ toolbarColumns: "Details" }}
            onCellDoubleClick={(
              params: GridCellParams,
              event: MuiEvent<MouseEvent>,
            ) => {
              event.defaultMuiPrevented = true
              const patientPath = `/patients/${params.row.id}`
              const screens = currentlyFilteredScreens(
                params?.row?.screens,
                filterAndSort,
              )
              if (screens.length === 0 || screens.length > 1) {
                navigate(`${patientPath}/screens`)
                return
              }
              navigate(
                `${patientPath}/screens/${params?.row?.screens?.[0]?.id}`,
              )
            }}
            sx={{
              border: "none",
              height: "85%",
              width: "99%",
              "& .MuiDataGrid-columnHeaderTitle": {
                textTransform: "capitalize",
                fontFamily: "Inter",
                fontWeight: "700",
                fontSize: "12pt",
                color: "black",
              },
              ".dark & .MuiDataGrid-columnHeaderTitle": {
                color: fullConfig.theme.colors.gray[200],
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "white",
                borderBottom: "none",
              },
              ".dark & .MuiDataGrid-columnHeaders": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-columnHeader": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-columnHeader--moving": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-columnHeader--moving": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-toolbarContainer": {
                "& .MuiButton-root": {
                  color: fullConfig.theme.colors["topo-blue"],
                },
              },
              "& .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiButtonBase-root": {
                color: fullConfig.theme.colors.gray[100],
              },
              "&": {
                display: "flex-grow",
                height: "100%",
                justifyContent: "flex-end",
              },
              "& .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[100],
              },
              ".dark & .MuiDataGrid-row:hover": {
                backgroundColor: fullConfig.theme.colors.gray[600],
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: "white",
              },
              ".dark & .MuiDataGrid-virtualScrollerContent": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              ".dark & .MuiDataGrid-virtualScroller": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGrid-footerContainer": {
                backgroundColor: "white",
                borderBottomLeftRadius: ".5rem",
                borderBottomRightRadius: ".5rem",
              },
              ".dark & .MuiDataGrid-footerContainer": {
                backgroundColor: fullConfig.theme.colors.gray[700],
              },
              "& .MuiDataGridPro-toolbarContainer": {
                justifyContent: "flex-end",
              },
              "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                py: "4px",
              },
              "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                py: "7px",
              },
              "&.MuiDataGrid-filterFormValueInput": {
                backgroundColor: "red",
              },
            }}
            slots={{
              toolbar: ToolbarPatients,
              loadingOverlay: LinearProgress,
            }}
            slotProps={{
              filterPanel: {
                sx: {
                  minWidth: "50vw",
                },
                filterFormProps: {
                  valueInputProps: {
                    sx: {
                      width: "inherit",
                    },
                  },
                },
              },
              toolbar: {
                currentUser,
                setQuickAddOpen,
                navigate,
                filtersOpen,
                setFiltersOpen,
                customSortableColumns,
                columns,
                disableColumnFilter,
                setDisableColumnFilter,
                filterAndSort,
                setFilterAndSort,
                search,
                csvOptions: { disableToolbarButton: !isAdmin(currentUser) },
                printOptions: { disableToolbarButton: true },
                setSearch,
                isValidatingPatients,
                // printOptions: { disableToolbarButton: true },
              },
            }}
          />
        </div>
      </div>
    </>
  )
}

// const superCustomStudyFilterOperator: GridFilterOperator[] = [{
//   label: 'is',
//   value: 'equals',
//   InputComponent: InputScreenFilter,
//   getApplyFilterFn: (filterItem: GridFilterItem, column: GridColDef) => {
//     // [ 'AA', 'prequalified, 'phone_screen' ]
//     // [ 'AA', null, 'phone_screen' ]
//     // [ 'AA', in_progress, null ]
//     // [ null, null, 'phone_screen' ]

//     // value is a screen
//     return ({ value: screens }): boolean => {
//       if (screens === undefined) return false
//       if (filterItem?.value === undefined) return false
//       if (filterItem?.value?.length > 3) return true // migration from 4 args to 3, this should cause the filter to match all, until you clear out the filters and reset

//       // TODO: ilike functionality
//       // this is effectively an AND between the criteria
//       if (filterItem.value[0] != undefined) {
//         screens = screens.filter(screen => screen.study?.abbreviation == filterItem.value[0])
//       }
//       if (filterItem.value[1] != undefined) {
//         screens = screens.filter(screen => screen.status == filterItem.value[1])
//       }
//       if (filterItem.value[2] != undefined) {
//         screens = screens.filter(screen => screen.assigned_to?.name == filterItem.value[2])
//       }
//       return screens.length > 0
//     }
//   },
// }]

const currentlyFilteredScreens = (screens, filterAndSort) => {
  const screenFilterValue = filterAndSort?.filtering?.filters?.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  return sortScreens(screens)?.filter(
    (screen) =>
      !screenFilterValue || screen?.study?.abbreviation === screenFilterValue,
  )
}

const moreScreens = (screens, apiRef = useGridApiContext()) => {
  const filterModel = useGridSelector(apiRef, gridFilterModelSelector)
  const screenFilterValue = filterModel?.items.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  return screens.filter(
    (screen) =>
      screenFilterValue && screen?.study?.abbreviation !== screenFilterValue,
  )?.length
}

const renderCellScreens = (params: GridCellParams, filterAndSort) => (
  <div className="flex-col w-full align-baseline">
    {currentlyFilteredScreens(params?.row?.screens, filterAndSort)?.map(
      (screen) => (
        <Link
          key={screen.id}
          to={`/patients/${params.row.id}/screens/${screen.id}`}
        >
          <StudyScreenStatusChip
            text={screen?.study?.abbreviation?.substring(0, 13)}
            status={screen.status}
          />
        </Link>
      ),
    )}
    {/* {false && moreScreens(params.row?.screens) > 0 &&
      <Link to={`/patients/${params.row.id}/screens`}>
        <div className='text-xs text-gray-500 underline decoration-dotted flex justify-end mr-3'>
          {`+${moreScreens(params.row?.screens)} more`}
        </div>
      </Link>
    } */}
  </div>
)

const renderCellScreenStatusTitle = (params: GridCellParams, filterAndSort) => {
  return (
    <div className="flex-col space-y-4">
      {currentlyFilteredScreens(params?.row?.screens, filterAndSort)?.map(
        (screen) => (
          <div key={screen?.id}>
            <p className="font-semibold">
              {" "}
              {screen?.status
                ? snakeToTitle(screen.status?.split("_").join(" "))
                : "Unknown Status"}
            </p>
          </div>
        ),
      )}
    </div>
  )
}

const renderCellAssignedTo = (params: GridCellParams, filterAndSort) => {
  return (
    <div className="flex-col space-y-4">
      {currentlyFilteredScreens(params?.row?.screens, filterAndSort)?.map(
        (screen) => (
          <div key={screen?.id}>
            <p>{screen?.assigned_to?.name}</p>
          </div>
        ),
      )}
    </div>
  )
}

const renderCellOverflow = (params: GridCellParams) => (
  <div
    className={`flex h-8 text-xs overflow-clip ${
      params.value === "Select a Study" ? "items-center" : ""
    }`}
    title={params.value || "None"}
  >
    {params.value}
  </div>
)

const handleScreenStart = async (
  studyId,
  patientId,
  practiceId,
  getAccessTokenSilently,
  navigate,
) => {
  if (!studyId) return

  const data = {
    study_id: studyId,
    patient_id: patientId,
    practice_id: practiceId,
    status: "chart_review",
  }
  const response = await useCreate("screens", data, getAccessTokenSilently)
  const screenId = response?.data?.data?.id
  if (screenId) navigate(`/patients/${patientId}/screens/${screenId}`)
}

const renderCellContinueScreen = (params: GridCellParams, filterAndSort) => {
  const screen_filter = filterAndSort?.filtering?.filters?.find(
    (item) => item.field === "study",
  )?.value
  let screens = []
  if (screen_filter) {
    screens = currentlyFilteredScreens(
      params?.row?.screens,
      filterAndSort,
    ).filter((screen) => screen.study.abbreviation === screen_filter)
  } else {
    screens = params?.row?.screens
  }
  if (screens?.length > 1 || screens?.length === 0) return <></>
  const screen = screens?.[0]
  return (
    <Link to={`/patients/${params?.row?.id}/screens/${screen?.id}`}>
      <div
        className="border border-topo-green-200 bg-transparent hover:bg-topo-green-100 dark:hover:bg-topo-green-800 hover:bg-opacity-50 focus:bg-topo-green-700 focus:outline-none focus:ring-2 focus:ring-topo-green-800 focus:ring-offset-2 text-topo-green dark:hover:text-topo-green-200 text-xs font-medium mr-6 px-2.5 py-1.5 rounded-md flex items-center justify-between"
        title={screen?.study?.abbreviation}
      >
        Screen
        <HeroIcon icon="ArrowCircleRightIcon" className="ml-1 w-5 h-5 flex" />
      </div>
    </Link>
  )
}

const renderCellStudyDateTime = (params: GridCellParams) => (
  <div className="flex-row w-full space-y-1 text-xs">
    <div className="text-xs">
      {params.value === "Select a Study"
        ? params.value
        : params.value
          ? friendlyDateTime(params.value)
          : "None"}
    </div>
  </div>
)

const renderCellTier = (params: GridCellParams) => {
  return (
    <div className="flex-col w-full align-baseline">
      {params?.value?.tier_description ? (
        <Tooltip placement="left" content={params?.value?.tier_description}>
          {params?.value?.tier}
        </Tooltip>
      ) : (
        <div>{params?.value?.tier}</div>
      )}
    </div>
  )
}

const renderCellOverflowAuto = (params: GridCellParams) => (
  <div className="text-xs">
    <div className="overflow-auto">{params.value}</div>
  </div>
)
const renderCellSource = (params: GridCellParams) => (
  <div className="text-xs">
    {params?.value ? snakeToTitle(params.value) : ""}
  </div>
)

const renderCellStars = (params: GridCellParams) => {
  if (params.value === "Select a Study")
    return <div className="flex text-xs">{params.value}</div>
  const stars = Array.from({ length: params?.value }, (_, i) => i + 1)
  return (
    <div className="flex space-x-1">
      {stars.map((star) => (
        <div key={star} className="">
          ⭐
        </div>
      ))}
    </div>
  )
}

const sortScreens = (screens) =>
  screens
    .slice()
    .sort(
      (v1, v2) =>
        new Date(v2.updated_at).getTime() - new Date(v1.updated_at).getTime(),
    )
// .filter((screen) => screen.status && screen.status != 'not_started' && screen.status != 'disqualified')

const sortComparatorScreenStatus: GridComparatorFn<string> = (
  v1,
  v2,
  cellParams1,
  cellParams2,
) => {
  const statuses = {
    not_started: 60,
    prequalified: 50,
    in_progress: 40,
    qualified: 30,
    disqualified: 20,
    moved_to_esource: 10,
  }
  if (statuses[v2] === undefined) return -100
  if (statuses[v1] === undefined) return 100
  return statuses[v1] - statuses[v2]
}

const valueGetterContacts = (value, row, column, apiRef) => {
  return (
    row?.contact_count &&
    Object.values(row?.contact_count)?.reduce(
      (totalContacts: number, currentContactValue: number) =>
        totalContacts + currentContactValue,
      0,
    )
  )
}

const valueGetterContactsPhone = (value, row, column, apiRef) =>
  row.contact_count?.["Phone Call"]
const valueGetterFullName = (value, row, column, apiRef) => {
  const suffix = row.suffix?.length > 0 ? `, ${row.suffix}` : ""
  return `${row.given_name} ${row.family_name}${suffix}`
}
//TODO: There's a bug here.  return something that can be filtered on (3 item array)
const valueGetterScreens = (value, row, column, apiRef) =>
  sortScreens(row.screens)

const singleStudyView = (filters) =>
  !!filters?.find((item) => item.field === "study")?.value

export const valueGetterScreenValue = (
  value,
  row,
  column,
  apiRef,
  filterAndSort,
  screenField: string,
) => {
  if (!singleStudyView(filterAndSort?.filtering?.filters))
    return "Select a Study"
  const screenFilterValue = filterAndSort?.filtering?.filters?.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  const currentlyFilteredScreens = sortScreens(row.screens)?.filter(
    (screen) =>
      !screenFilterValue || screen?.study?.abbreviation === screenFilterValue,
  )
  return currentlyFilteredScreens?.[0]?.[screenField]
}

export const valueGetterScreen = (
  value,
  row,
  column,
  apiRef,
  filterAndSort,
) => {
  if (!singleStudyView(filterAndSort?.filtering?.filters))
    return "Select a Study"
  const screenFilterValue = filterAndSort?.filtering?.filters?.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  const currentlyFilteredScreens = sortScreens(row.screens)?.filter(
    (screen) =>
      !screenFilterValue || screen?.study?.abbreviation === screenFilterValue,
  )

  return currentlyFilteredScreens?.[0]
}

const valueGetterStars = (feedback_score) => {
  if (feedback_score === "Select a Study") return feedback_score
  if (!feedback_score) return 0
  // Bound within the range of 0 to 100
  const bounded = Math.max(0, Math.min(100, feedback_score))
  // Adjust to the nearest of 0, 25, 50, 75, 100
  const adjusted = Math.round(bounded / 25) * 25
  // Map directly to 1-5 (0=1, 25=2, 50=3, 75=4, 100=5)
  return adjusted / 25 + 1
}

const valueGetterUpdatedAt = (value, row, column, apiRef, filterAndSort) => {
  if (!singleStudyView(filterAndSort?.filtering?.filters))
    return "Select a Study"
  const screenFilterValue = filterAndSort?.filtering?.filters?.find(
    (filterModelValue) => filterModelValue.field === "study",
  )?.value
  const currentlyFilteredScreens = sortScreens(row.screens)?.filter(
    (screen) =>
      !screenFilterValue || screen?.study?.abbreviation === screenFilterValue,
  )
  return dateTimeISO(currentlyFilteredScreens?.[0]?.updated_at)
}

// This sorts patient_appointments_last by start_dt and returns the most recent one as an ISO date
const lastAppointment = (appointments) =>
  appointments
    ?.sort((a, b) => Date.parse(b.start_dt) - Date.parse(a.start_dt))
    ?.slice(0, 1)

// This sorts patient_appointments_future by start_dt and returns the next soonest one as an ISO date
const nextAppointment = (appointments) =>
  appointments
    ?.sort((a, b) => Date.parse(a.start_dt) - Date.parse(b.start_dt))
    ?.slice(0, 1)

const valueGetterLastAppointment = (value, row, column, apiRef) =>
  lastAppointment(row?.patient_appointments_past)?.map((patient_appointment) =>
    dateTimeISOWithoutTimezone(patient_appointment?.start_dt),
  )?.[0]

const valueFormatterLastAppointment = (value, row, column, apiRef) =>
  dateTimeISO(new Date(value).toJSON())

const valueGetterLastAppointmentType = (value, row, column, apiRef) =>
  lastAppointment(row?.patient_appointments_past)?.[0]?.appointment_type

const valueGetterLastAppointmentLocation = (value, row, column, apiRef) =>
  lastAppointment(row?.patient_appointments_past)?.[0]?.appointment_location

const valueGetterNextAppointment = (value, row, column, apiRef) => {
  return nextAppointment(row?.patient_appointments_future)?.map(
    (patient_appointment) =>
      dateTimeISOWithoutTimezone(patient_appointment?.start_dt),
  )?.[0]
}

const valueFormatterNextAppointment = (value, row, column, apiRef) =>
  dateTimeISO(new Date(value).toJSON())

const valueGetterNextAppointmentType = (value, row, column, apiRef) =>
  nextAppointment(row?.patient_appointments_future)?.[0]?.appointment_type

const valueGetterNextAppointmentLocation = (value, row, column, apiRef) =>
  nextAppointment(row?.patient_appointments_future)?.[0]?.appointment_location
