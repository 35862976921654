import { useQueryClient } from "@tanstack/react-query"
import { Alert, Card } from "flowbite-react"
import { useContext, useEffect } from "react"
import { HiInformationCircle } from "react-icons/hi"
import { Link, useNavigate } from "react-router-dom"
import { HeroIcon } from "../../components/hero-icon"
import { StudyScreenStatusChip } from "../../components/patients/study-screen-status-chip"
import { AuthorizationContext } from "../../shared/authorization-provider"
import { friendlyDateTime } from "../../shared/friendly-dates"
import { useGet, useShow } from "../../shared/use-rest"
import { useShowQuery } from "../../shared/use-rest-query"

export function ChartReviewDirectoryScreenCard({ screen }) {
  const { currentUser } = useContext(AuthorizationContext)
  const queryClient = useQueryClient()
  const InfoItem = ({ label, value }) => (
    <div className="flex justify-between">
      <h3 className="text-sm font-semibold">{label}</h3>
      <span>{value || "None"}</span>
    </div>
  )

  const navigate = useNavigate()

  const handleDoubleClick = () => {
    navigate(`/patients/${screen?.patient_id}/screens/${screen?.id}`)
  }

  const {
    data: { data: batched_by_user },
  } = screen?.batched_by_user_id
    ? useShowQuery("users", screen?.batched_by_user_id)
    : { data: { data: null } }

  return (
    <Card
      onDoubleClick={handleDoubleClick}
      className="max-w-[1500px] bg-white rounded-lg shadow-md mb-4"
    >
      <div className="space-y-4">
        {/* Title section */}
        <div className="flex-1 items-center  border-b-2 pb-2">
          <div className="flex w-full items-center space-x-4 justify-between">
            <div className="grid grid-cols-2 items-center w-1/6">
              <div>
                <Link
                  to={`/patients/${screen?.patient_id}/screens/${screen?.id}`}
                >
                  <StudyScreenStatusChip
                    text={screen?.study?.abbreviation?.substring(0, 13)}
                    status={screen?.status}
                  />
                </Link>
              </div>
              <div className="justify-self-end">
                <Link
                  to={`/patients/${screen?.patient_id}/screens/${screen?.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="border border-topo-green-200 bg-transparent hover:bg-topo-green-100 text-topo-green text-xs font-medium px-2.5 py-1.5 rounded-md flex items-center"
                >
                  Screen
                  <HeroIcon
                    icon="ArrowCircleRightIcon"
                    className="ml-1 w-5 h-5"
                  />
                </Link>
              </div>
            </div>
            <div className="justify-self-start">
              {screen?.batched &&
                screen?.batched_by_user_id &&
                screen?.batched_by_user_id !== currentUser?.id && (
                  <Alert color="failure" icon={HiInformationCircle}>
                    <span className="font-medium">Already Under Review</span>{" "}
                    This screen is currently being reviewed
                    {batched_by_user?.name
                      ? ` by ${batched_by_user?.name}`
                      : ""}
                    .
                  </Alert>
                )}
            </div>
            <div className="flex flex-col flex-grow space-y-2 border-r pr-4">
              {" "}
            </div>
          </div>
        </div>

        {/* Details section */}
        <div className="flex w-full">
          {/* Study, tier, assignee */}
          <div className="flex flex-col flex-grow space-y-2 border-r pr-4">
            <InfoItem
              label={screen?.tier ? "Tier: " : "Confidence Score: "}
              value={
                screen?.tier
                  ? screen.tier
                  : screen?.confidence_score || "Unavailable"
              }
            />
            <InfoItem label="Assignee:" value={screen?.assigned_to?.name} />
          </div>

          {/* Last Updated, Updated Status */}
          <div className="flex flex-col flex-grow space-y-2 border-r px-4">
            <InfoItem
              label="Last Updated:"
              value={friendlyDateTime(screen?.updated_at)}
            />
            <InfoItem
              label="Last Updated Status Date:"
              value={friendlyDateTime(screen?.status_updated_at)}
            />
          </div>

          {/* Follow Up Reason, Follow Up Date */}
          <div className="flex flex-col flex-grow space-y-2 border-r px-4">
            <InfoItem
              label="Follow Up Reason:"
              value={screen?.follow_up_reason}
            />
            <InfoItem
              label="Follow Up Date:"
              value={friendlyDateTime(screen?.follow_up_dt)}
            />
          </div>

          {/* Disqualification Reason, Disqualification Detail */}
          <div className="flex flex-col flex-grow space-y-2 pl-4">
            <InfoItem
              label="Disqualification Reason:"
              value={screen?.disqualification_reason}
            />
            <InfoItem
              label="Disqualification Detail:"
              value={screen?.disqualification_detail}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}
